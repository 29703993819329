/*global chrome*/
import {
	call,
	put,
	takeLeading,
	takeEvery,
	select,
	take,
} from "redux-saga/effects"
import { toastr } from "react-redux-toastr"
import I18n from "../../i18n"
import { v4 as uuidv4 } from "uuid"
import { isMobile } from "react-device-detect"
import * as Sentry from "@sentry/browser"

import { callBackground } from "../../popup/tools/extensionUtils"

import * as Api from "./services"
import { backwardCompApi, rebuildBase64 } from "../../actions/generalUtils"
import { t } from "i18next"

//import { setExtensionItem } from '../../popup/tools/extensionUtils'

export function* loginRequest(action) {
	try {
		// If using plugin : get url from domain
		let usingPlugin = localStorage.getItem("usingPlugin")
		let apiUrlToStore

		if (usingPlugin && action.payload.customUrlGiven === false) {
			const apiUrl = yield call(
				Api.getApiUrl,
				action.payload.email.split("@")[1],
			)
			apiUrlToStore = apiUrl + "/api/"

			localStorage.setItem("apiUrl", apiUrl + "/api/")
		}

		if (typeof browser !== "undefined" && browser?.storage) {
			browser.storage.sync.set({
				apiUrl: !!apiUrlToStore
					? apiUrlToStore
					: localStorage.getItem("apiUrl"),
			})
		} else if (typeof chrome !== "undefined" && chrome?.storage) {
			chrome.storage.sync.set({
				apiUrl: !!apiUrlToStore
					? apiUrlToStore
					: localStorage.getItem("apiUrl"),
			})
		}

		const data = yield call(Api.login, action.payload)

		if (data === true) yield put({ type: "auth/loginFailed" })
		else {
			if (!data.userSession.isSso)
				localStorage.removeItem("connexionType")

			// We need to convert simple base64 data from the internal whitemark to data-url.
			// We do this only if the api version allows the whitemark use.

			if (
				window.localStorage.getItem("apiVersion") !== null &&
				backwardCompApi("1.10.7")
			) {
				if (data.userSession.userWhitemark.logoUrl) {
					data.userSession.userWhitemark.logoUrl = rebuildBase64(
						data.userSession.userWhitemark.logoUrl,
						"image",
						"svg+xml",
					)
				} else {
					data.userSession.userWhitemark.logoUrl = ""
				}
			} else {
				data.userSession.userWhitemark.logoUrl = ""
			}

			yield put({
				type: "accountOptions/setSSO",
				payload: data.userSession.isSso,
			})

			const organizationNames = data.userSession.organizationNames
			const organizationIds = data.userSession.organizationIds

			Sentry.setContext("user", {
				username: data.userSession.username,
				userEmail: data.userSession.userEmail,
				organization:
					organizationNames[
						organizationIds[organizationIds.length - 1]
					],
			})

			return yield put({ type: "auth/loginSuccess", data })
		}
	} catch (error) {
		// normalement toutes les erreurs sont directement traîtées dans sevices (Vlad) Sauf la désactivation du loader suite à une erreur réseau (Guillaume)
		yield put({ type: "auth/loginLoading" })
	}
}

export function* decrypt(action) {
	try {
		yield put({ type: "auth/resetErrorStatus" })
		let token = yield select((state) => state.auth.token)
		let payload = {
			...action.payload,
			token: token.token,
			id: token.userSession.userId,
			auth: token.userSession.userAuth,
		}
		const data = yield call(Api.decrypt, payload)
		const uS = yield select((state) => state.auth.token.userSession)

		// Disable the mfa connexion if the user is logging in. If he's at the login step, he can't have passed the MFA connexion.
		// The reason we need to do this is that when a user closed a window with an active LockSelf session, and it has expired, he will be
		// send back to the login page when he goes back to the webapp, but will still have the mfaLogged key in localStorage.
		window.localStorage.setItem("mfaLogged", false)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
			case false:
				return yield put({ type: "auth/pinFailed" })
			default:
				let auth = yield select((state) => state.auth)

				localStorage.setItem("userFavDura", action.payload.dura)
				toastr.removeByType("warning")
				localStorage.removeItem("error")

				// If the account must use MFA, stop there, the app setup will happen
				// after the double authentication.
				if (auth.token.userSession.isMfaConnection) {
					return yield put({ type: "auth/pinSuccess", data })
				}

				const showPadlock = yield select(
					(state) => state.auth.showPadlock,
				)
				const isNewLicense = yield select(
					(state) => state.auth.token.userSession.isNewLicense,
				)
				const userType = yield select(
					(state) => state.auth.token?.userSession?.userType,
				)

				// force refresh if token expired !!!necessary for permalinks to work!!!
				// showing the padlock will stop all request so it's necessary to refresh the page
				// to resend the requests
				const organizationId = yield select(
					(state) =>
						state.auth.token.userSession.organizationIds[
							state.auth.selectedOrg
						],
				)

				if (backwardCompApi("1.22.14", data?.res?.version)) {
					yield put({
						type: "GET_OPTIONS_SAGA",
						payload: {
							...payload,
							xLsT: data.xLsT,
							organizationId: organizationId,
						},
					})

					yield take("params/setOptions")
				}

				const user = {
					xLsT: data?.xLsT,
					jwt: payload.token,
					hasDbHomepage: data?.hasDbHomepage,
					//password_categories: categories,
					language: localStorage.getItem("i18nextLng"),
					urlAPI: localStorage.getItem("apiUrl"),
					userFormule: JSON.parse(localStorage.getItem("token"))
						.userSession.userFormulas,
					hasLockPass: JSON.parse(localStorage.getItem("token"))
						.userSession.userFormulas.lockpassContent,
					organizationId,
				}

				window.localStorage.setItem("user", JSON.stringify(user))

				if (typeof browser !== "undefined" && browser?.storage) {
					browser.storage.sync.set({ user: JSON.stringify(user) })
					browser.storage.sync.set({
						apiUrl: localStorage.getItem("apiUrl"),
					})
				} else if (typeof chrome !== "undefined" && chrome?.storage) {
					chrome.storage.sync.set({ user: JSON.stringify(user) })
					chrome.storage.sync.set({
						apiUrl: localStorage.getItem("apiUrl"),
					})
				}

				try {
					/* eslint-disable no-undef */
					if (chrome?.runtime) {
						callBackground({ message: "LScheckForPopup" })
					}
				} catch (e) {}

				yield put({
					type: "auth/pinSuccess",
					data,
				})

				// Retrieve last saga before locker block
				try {
					let lcs = JSON.parse(localStorage.getItem("lastCalledSaga"))

					if (showPadlock && lcs.length > 0) {
						if (lcs[0]) yield put(lcs[0])
						if (lcs[1]) yield put(lcs[1])
						if (lcs[2]) yield put(lcs[2])
						if (lcs[3]) yield put(lcs[3])
						if (lcs[4]) yield put(lcs[4])
						localStorage.removeItem("lastCalledSaga")
					} else {
						localStorage.removeItem("lastCalledSaga")
					}
				} catch (e) {}

				yield put({
					type: "M_GET_USER_SAGA",
					payload: token.userSession.userId,
				})

				// Get the whitemark if the api version allows it.
				if (
					(backwardCompApi("1.10.7") && uS.isSuperAdmin) ||
					(backwardCompApi("1.18.5") && uS.userType === "isModerator")
				) {
					yield put({
						type: "G_GET_EXTERNAL_WHITEMARK_SAGA",
						payload: { xLsT: data.xLsT, token: payload.token },
					})
				}

				if (
					isNewLicense &&
					["isAdmin", "isModerator"].includes(uS.userType)
				) {
					yield put({ type: "LICENSE_CHECK_LICENSE_SAGA" })
					yield put({
						type: "LICENSE_GET_INDICATORS_SAGA",
						payload: {
							xLsT: data.xLsT,
							token: payload.token,
						},
					})
				}

				if (action.payload?.removeCR && action.payload?.activeStoreId) {
					yield put({
						type: `${action.payload?.activeStoreId}/setCR`,
					})
				}

				if (
					isNewLicense &&
					["isAdmin", "isModerator"].includes(userType)
				) {
					if (!window.location.href.includes("Management")) {
						yield put({ type: "LICENSE_CHECK_LICENSE_SAGA" })

						yield put({
							type: "LICENSE_GET_INDICATORS_SAGA",
							payload: { getFromLocalS: true },
						})
					}
				}
				return
		}
	} catch (error) {
		// console.log(error)
		// normalement toutes les erreurs sont directement traîtées dans sevices
	}
}

export function* isAlive(action) {
	try {
		const data = yield call(Api.isAlive, action.payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			/*case (false) :
        return yield put({ type: 'auth/sessionExpired' })*/
			default:
				toastr.removeByType("warning")
				localStorage.removeItem("error")

				yield put({ type: "AUTH_ACTION_AFTER_LOGIN_SAGA" })

				if (action.payload.callback) {
					const argument = action.payload
					if (argument.successTitle) {
						action.payload.callback(
							argument.val,
							argument.successMsg,
							argument.useToast,
							argument.successTitle,
						)
					} else if (argument.successMsg) {
						action.payload.callback(
							argument.val,
							argument.successMsg,
						)
					} else {
						action.payload.callback(argument.val)
					}
				}

				const mfaAccount = yield select(
					(state) => state.auth.isMfaConnection,
				)

				if (mfaAccount) {
					return yield put({
						type: "auth/mfaSessionRetrieved",
						payload: action.payload,
					})
				}

				return yield put({
					type: "auth/sessionRetrieved",
					payload: action.payload,
				})
		}
	} catch (error) {
		yield put({ type: "auth/mLoading" })
		// normalement toutes les erreurs sont directement traîtées dans sevices
	}
}

export function* isValidToken(action) {
	try {
		const data = yield call(Api.isValidToken, action.payload)

		if (data === true) yield put({ type: "auth/sessionExpired" })
		else return yield put({ type: "auth/tokenIsValid" })
		//yield put({ type: "AUTH_ACTION_AFTER_LOGIN_SAGA" })
	} catch (error) {
		yield put({ type: "auth/mLoading" })
		//console.log(error)
	}
}

export function* logOut() {
	try {
		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT }
		Sentry.setContext("user", undefined)
		yield call(Api.logOut, payload)

		yield put({ type: "auth/sessionExpired" })

		try {
			/* eslint-disable no-undef */
			if (chrome?.runtime) {
				localStorage.removeItem("isPopupOpen")
				callBackground({ message: "LScheckForPopup" })
			}
		} catch (e) {}
	} catch (error) {
		//console.log(error)
	}
}

export function* forgotPass(action) {
	try {
		yield put({ type: "auth/loginLoading", payload: true })

		// If using plugin : get url from domain
		let usingPlugin = localStorage.getItem("usingPlugin")

		if (
			usingPlugin ||
			window.location?.href?.includes("api.lockself.com")
		) {
			const apiUrl = yield call(
				Api.getApiUrl,
				action.payload.email.split("@")[1],
			)
			localStorage.setItem("apiUrl", apiUrl + "/api/")
		}

		const data = yield call(Api.forgotPass, action.payload)

		if (data) {
			toastr.success(
				I18n.t("auth.forgot.emailSended"),
				I18n.t("auth.forgot.emailSendedDesc"),
			)
			yield put({ type: "auth/loginLoading" })
			yield put({ type: "auth/setForgotPass" })
		}

		/*if(data === true) yield put({ type: 'auth/loginFailed' })
    else {

      toastr.success(I18n.t("auth.forgot.emailSended"))

      yield put({ type: "auth/loginLoading" })
      yield put({ type: "auth/setForgotPass" })
    }*/
	} catch (error) {
		//console.log(error)
	}
}

export function* checkPassChange(action) {
	try {
		yield put({ type: "auth/loginLoading", payload: true })

		const data = yield call(Api.checkChangePass, action.payload)

		yield put({ type: "auth/loginLoading" })

		if (data === "Correct") {
			return null
		} else {
			//yield put({ type: "auth/setChangePass"})
			//toastr.warning(I18n.t("auth.changeP.badHash"), I18n.t("auth.changeP.badHashDesc"))
			localStorage.setItem("error", I18n.t("auth.changeP.badHash"))
			return redirectAfterChangePassAction()
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* resetPass(action) {
	try {
		yield put({ type: "auth/loginLoading", payload: true })

		const data = yield call(Api.resetPass, action.payload)

		yield put({ type: "auth/loginLoading" })

		if (data === "Correct") {
			localStorage.setItem("success", I18n.t("auth.changeP.success"))

			/*let url = window.location.href.replace("#", '')
      let params = (new URL(url)).searchParams
      params.delete("action")
      params.delete("hash1")
      params.delete("hash2")
      params.delete("email")*/
		}

		return redirectAfterChangePassAction()
	} catch (error) {
		//console.log(error)
	}
}

function redirectAfterChangePassAction() {
	let path =
		window.location.protocol +
		"//" +
		window.location.host +
		window.location.pathname //+ `${params.toString() ? "?" : ""}${params.toString()}`
	window.history.pushState({ path }, "", path)
	return window.location.reload(false)
}

export function* authActionAfterLogin() {
	try {
		let url = window.location.href.replace("#", "")
		let params = new URL(url).searchParams
		let action = params.get("action")

		if (action) {
			if (action === "viewLicense")
				yield put({ type: "action/openAction", payload: "viewLicense" })

			if (action !== "changePass")
				yield put({ type: "AUTH_REMOVE_ACTION_PARAM_SAGA" })
		}
		return
	} catch (error) {
		/*console.log(error)*/
	}
}

export function* removeActionParam() {
	try {
		let url = window.location.href.replace("#", "")
		let params = new URL(url).searchParams
		let action = params.get("action")

		if (action) {
			params.delete("action")
			let path =
				window.location.protocol +
				"//" +
				window.location.host +
				window.location.pathname +
				`${params.toString() ? "?" : ""}${params.toString()}`
			window.history.pushState({ path }, "", path)
		}
		return
	} catch (error) {
		/*console.log(error)*/
	}
}

export function* loginSSORequest(action) {
	try {
		// If using plugin : get url from domain
		let usingPlugin = localStorage.getItem("usingPlugin")

		if (
			(!action.payload.customUrlGiven && usingPlugin) ||
			window.location?.host === "api.lockself.com"
		) {
			const apiUrl = yield call(
				Api.getApiUrl,
				action.payload.email.split("@")[1],
			)

			localStorage.setItem("apiUrl", apiUrl + "/api/")
		}

		if (typeof browser !== "undefined" && browser?.storage) {
			browser.storage.sync.set({ apiUrl: localStorage.getItem("apiUrl") })
		} else if (typeof chrome !== "undefined" && chrome?.storage) {
			chrome.storage.sync.set({ apiUrl: localStorage.getItem("apiUrl") })
		}

		let uuid = uuidv4()
		localStorage.setItem(
			"ssoInfos",
			JSON.stringify({ ...action.payload, uuid }),
		)

		const data = yield call(Api.requestSSO, { ...action.payload, uuid })

		if (!data || data === true) {
			yield put({ type: "auth/loginLoading" })
			localStorage.setItem("reloadFlag", true)

			window.location.reload()
		} else {
			if (action.payload.email !== localStorage.getItem("userEmail"))
				localStorage.setItem("userEmail", action.payload.email)
			localStorage.setItem("connexionType", "sso")

			if (usingPlugin) {
				try {
					if (chrome?.runtime) {
						localStorage.removeItem("isPopupOpen")
						callBackground({
							message: "LScheckForPopup",
							url: data,
						})
					}
				} catch (e) {}
			} else {
				window.location.href = data
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

// saml2/connect
export function* samlCheck() {
	try {
		// For mobile application we have to try to redirect anyway
		// It's browser will not have any apiUrl in localStorage
		if (isMobile) {
			try {
				if (localStorage.getItem("apiUrl")) {
					localStorage.clear()
				}
				return (window.location.href = "lockself://saml2/mobile")
			} catch (e) {
				return console.warn(
					"[LS] - Can't redirect to mobile application.",
				)
			}
		} else if (!localStorage.getItem("apiUrl")) {
			return console.warn("[LS] - No apiUrl found in localStorage.")
		}

		//const data = { uuid, email: "bonjour@vkode.fr" }
		//let infos = yield select(state => state.auth.ssoInfos)
		let infos = JSON.parse(localStorage.getItem("ssoInfos"))

		let data

		if (infos) {
			data = yield call(Api.samlCheck, infos)
		} else {
			yield put({ type: "auth/mLoading" })
			return
		}

		yield put({ type: "auth/loginLoading" })

		if (data.error) {
			const currentUrl = window.location.href
			const updatedUrl = currentUrl.replace(/\/samlRedirect/, "")
			window.history.replaceState({}, document.title, updatedUrl)
			localStorage.setItem("error", data.message)
			return yield put({ type: "auth/sessionExpired" })
		} else {
			yield put({ type: "auth/mLoading" })

			if (infos) {
				localStorage.removeItem("ssoInfos")
			}

			callBackground({
				message: "ssoLoginDone",
			})

			if (data.validatePin) {
				// ici on passe dans le cas où l'user n'a pas encore de compte
				// On annonce à l'utilisateur que son authentification SSO a fonctionné
				yield put({
					type: "action/openAction",
					payload: "SSOSuccessLogin",
				})

				// on ouvre la popup de création de pin
				yield put({ type: "action/openAction", payload: "setPinSSO" })
				yield put({
					type: "params/setLAR",
					payload: { message: "creatingPin", data: data.validatePin },
				})

				// on précharge déjà la vue d'entrée de Pin dans le cas où il réussira à set son pin correctement
				yield put({
					type: "accountOptions/setSSO",
					payload: data.userSession.isSso,
				})
				yield put({ type: "auth/loginSuccess", data })
			} else {
				// sinon accés au pin classique
				yield put({
					type: "accountOptions/setSSO",
					payload: data.userSession.isSso,
				})
				return yield put({ type: "auth/loginSuccess", data })
			}
		}
	} catch (error) {
		//console.log(error)
		// normalement toutes les erreurs sont directement traîtées dans sevices
	}
}

export function* registerSsoAccount(action) {
	try {
		yield put({ type: "action/setActionLoader", payload: {} })

		const data = yield call(Api.registerSsoAccount, action.payload)

		yield put({ type: "action/setActionLoader" })

		if (data === true) {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.ssoIssue"),
			)
			return yield put({ type: "auth/loginFailed" }) // suffit pour le throw d'erreur ici ???
		} else {
			localStorage.setItem(
				"success",
				I18n.t("forServices.ssoWelcome.title"),
			)
			return yield put({ type: "auth/sessionExpired" })
			//return yield put({ type: "action/openAction", payload: "ssoWelcome" })
		}
	} catch (error) {
		// console.log(error)
	}
}

// Fetch the external whitemark data. Launched while loging.
// I didn't put this inside the login saga, as I didn't want to slow the login process, and external whitemark data is only needed
// in a single part of the application; the user must navigate and open a popup, and the external whitemark fetch should have ended
// when he reach the popup.
export function* getExternalWhitemark(action) {
	try {
		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT }

		const data = yield call(Api.getExternalWhitemark, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				const currentToken = JSON.parse(localStorage.getItem("token"))
				const newToken = { ...currentToken }
				// The images send by the backend are in base64 format and have been truncated. Before storing them,
				// put back the beginning of the base64.
				const dispatchPayload = {
					favicon: "",
					banner: "",
					background: "",
					emailLanguage: "",
					companyName: "",
					bgColorClickButton: "",
					isExternalLoaded: true,
				}

				if (data.favicon)
					dispatchPayload.favicon = rebuildBase64(
						data.favicon,
						"image",
						"svg+xml",
					)
				if (data.banner)
					dispatchPayload.banner = rebuildBase64(
						data.banner,
						"image",
						"png",
					)
				if (data.background)
					dispatchPayload.background = rebuildBase64(
						data.background,
						"image",
						"png",
					)
				if (data.emailLanguage)
					dispatchPayload.emailLanguage = data.emailLanguage
				if (data.companyName)
					dispatchPayload.companyName = data.companyName
				if (data.bgColorClickButton)
					dispatchPayload.bgColorClickButton = data.bgColorClickButton

				newToken.userSession.userWhitemark = {
					...newToken.userSession.userWhitemark,
					...dispatchPayload,
				}

				localStorage.setItem("token", JSON.stringify(newToken))

				if (data)
					yield put({
						type: "auth/updateWm",
						payload: dispatchPayload,
					})
				return
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getOptions(action) {
	try {
		let payload = {
			token: action.payload.token,
			xLsT: action.payload.xLsT,
			organizationId: action.payload.organizationId,
		}

		const data = yield call(Api.getOptions, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				localStorage.setItem("options", JSON.stringify(data))

				if (data !== undefined) {
					if (typeof browser !== "undefined" && browser?.storage) {
						browser.storage.sync.set({ user: JSON.stringify(data) })
					} else if (
						typeof chrome !== "undefined" &&
						chrome?.storage
					) {
						chrome.storage.sync.set({
							options: JSON.stringify(data),
						})
					}
				}

				yield put({ type: "params/setOptions", payload: data })

				return
			}
		}
	} catch (error) {
		console.log(error)
	}
}

export function* mfaConnect(action) {
	try {
		yield put({ type: "auth/resetErrorStatus" })
		const token = yield select((state) => state.auth.token)
		const xLsT = yield select((state) => state.auth.xLsToken)

		const payload = {
			...action.payload,
			token: token.token,
			xLsT,
			userId: token.userSession.userId,
		}

		yield put({ type: "auth/loginLoading", payload: true })
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		const data = yield call(Api.mfaConnect, payload)

		yield put({ type: "action/setActionLoader" })
		yield put({ type: "auth/loginLoading" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
			case false:
				return yield put({ type: "auth/pinFailed" })
			default:
				if (data === "invalidCode") {
					toastr.error(
						I18n.t("auth.mfaAuth.error.error"),
						I18n.t("auth.mfaAuth.error.invalidCode"),
					)

					return yield put({
						type: "auth/setLAR",
						payload: { message: "mfaWrongCode" },
					})
				} else {
					window.localStorage.setItem("mfaLogged", true)

					const isSuperAdmin = yield select(
						(state) => state.auth.token.userSession.isSuperAdmin,
					)
					const showPadlock = yield select(
						(state) => state.auth.showPadlock,
					)
					const isNewLicense = yield select(
						(state) => state.auth.token.userSession.isNewLicense,
					)

					// force refresh if token expired !!!necessary for permalinks to work!!!
					// showing the padlock will stop all request so it's necessary to refresh the page
					// to resend the requests
					const organizationId = yield select(
						(state) =>
							state.auth.token.userSession.organizationIds[
								state.auth.selectedOrg
							],
					)

					const user = {
						xLsT,
						jwt: payload.token,
						hasDbHomepage: data?.res?.hasDbHomepage,
						//password_categories: categories,
						language: localStorage.getItem("i18nextLng"),
						urlAPI: localStorage.getItem("apiUrl"),
						userFormule: JSON.parse(localStorage.getItem("token"))
							.userSession.userFormulas,
						hasLockPass: JSON.parse(localStorage.getItem("token"))
							.userSession.userFormulas.lockpassContent,
						organizationId,
					}

					window.localStorage.setItem("user", JSON.stringify(user))

					if (typeof browser !== "undefined" && browser?.storage) {
						browser.storage.sync.set({
							user: JSON.stringify(user),
						})
					} else if (
						typeof chrome !== "undefined" &&
						chrome?.storage
					) {
						chrome.storage.sync.set({
							user: JSON.stringify(user),
						})
					}

					try {
						/* eslint-disable no-undef */
						if (chrome?.runtime) {
							callBackground({ message: "LScheckForPopup" })
						}
					} catch (e) {}

					//yield put({ type: 'extension/setUser', payload: user })

					toastr.removeByType("warning")
					localStorage.removeItem("error")

					yield put({ type: "auth/mfaSuccess" })

					if (backwardCompApi("1.22.14", data?.res?.version)) {
						yield put({
							type: "GET_OPTIONS_SAGA",
							payload: {
								...payload,
								xLsT,
								organizationId: organizationId,
							},
						})
					}

					// Retrieve last saga before locker block
					try {
						let lcs = JSON.parse(
							localStorage.getItem("lastCalledSaga"),
						)

						if (showPadlock && lcs.length > 0) {
							if (lcs[0]) yield put(lcs[0])
							if (lcs[1]) yield put(lcs[1])
							if (lcs[2]) yield put(lcs[2])
							if (lcs[3]) yield put(lcs[3])
							if (lcs[4]) yield put(lcs[4])
							localStorage.removeItem("lastCalledSaga")
						} else {
							localStorage.removeItem("lastCalledSaga")
						}
					} catch (e) {}

					yield put({
						type: "M_GET_USER_SAGA",
						payload: token.userSession.userId,
					})

					// Get the whitemark if the api version allows it.
					if (isSuperAdmin) {
						yield put({
							type: "G_GET_EXTERNAL_WHITEMARK_SAGA",
							payload: {
								xLsT: data.xLsT,
								token: payload.token,
							},
						})
					}

					if (
						isNewLicense &&
						["isAdmin", "isModerator"].includes(uS.userType)
					) {
						yield put({ type: "LICENSE_CHECK_LICENSE_SAGA" })
						yield put({
							type: "LICENSE_GET_INDICATORS_SAGA",
							payload: {
								xLsT: data.xLsT,
								token: payload.token,
							},
						})
					}

					if (
						action.payload?.removeCR &&
						action.payload?.activeStoreId
					) {
						yield put({
							type: `${action.payload?.activeStoreId}/setCR`,
						})
					}

					if (action.payload.activeProduct) {
						yield put({
							type: `${action.payload.activeProduct}/setCR`,
						})
					}

					return
				}
		}
	} catch (error) {
		// console.log(error)
		// normalement toutes les erreurs sont directement traîtées dans sevices
	}
}

export function* mfaBackupConnect(action) {
	try {
		yield put({ type: "auth/resetErrorStatus" })
		const token = yield select((state) => state.auth.token)
		const xLsT = yield select((state) => state.auth.xLsToken)

		const payload = {
			...action.payload,
			token: token.token,
			xLsT,
			userId: token.userSession.userId,
		}

		yield put({ type: "auth/loginLoading", payload: true })
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		const data = yield call(Api.mfaBackupConnect, payload)

		yield put({ type: "action/setActionLoader" })
		yield put({ type: "auth/loginLoading" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
			default:
				if (data.code < 200 || data.code > 299) {
					if (
						data.message ===
						t("auth.mfaAuth.error.backendCodeErrorDetail")
					) {
						return toastr.error(data.message)
					}
				} else {
					window.localStorage.setItem("mfaLogged", true)

					const isSuperAdmin = yield select(
						(state) => state.auth.token.userSession.isSuperAdmin,
					)
					const showPadlock = yield select(
						(state) => state.auth.showPadlock,
					)
					const isNewLicense = yield select(
						(state) => state.auth.token.userSession.isNewLicense,
					)

					// force refresh if token expired !!!necessary for permalinks to work!!!
					// showing the padlock will stop all request so it's necessary to refresh the page
					// to resend the requests
					const organizationId = yield select(
						(state) =>
							state.auth.token.userSession.organizationIds[
								state.auth.selectedOrg
							],
					)

					const user = {
						xLsT,
						jwt: payload.token,
						hasDbHomepage: data?.res?.hasDbHomepage,
						//password_categories: categories,
						language: localStorage.getItem("i18nextLng"),
						urlAPI: localStorage.getItem("apiUrl"),
						userFormule: JSON.parse(localStorage.getItem("token"))
							.userSession.userFormulas,
						hasLockPass: JSON.parse(localStorage.getItem("token"))
							.userSession.userFormulas.lockpassContent,
						organizationId,
					}

					window.localStorage.setItem("user", JSON.stringify(user))

					if (typeof browser !== "undefined" && browser?.storage) {
						browser.storage.sync.set({
							user: JSON.stringify(user),
						})
					} else if (
						typeof chrome !== "undefined" &&
						chrome?.storage
					) {
						chrome.storage.sync.set({
							user: JSON.stringify(user),
						})
					}

					try {
						/* eslint-disable no-undef */
						if (chrome?.runtime) {
							callBackground({ message: "LScheckForPopup" })
						}
					} catch (e) {}

					//yield put({ type: 'extension/setUser', payload: user })

					toastr.removeByType("warning")
					localStorage.removeItem("error")

					if (backwardCompApi("1.22.14", data?.res?.version)) {
						yield put({
							type: "GET_OPTIONS_SAGA",
							payload: {
								...payload,
								xLsT,
								organizationId: organizationId,
							},
						})
					}

					// Retrieve last saga before locker block
					try {
						let lcs = JSON.parse(
							localStorage.getItem("lastCalledSaga"),
						)

						yield put({ type: "auth/mfaSuccess" })

						if (showPadlock && lcs.length > 0) {
							if (lcs[0]) yield put(lcs[0])
							if (lcs[1]) yield put(lcs[1])
							if (lcs[2]) yield put(lcs[2])
							if (lcs[3]) yield put(lcs[3])
							if (lcs[4]) yield put(lcs[4])
							localStorage.removeItem("lastCalledSaga")
						} else {
							localStorage.removeItem("lastCalledSaga")
						}
					} catch (e) {}

					yield put({
						type: "M_GET_USER_SAGA",
						payload: token.userSession.userId,
					})

					// Get the whitemark if the api version allows it.
					if (isSuperAdmin) {
						yield put({
							type: "G_GET_EXTERNAL_WHITEMARK_SAGA",
							payload: {
								xLsT: data.xLsT,
								token: payload.token,
							},
						})
					}

					if (
						isNewLicense &&
						["isAdmin", "isModerator"].includes(uS.userType)
					) {
						yield put({ type: "LICENSE_CHECK_LICENSE_SAGA" })
						yield put({
							type: "LICENSE_GET_INDICATORS_SAGA",
							payload: {
								xLsT: data.xLsT,
								token: payload.token,
							},
						})
					}

					if (
						action.payload?.removeCR &&
						action.payload?.activeStoreId
					) {
						yield put({
							type: `${action.payload?.activeStoreId}/setCR`,
						})
					}

					if (action.payload.activeProduct) {
						yield put({
							type: `${action.payload.activeProduct}/setCR`,
						})
					}

					return
				}
		}
	} catch (error) {
		// console.log(error)
		// normalement toutes les erreurs sont directement traîtées dans sevices
	}
}

export default function* authSagas() {
	yield takeEvery("LOGIN_SAGA", loginRequest)
	yield takeLeading("DECRYPT_SAGA", decrypt)
	yield takeEvery("ISALIVE_SAGA", isAlive)
	yield takeEvery("IS_VALID_TOKEN_SAGA", isValidToken)
	yield takeEvery("LOGOUT_SAGA", logOut)

	yield takeEvery("FORGOT_PASS_SAGA", forgotPass)
	yield takeEvery("CHANGE_PASS_CHECK_SAGA", checkPassChange)
	yield takeEvery("RESET_PASS_SAGA", resetPass)

	yield takeLeading("AUTH_ACTION_AFTER_LOGIN_SAGA", authActionAfterLogin)
	yield takeLeading("AUTH_REMOVE_ACTION_PARAM_SAGA", removeActionParam)

	yield takeEvery("LOGIN_SSO_SAGA", loginSSORequest)
	yield takeEvery("LOGIN_MFA_SAGA", mfaConnect)
	yield takeEvery("LOGIN_MFA_BACKUP_SAGA", mfaBackupConnect)
	yield takeEvery("SAML_CHECK", samlCheck)
	yield takeEvery("REGISTER_SSO_ACCOUNT_SAGA", registerSsoAccount)
	yield takeEvery("G_GET_EXTERNAL_WHITEMARK_SAGA", getExternalWhitemark)

	yield takeEvery("GET_OPTIONS_SAGA", getOptions)
}

//export default authSagas;
