import { toastr } from "react-redux-toastr"
import I18n from "../../i18n"
import { backwardCompApi, handleError } from "../../actions/generalUtils"

function headers(payload) {
	let a = {
		"Accept-Language": localStorage.getItem("i18nextLng"),
		Authorization: "Bearer " + payload.token,
		"X-Ls-Token": payload.xLsT,
	}

	if (!payload.formData) a = { ...a, "Content-Type": "application/json" }

	return a
}

function formQuery(data) {
	let query = ""
	let first = true

	if (data.offset) {
		first = false
		query += "?offset=" + data.offset
	}

	if (data.id) {
		if (first) {
			query += "?id=" + data.id
		} else {
			query += "&id=" + data.id
		}
		first = false
	}

	if (data.limit) {
		if (first) {
			query += "?limit=" + data.limit
		} else {
			query += "&limit=" + data.limit
		}
		first = false
	}

	if (data.search) {
		if (first) {
			query += "?search=" + encodeURIComponent(data.search)
		} else {
			query += "&search=" + encodeURIComponent(data.search)
		}
		first = false
	}

	if (data.organizationId) {
		if (first) {
			query += "?organizationId=" + data.organizationId
		} else {
			query += "&organizationId=" + data.organizationId
		}
		first = false
	}

	if (typeof data.categoryId !== "undefined") {
		if (first) {
			query += "?categoryId=" + data.categoryId
		} else {
			query += "&categoryId=" + data.categoryId
		}
		first = false
	}

	if (typeof data.searchType !== "undefined") {
		if (first) {
			query += "?searchType=" + data.searchType
		} else {
			query += "&searchType=" + data.searchType
		}
		first = false
	}

	if (data.pagination) {
		if (first) {
			query += "?pagination=" + data.pagination
		} else {
			query += "&pagination=" + data.pagination
		}
		first = false
	}

	return query
}

export const getCredentials = (payload) => {
	let responseStatus
	let url =
		localStorage.getItem("apiUrl") + "credentials" + formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas

	/*.then(res => (!responseStatus
		? payload.search ? { limit: 10, page: 1, pages: 1, total: 0, _embedded: {items: []} } : handleError(res)
		: res)
	)*/
}

// get master credential id for permalinks
export const getMasterCredentialsId = (payload) => {
	//console.log(payload)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") + `credentials/${payload.id}/master`

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas

	/*.then(res => (!responseStatus
		? payload.search ? { limit: 10, page: 1, pages: 1, total: 0, _embedded: {items: []} } : handleError(res)
		: res)
	)*/
}

export const getIsWaiting = (payload) => {
	let responseStatus
	let url =
		localStorage.getItem("apiUrl") +
		"categories/is/waiting" +
		formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res))
}

export const getCategories = (payload) => {
	////console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "categories" + formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getCategoryUsers = (payload) => {
	////console.log(payload)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") + "categories/users" + formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getPersoCategories = (payload) => {
	////console.log(payload)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") + "categories/perso" + formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getCat = (payload) => {
	////console.log(payload)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") + "categories/specific/" + payload.id

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getRootCats = (payload) => {
	////console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "categories/root"

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getRootCatsV2 = (payload) => {
	////console.log(payload)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") + "v2/categories" + formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getBranch = (payload) => {
	/* console.log("running getBranch service", payload) */

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") +
		"categories/branch?mainId=" +
		payload.mainId

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getCategoriesSearch = (payload) => {
	let responseStatus
	let url =
		localStorage.getItem("apiUrl") +
		"categories/search?search=" +
		encodeURIComponent(payload.search)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? "" : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const decryptCredit = (payload) => {
	//console.log(payload)
	let responseStatus
	let url = `${localStorage.getItem("apiUrl")}credentials/decrypt/${
		payload.id
	}${payload.noEmail ? "?sendEmail=false" : ""}`

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const postCredential = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "credentials"

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify(payload.action),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
				.json()
				.then((data) => ({ status: responseStatus, data: data }))
		})
		.then((result) => (!result.status ? handleError(result.data) : result))
	// Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const putCredit = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "credentials/" + payload.id

	return fetch(url, {
		method: "PUT",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const grabCredential = (payload) => {
	let responseStatus
	let networkError = false
	let url = localStorage.getItem("apiUrl") + "credentials/owner/" + payload.id

	return fetch(url, {
		method: "PUT",
		headers: headers(payload),
	})
		.catch(() => {
			networkError = true
			return toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => {
			if (networkError) {
				return res
			}

			return res.json()
		})
		.then((res) => {
			if (networkError) {
				return "networkError"
			}

			return !responseStatus ? handleError(res) : res
		}) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const grabAllCredentials = (payload) => {
	let responseStatus
	let networkError = false
	let url =
		localStorage.getItem("apiUrl") + "categories/" + payload.id + "/owner"

	return fetch(url, {
		method: "PUT",
		headers: headers(payload),
		body: JSON.stringify({}),
	})
		.catch(() => {
			networkError = true
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => {
			if (networkError) {
				return "networkError"
			}
			return res.json()
		})
		.then((res) => {
			if (networkError) {
				return "networkError"
			}

			return !responseStatus ? handleError(res) : res
		}) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const deleteCredit = (payload) => {
	let responseStatus
	let defineUrl = backwardCompApi("1.18.0")
		? "credentials/trash/permanent?ids="
		: "credentials/v2?ids="

	if (backwardCompApi("1.18.0") && payload?.moveInTrash) {
		defineUrl = "credentials/trash?ids="
	}

	const url = localStorage.getItem("apiUrl") + defineUrl + payload.action

	return fetch(url, {
		method: "DELETE",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getCreditFiles = (payload) => {
	////console.log(payload)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") +
		"credentials/files" +
		formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const addFilesToCredit = (payload) => {
	let responseStatus
	let url = localStorage.getItem("apiUrl") + "credentials/file"

	const data = new FormData()
	data.append("chunkIndex", payload.data.chunkIndex)
	data.append("file", payload.data.filePart)
	data.append("totalChunkCount", payload.data.totalChunkCount)
	data.append("totalSize", payload.data.totalSize)
	data.append("uuid", payload.data.uuid)
	data.append("credentialId", payload.data.id)
	data.append("firstUpload", payload.data.firstUpload)

	return fetch(url, {
		method: "POST",
		headers: headers({ ...payload, formData: true }),
		body: data,
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const deleteCreditFile = (payload) => {
	let responseStatus
	let url = localStorage.getItem("apiUrl") + "credentials/file"

	return fetch(url, {
		method: "DELETE",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const downloadCreditFile = (payload) => {
	let data = JSON.stringify({
		fileId: payload.action.fileId,
		fileHash: payload.action.fileHash,
	})

	return new Promise(function (resolve, reject) {
		let xhr = new XMLHttpRequest()
		let requestType = "POST"
		let requestUrl = `${localStorage.getItem(
			"apiUrl",
		)}credentials/downloads`

		if (backwardCompApi("1.20.3")) {
			requestType = "GET"
			requestUrl += `?fileId=${payload.action.fileId}&fileHash=${payload.action.fileHash}`
		}

		xhr.open(requestType, requestUrl)
		xhr.setRequestHeader("X-LS-Token", payload.xLsT)
		xhr.setRequestHeader("Content-Type", "application/json")
		xhr.setRequestHeader("Authorization", "Bearer " + payload.token)
		xhr.setRequestHeader("Access-Control-Allow-Headers", "Origin")
		xhr.responseType = "blob"
		xhr.send(data)
		xhr.addEventListener("error", () => {
			toastr.error(I18n.t("forServices.error"))
		})

		xhr.onload = function (event) {
			let blob = this.response
			let fileName = payload.action.fileName
			let a = document.createElement("a")
			a.href = URL.createObjectURL(blob)
			a.download = fileName
			document.body.appendChild(a)
			a.click()
			document.body.removeChild(a)

			return resolve(event)
		}

		xhr.onerror = function () {
			return reject()
		}
	})
}

export const getSshKey = (payload) => {
	let responseStatus
	let url = localStorage.getItem("apiUrl") + "ssh/key/" + payload.credentialId

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const removeTotp = (payload) => {
	let responseStatus
	let url = `${localStorage.getItem("apiUrl")}credentials/${
		payload.data.passwordId
	}/totp`

	return fetch(url, {
		method: "DELETE",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getCategoryReport = (payload) => {
	let responseStatus
	let responseCode
	let url = `${localStorage.getItem(
		"apiUrl",
	)}categories/report/pdf?categoryId=${payload.data.categoryId}`

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch(() => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			// console.log(e)
		})
		.then((res) => {
			responseStatus = res?.ok
			responseCode = res.status
			return res
		})
		.then((res) => res.blob())
		.then((res) =>
			!responseStatus
				? handleError({
						status: responseCode,
						msg: "Category not found",
				  })
				: res,
		)
}

export const postCategory = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "categories"

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify(payload.action),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const putCategory = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "categories/" + payload.id

	return fetch(url, {
		method: "PUT",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const deleteCategory = (payload) => {
	let responseStatus
	let apiVersion = backwardCompApi("1.11.1") ? `&cascade=${1}` : null

	let url =
		localStorage.getItem("apiUrl") +
		`categories?ids=${payload.action}${
			backwardCompApi("1.19.12") ? "" : apiVersion
		}`

	return fetch(url, {
		method: "DELETE",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getCatGroups = (payload) => {
	////console.log(payload)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") +
		"categories/groups" +
		formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const categoryAddGroup = (payload) => {
	//console.log(payload)

	let responseStatus
	const url = localStorage.getItem("apiUrl") + "categories/groups"

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify(payload.action),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const categoryAddGroupV2 = (payload) => {
	//console.log(payload)

	let responseStatus
	const url = `${localStorage.getItem("apiUrl")}v2/categories/${
		payload?.action?.categoryId
	}/groups`

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify(payload.action),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const categoryAddUser = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "categories/users"

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify(payload.action),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const categoryAddUserV2 = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = `${localStorage.getItem("apiUrl")}v2/categories/${
		payload?.action?.categoryId
	}/users`

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify(payload.action),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const categoryDelGroup = (payload) => {
	//console.log(payload)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") +
		"categories/group?categoryId=" +
		payload.categoryId +
		"&groupIds=" +
		payload.groupIds

	return fetch(url, {
		method: "DELETE",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const categoryDelUser = (payload) => {
	//console.log(payload)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") +
		"categories/user?categoryId=" +
		payload.categoryId +
		"&userIds=" +
		payload.userIds

	return fetch(url, {
		method: "DELETE",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const categoryUserExpi = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "categories/user/expiration/date"

	return fetch(url, {
		method: "PUT",
		headers: headers(payload),
		body: JSON.stringify(payload.action),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const categoryChangeUserGrade = (payload) => {
	//console.log(payload)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") +
		"categories/users/rights/" +
		payload.userIdToSetRight

	return fetch(url, {
		method: "PUT",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const categoryChangeGroupGrade = (payload) => {
	////console.log(payload)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") +
		"categories/groups/rights/" +
		payload.groupIdToSetRight

	return fetch(url, {
		method: "PUT",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const postTag = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "credentials/tags"

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify(payload.action),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const deleteTag = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "credentials/tag"

	return fetch(url, {
		method: "DELETE",
		headers: headers(payload),
		body: JSON.stringify(payload.action),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getPPs = (payload) => {
	// console.log(payload)

	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		"passwordpolicies/" +
		formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			//console.log("err",e)
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) =>
			!responseStatus
				? res?.code === 404
					? "noPPs"
					: handleError(res)
				: res,
		) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getPP = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "passwordpolicies/" + payload.id

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
		.catch((e) => {
			console.error(e)
			return toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
		})
}

export const getFavs = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "categories/favorites"

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const postFav = (payload) => {
	let responseStatus
	let url = localStorage.getItem("apiUrl") + "categories/favorites"

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify({ categoryId: payload.categoryId }),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const deleteFav = (payload) => {
	let responseStatus
	let url =
		localStorage.getItem("apiUrl") +
		`categories/favorites?ids=${payload.categoryId}`

	return fetch(url, {
		method: "DELETE",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : null)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

/* ----------------------------- SSH REQUESTS ----------------------------- */

export const getGuacamole = (payload) => {
	// console.log(payload)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") +
		"ssh/connection/" +
		payload.idCredential

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const deleteGuacamole = (payload) => {
	// console.log(payload)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") +
		"ssh/connection/" +
		payload.connectionId

	return fetch(url, {
		method: "DELETE",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => {
			if (!responseStatus) {
				return
			} else {
				return res
			}
		})
}

/* ----------------------------- TRASH REQUESTS ----------------------------- */
export const getTrashTotal = (payload) => {
	let responseStatus
	let url = `${localStorage.getItem("apiUrl")}credentials/trash/total`

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getTrashPassCard = (payload) => {
	let responseStatus
	let url = `${localStorage.getItem("apiUrl")}credentials/trash/card/${
		payload.id
	}`

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const restoreTrashPass = (payload) => {
	let responseStatus
	let body = JSON.stringify({
		ids: payload?.passwordMasterIds,
	})
	let url = `${localStorage.getItem("apiUrl")}credentials/trash/restore`

	if (payload?.passCatIsDeleted) {
		body = JSON.stringify({
			ids: payload?.passwordMasterIds,
			categoryName: payload?.newCategoryName,
			isShared: payload?.inShared,
		})
		url = `${localStorage.getItem(
			"apiUrl",
		)}credentials/trash/restore/category`
	}

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: body,
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => (payload?.passCatIsDeleted ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getTrashPasswords = (payload) => {
	// type = 'all' | 'personal' | 'shared'
	const { type, order, page, results, search } = payload

	let responseStatus
	let url

	if (search) {
		url = `${localStorage.getItem(
			"apiUrl",
		)}credentials/trash/list?search=${search}&order=${order}`
	} else {
		url = `${localStorage.getItem(
			"apiUrl",
		)}credentials/trash?list=${type}&order=${order}&page=${page}&results=${results}`
	}

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}
/* --------------------------- END TRASH REQUESTS --------------------------- */

export const handleAlertCategories = (payload) => {
	let responseStatus
	let url = localStorage.getItem("apiUrl") + `categories_alerts`

	return fetch(url, {
		method: payload.isAlert ? "POST" : "DELETE",
		headers: headers(payload),
		body: JSON.stringify({ categoryId: payload.categoryId }),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : null)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}
