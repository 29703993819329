export const NEWPWD = {
	URL: "url",
	NAME: "name",
	USERNAME: "username",
	PASSWORD: "password",
	DESCRIPTION: "description",
	TOTP: "totp",
	OPT1: "opt1",
	OPT2: "opt2",
	OPT3: "opt3",
	CAT: "cat",
}

export const DEFAULTNEWPWD = {
	[NEWPWD.URL]: "",
	[NEWPWD.NAME]: "",
	[NEWPWD.USERNAME]: "",
	[NEWPWD.DESCRIPTION]: "",
	[NEWPWD.CAT]: "",
}
