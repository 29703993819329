import { toastr } from "react-redux-toastr"
import I18n from "../../../../i18n"
import { handleError } from "../../../../actions/generalUtils"

function headers(payload) {
	let header = {
		"Accept-Language": localStorage.getItem("i18nextLng"),
		Authorization: "Bearer " + payload.token,
		"X-Ls-Token": payload.xLsT,
	}
	if (!payload.formData) {
		header = { ...header, "Content-Type": "application/json" }
	}

	return header
}

function formQuery(d) {
	let query = ""
	let first = true

	if (d.organizationId) {
		if (first) {
			query += "?organization=" + d.organizationId
		} else {
			query += "&organization=" + d.organizationId
		}
	}
	return query
}

// strenght
export const getPasswordStrength = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		"dashboard_credential_strength" +
		formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getPasswordStrengthList = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		`credentials_list_strength/organization/${payload.organizationId}?&results=${payload.result}&page=${payload.page}&strength=` +
		payload.metric

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

// seniority frequency of use
export const getCredential = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		"dashboard_credential" +
		formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getPasswordSeniorityList = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		`dashboard_credentials_age/organization/${payload.organizationId}?&results=${payload.result}&page=${payload.page}&updated=` +
		payload.metric

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getPasswordUseList = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		`dashboard_list_credential_use/organization/${payload.organizationId}?results=${payload.result}&page=${payload.page}&percentUsed=${payload.metric}`

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

//compartmentalization
export const getCategoryCompartimentation = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		"dashboard_category" +
		formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getCategoryCompartimentationList = (payload) => {
	let responseStatus

	const url =
		localStorage.getItem("apiUrl") +
		`dashboard_categories_shared/organization/${payload.organizationId}?results=${payload.result}&page=${payload.page}&credentialFilter=${payload.credentialFilter}&percentUsed=` +
		payload.metric

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

// category policy
export const getPasswordPolicy = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		"dashboard_password_policy" +
		formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getPasswordPolicyList = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		`dashboard_password_policy_details/organization/${payload.organizationId}?&results=${payload.result}&page=${payload.page}&credentialFilter=${payload.credentialFilter}&policy=` +
		payload.metric

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const exportLowStrength = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		`credentials/export/lowstrength?offset=${payload.offset}`

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.text())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}
