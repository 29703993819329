import { createSlice } from "@reduxjs/toolkit"
import moment from "moment"

let isDarkMode = localStorage?.getItem("LS_darkMode")
	? localStorage?.getItem("LS_darkMode")
	: false
let hasProductId = localStorage?.getItem("productId")
	? localStorage?.getItem("productId")
	: ""
let isFullScreen = localStorage?.getItem("fullScreen")
	? localStorage?.getItem("fullScreen")
	: false
let isListMode = localStorage?.getItem("listMode")
	? localStorage?.getItem("listMode")
	: true
let isNavMini = localStorage?.getItem("navMini")
	? localStorage?.getItem("navMini")
	: false

const aoSlice = createSlice({
	name: "accountOptions",
	initialState: {
		lang: "",
		darkMode: isDarkMode ? JSON.parse(isDarkMode) : false,
		productId: hasProductId ? JSON.parse(hasProductId) : "",
		fullScreen: isFullScreen ? JSON.parse(isFullScreen) : false,
		listMode: isListMode ? JSON.parse(isListMode) : true,
		navMini: isNavMini ? JSON.parse(isNavMini) : false,
		colorProduct: "",
		isOverViewDB: false,
		popupInfoDb: [],
		emptyCron: false,
		isSSO: null,
	},
	reducers: {
		setLang: (state, action) => {
			state.lang = action.payload
			moment.locale(action.payload?.substring(0, 2))
		},
		setProductId: (state, action) => {
			// console.log(action.payload?.productId)
			state.productId = !action.payload?.productId
				? ""
				: action.payload?.productId
		},
		toggleDarkMode: (state) => {
			//console.log(state.darkMode)
			state.darkMode = !state.darkMode
			localStorage.setItem("LS_darkMode", state.darkMode)
		},
		toggleFullScreen: (state) => {
			state.fullScreen = !state.fullScreen
			localStorage.setItem("fullScreen", state.fullScreen)
		},
		toggleListMode: (state) => {
			state.listMode = !state.listMode
			localStorage.setItem("listMode", state.listMode)
		},
		toggleNav: (state, action) => {
			if (action.payload?.mode) {
				state.navMini = action.payload.type
			} else {
				state.navMini = !state.navMini
			}
			localStorage.setItem("navMini", state.navMini)
		},
		setSSO: (state, action) => {
			state.isSSO = action.payload
		},
		setColorProduct: (state, action) => {
			state.colorProduct = action.payload
		},
		setOverViewDB: (state, action) => {
			state.isOverViewDB = action.payload
		},
		setEmptyCron: (state, action) => {
			state.emptyCron = action.payload
		},
		setPopupInfoDb: (state, action) => {
			state.popupInfoDb = [
				{
					title: action.payload.title,
					subTitle: action.payload.subTitle,
					textCut: action.payload.textCut,
					url: action.payload.url,
				},
			]
		},
	},
})

export const {
	setLang,
	toggleDarkMode,
	toggleFullScreen,
	toggleListMode,
	toggleNav,
	setSSO,
} = aoSlice.actions

export default aoSlice.reducer
