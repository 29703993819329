import { toastr } from "react-redux-toastr"
import I18n from "../../../../i18n"
import { handleError } from "../../../../actions/generalUtils"

function headers(payload) {
	let header = {
		"Accept-Language": localStorage.getItem("i18nextLng"),
		Authorization: "Bearer " + payload.token,
		"X-Ls-Token": payload.xLsT,
	}
	if (!payload.formData)
		header = { ...header, "Content-Type": "application/json" }

	return header
}

function formQuery(d) {
	let query = ""
	let first = true

	if (d.organizationId) {
		if (first) query += "?organization=" + d.organizationId
		else query += "&organization=" + d.organizationId
	}
	return query
}

//////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////   dashboard monitoring   /////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
export const getDashboardMonitoringUsage = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		"dashboard_usage_monitoring" +
		formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getDashboardMonitoringUsageGraph = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		"dashboard_usage_monitoring_graph/organization/" +
		payload.organizationId

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////     storage      //////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
export const getStorageData = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		"dashboard_storage_usage" +
		formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getStorageDataPassList = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		`dashboard_storage_usage_lock_pass/organization/${payload.organizationId}?&results=${payload.result}&page=${payload.page}`

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}
export const getStorageDataTransferList = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		`dashboard_storage_usage_transfers/organization/${payload.organizationId}?&results=${payload.result}&page=${payload.page}`

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}
export const getStorageDataDepositBoxList = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		`dashboard_storage_usage_deposit_boxes/organization/${payload.organizationId}?&results=${payload.result}&page=${payload.page}`

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}
export const getStorageDataSharedSpaceList = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		`dashboard_storage_usage_lock_files_shared/organization/${payload.organizationId}?&results=${payload.result}&page=${payload.page}`

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}
export const getStorageDataPersonalSpaceList = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		`dashboard_storage_usage_lock_files_personal/organization/${payload.organizationId}?&results=${payload.result}&page=${payload.page}`

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

//////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////   exhibition area   //////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
export const getFolderlExhibitionArea = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		"dashboard_folders_sharing" +
		formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getFolderlExhibitionAreaList = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		`dashboard_folders_sharing/organization/${payload.organizationId}?&results=${payload.result}&page=${payload.page}&order=${payload.credentialFilter}&shared=` +
		payload.metric

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getTransferExhibitionArea = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		"dashboard_transfer_sharing" +
		formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getTransferExhibitionAreaList = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		`dashboard_transfer_sharing/organization/${payload.organizationId}?&results=${payload.result}&page=${payload.page}&shared=` +
		payload.metric

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getDepositExhibitionArea = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		"dashboard_deposit_boxes_sharing" +
		formQuery(payload) +
		`&isInternal=${false}`

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getDepositExhibitionAreaList = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		`dashboard_deposit_boxes_sharing/organization/${payload.organizationId}?&results=${payload.result}&page=${payload.page}&shared=` +
		payload.metric

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

//////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////     highlighted     //////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
export const getLargeShareDeposit = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		"dashboard_files_size" +
		formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getLargeShareDepositList = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		`dashboard_files_size/organization/${payload.organizationId}?&results=${payload.result}&page=${payload.page}&filter=${payload.mode}&size=` +
		payload.metric

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getOldShare = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		"dashboard_transfers_age" +
		formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getOldShareList = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		`dashboard_transfers_age/organization/${payload.organizationId}?&results=${payload.result}&page=${payload.page}&age=` +
		payload.metric

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getOldDeposit = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		"dashboard_deposit_boxes_age" +
		formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getOldDepositList = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		`dashboard_deposit_boxes_age/organization/${payload.organizationId}?&results=${payload.result}&page=${payload.page}&age=` +
		payload.metric

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

//////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////       opening       //////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
export const getMapDownloadPagination = async (payload) => {
	const url =
		localStorage.getItem("apiUrl") +
		`dashboard_ip/organization/${payload.organizationId}?limit=${payload.limit}&offset=${payload.offset}`
	let responseStatus

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getMapDownload = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		`dashboard_ip/organization/${payload.organizationId}`

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getRecipientDomain = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		`dashboard_domain/organization/${payload.organizationId}`

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getRecipientDomainList = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		`dashboard_domain_list/organization/${payload.organizationId}?&results=${payload.result}&page=${payload.page}&domain=` +
		payload.domain

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}
