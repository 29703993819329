// I added a few words at the bottom of the document about the loader stateS (yes, there are multiples keys for this). If you're
// trying to understand how the completion and remaining time, the text will clarify a few details.
import { createSlice } from "@reduxjs/toolkit"
import { backwardCompApi } from "../../actions/generalUtils"

const base = [
	{
		name: "mLayout.personnalSpace",
		files: [],
		reps: [],
		id: -1,
		mainId: 0,
		static: true,
		searchable: true,
		showHelp: "lF",
		boardHelp: "lF",
		repName: "mLayout.folder",
		fileName: "files.filesList",
		comp: "File",
		actionBtn: [{ name: "files.addFolderBtn", action: "addFolder" }],
		rootLoadData: "LF_GET_DATA_SAGA",
		loadData: "LF_GET_DATA_PERMALINK_SAGA",
		icon: "personal",
	},
	{
		name: "mLayout.shared",
		id: 0,
		static: true,
		searchable: true,
		showHelp: "lF",
		boardHelp: "lF",
		repName: "mLayout.folder",
		fileName: "files.filesList",
		comp: "File",
		actionBtn: [{ name: "files.addFolderBtn", action: "addFolder" }],
		rootLoadData: "LF_GET_DATA_SAGA",
		loadData: "LF_GET_DATA_PERMALINK_SAGA",
		icon: "share",
	},
]

//"LFdragAndDrop",
//actionBtn: [{ name: "files.addFileBtn", action: "addFiles" }, { name: "files.addFolderBtn", action: "addFolder" }],

const filesSlice = createSlice({
	name: "files",
	initialState: {
		loading: true,
		slideLoading: true,
		data: null,
		history: [],
		active: {},
		searchMode: false,
		searchVal: "",
		searchResults: [],
		lastActionResult: null,
		isFile: false,
		tempoActFile: [],
		duplicateUploadDecision: null,
		arbo: base,
		arboChildren: [],
		isArboLoaded: false,
	},
	reducers: {
		init: (state) => {
			////console.log("INIT")
			state.data = base
			state.history = []
			state.active = {}
			state.searchMode = false
			state.searchVal = ""
			state.loading = true
		},
		setErrorNotFound: (state, action) => {
			state.errorNotFound = action?.payload ? action?.payload : false
			state.callRunning = false
		},
		toggleLoading: (state, action) => {
			if (!action.payload) state.loading = false
			else state.loading = action.payload
		},
		toggleSlideLoading: (state) => {
			state.slideLoading = !state.slideLoading
		},
		toggleChildrenLoading: (state, action) => {
			if (!action.payload) {
				state.childrenLoading = false
			} else {
				state.childrenLoading = action.payload
			}
		},
		setLAR: (state, action) => {
			if (!action.payload) state.lastActionResult = null
			else state.lastActionResult = action.payload
		},
		setCR: (state, action) => {
			if (!action.payload) state.callRunning = false
			else state.callRunning = action.payload
		},
		setIsFile: (state, action) => {
			state.isFile = action.payload
		},
		setTempoActFile: (state, action) => {
			if (!action.payload) {
				state.tempoActFile = null
			} else {
				state.tempoActFile = action.payload
			}
		},
		addFiles: (state, action) => {
			////console.log(action)

			let i = 0

			let og = state.data[i]
			let newState = [...state.data]

			// On prépare les nouveaux dossiers
			let f = action.payload.files
			let newF = []

			if (f?.length > 0) {
				f.map((item) => {
					return newF.push({ ...item, comp: "File" })
				})
			}

			// On les ajoute au nouveau state

			newState[i] = {
				...og,
				files: og.files?.length > 0 ? [...og.files, ...newF] : newF,
			}
			state.data = newState
		},
		addChildren: (state, action) => {
			const children = action.payload.data

			state.arboChildren = {
				...state.arboChildren,
				[action.payload.parentId]: children,
			}
		},
		addReps: (state, action) => {
			////console.log(action)
			let i
			if (action.payload.stateTarget) i = action.payload.stateTarget
			else i = 0

			let og = state.data[i]
			let ogArbo = state.arbo[i]
			let newState = [...state.data]
			let arboState = [...state.arbo]

			// On prépare les nouveaux dossiers
			let d = action.payload.data
			let newD = []

			if (d?.length > 0) {
				d.map((item) => {
					return newD.push({
						...item,
						showHelp: "lF",
						boardHelp: "lF",
						repName: "mLayout.folder",
						fileName: "files.filesList",
						comp: "File",
						loadData: "LF_GET_DATA_SAGA",
					})
				})
			}

			// On les ajoute au nouveau state
			let reps = action.payload.doNotKeep
				? newD
				: og.reps?.length > 0
				? [...og.reps, ...newD]
				: newD

			let arboReps = action.payload.doNotKeep
				? newD
				: ogArbo.reps?.length > 0
				? [...ogArbo.reps, ...newD]
				: newD

			newState[i] = { ...og, reps }
			arboState[i] = { ...ogArbo, reps: arboReps }

			if (!action.payload.initArbo || !backwardCompApi("1.20.16")) {
				state.data = newState
			}

			if (!action.payload.keepArbo) {
				state.arbo = arboState
			}

			//state.loading = false
		},
		enterInFolder: (state, action) => {
			let pt = action.payload.productTarget
				? action.payload.productTarget
				: 0 // Quel sous produit on vise ? N'existe que si l'on est à la base du produit et depuis le compass affichant tous les sous produits

			if (action.payload.justRefresh) {
				// Lorsqu'on reste dans le même repertoire mais qu'on update seulement les données (par ex après post ou delete)

				// Ici on combine la data de fichiers/dossiers dans le state redux, et on y ajoute les fichiers récupérés depuis l'appel api
				let a = []
				let og = state.data[0]

				let repUsers = []
				if (action.payload.groups) {
					action.payload.groups.map((g) =>
						repUsers.push({ ...g, email: "Group" }),
					)
				}
				if (action.payload.users)
					repUsers = [...repUsers, ...action.payload.users]

				a.push({
					...og,
					files: action.payload.files ? action.payload.files : [],
					reps: action.payload.reps ? action.payload.reps : [],
					users: repUsers,
				})

				if (action.payload.retriveActive) {
					let a = action.payload._embedded.items.find(
						(item) =>
							item.id ===
							state.active[Object.keys(state.active)[0]].id,
					)
					let elemType =
						state.active[Object.keys(state.active)[0]].elemType
					let ai = Object.keys(state.active)[0]

					state.active = { [ai]: { ...a, elemType } }
				} else state.active = {}

				if (state.data[1]) a.push(state.data[1])

				state.data = a
				//state.pagin = { limit: action.payload.limit, page: action.payload.page, pages: action.payload.pages, total: action.payload.total }
				return
			}

			if (!action.payload.historyState) {
				//si on entre dans un nouveau dossier (non depuis l'historique du compass)

				state.history.push({
					name: state.data[pt]?.name,
					loadData: state.data[pt].loadData,
					historyState: JSON.stringify(state.history),
					repName: state.data[pt].repName,
					fileName: state.data[pt].fileName,
					comp: state.data[pt].comp,
					noSelect: state.data[pt].noSelect ? true : false,
					showHelp: state.data[pt].showHelp
						? state.data[pt].showHelp
						: false,
					actionBtn: state.data[pt].actionBtn,
					searchable: state.data[pt].searchable ? true : false,
					categoryId: state.data[0].id,
					parentId: state.data[0].parentId,
					mainId: state.data[0].mainId,
					isInCategory: state.data[0].isInCategory,
				})

				if (action.payload?.historyData) {
					state.history.push(
						...action.payload?.historyData?.map((historyItem) => ({
							id: historyItem.id,
							name: historyItem.name,
							loadData: historyItem.loadData,
							historyState: JSON.stringify(state.history),
							repName: historyItem.repName,
							fileName: historyItem.fileName,
							comp: historyItem.comp,
							parentId: historyItem.parentId,
							noSelect: historyItem?.noSelect ? true : false,
							showHelp: historyItem?.showHelp
								? historyItem.showHelp
								: false,
							actionBtn: historyItem?.actionBtn,
							searchable: historyItem?.searchable ? true : false,
							categoryId: state.data[pt].id,
							mainId: state.data[pt].mainId,
							isInCategory: state.data[pt].isInCategory,
						})),
					)
				}

				// Ici on combine la data de fichiers/dossiers dans le state redux, et on y ajoute les fichiers récupérés depuis l'appel api
				let a = []
				let og = state.data[pt]?.reps?.[action.payload.domTarget]

				// On prépare les nouveaux dossiers
				let receivedReps = action.payload.reps
				let newReps = []

				if (receivedReps?.length > 0) {
					receivedReps.map((item) => {
						return newReps.push({
							...item,
							showHelp: "lF",
							boardHelp: "lF",
							repName: "mLayout.folder",
							fileName: "files.filesList",
							comp: "File",
							loadData: "LF_GET_DATA_SAGA",
						})
					})
				}

				let repUsers = []
				if (action.payload.groups) {
					action.payload.groups.map((g) =>
						repUsers.push({ ...g, email: "Group" }),
					)
				}
				if (action.payload.users)
					repUsers = [...repUsers, ...action.payload.users]

				a.push(
					action.payload?.reset
						? {
								...action.payload,
								searchable: true,
								...(!og?.static
									? { actionBtn: state.data[pt].actionBtn }
									: {}),
						  }
						: {
								...og,
								users: repUsers,
								searchable: true,
								files: action.payload?.files
									? action.payload?.files
									: [], //action.payload._embedded?.items,
								reps: newReps,
								...(!og?.static
									? { actionBtn: state.data[pt].actionBtn }
									: {}),
						  },
				)

				state.data = a
				state.active = {}
				//state.pagin = { limit: action.payload.limit, page: action.payload.page, pages: action.payload.pages, total: action.payload.total }
			} else {
				// ici on revient donc sur un dossier précedent

				state.history = JSON.parse(action.payload.historyState)

				let repUsers = []
				if (action.payload.groups) {
					action.payload.groups.map((g) =>
						repUsers.push({ ...g, email: "Group" }),
					)
				}
				if (action.payload.users)
					repUsers = [...repUsers, ...action.payload.users]

				let ns = []
				let p = action.payload

				ns.push({
					name: p.name,
					static: p.static,
					loadData: p.loadData,
					files: p._embedded?.items,
					repName: p.repName,
					fileName: p.fileName,
					comp: p.comp,
					reps: [],
					noSelect: p.noSelect ? true : false,
					showHelp: p.showHelp ? p.showHelp : false,
					actionBtn: p.actionBtn,
					searchable: true,
					users: repUsers,
				})

				state.data = ns
				state.active = {}
				//state.loading = false
				//state.pagin = { limit: action.payload.limit, page: action.payload.page, pages: action.payload.pages, total: action.payload.total }
			}
		},
		manageSelected: (state, action) => {
			if (state.active[action.payload.elemKey]) {
				// Si l'élément est déjà dans les actifs on le retire
				delete state.active[action.payload.elemKey]
			} else {
				if (action.payload.uniSelect)
					state.active = {
						[action.payload.elemKey]: action.payload,
					}
				// Si on est en séléction unique (clic sur la card) on reset la sélec par l'élément
				else
					state.active = {
						...state.active,
						[action.payload.elemKey]: action.payload,
					} // sinon on le rajoute dans la sélec
			}
		},
		updateTree: (state, action) => {
			// create by Theo, ask him if you have some questions : t.makowiak@lockself.com

			let tree = [...state.branchInfo.tree]
			let originFolderIndex = null
			let originFolderIsFound = false
			let updatedBranch = {}
			let branchIsUpdated = false

			const getModifyFolderOrigin = (folder, index) => {
				if (originFolderIsFound) return

				originFolderIndex = index

				let findIdFolder = (subFolder) => {
					if (subFolder.id === action.payload.id) {
						originFolderIsFound = true
					} else {
						subFolder.children &&
							subFolder.children.forEach(findIdFolder)
					}
				}

				if (folder.id === action.payload.id) {
					originFolderIsFound = true
				} else {
					folder.children && folder.children.forEach(findIdFolder)
				}
			}

			const updateBranchWithNewFolder = (branch) => {
				const nestedBranch = branch

				if (nestedBranch.id === action.payload.id) {
					nestedBranch.name = action.payload.name
				} else if (branch.children !== null) {
					const nestedBranchChildren = []

					nestedBranch?.children?.forEach((branch) => {
						nestedBranchChildren.push(
							updateBranchWithNewFolder(branch),
						)
					})

					nestedBranch.children = nestedBranchChildren
				}

				return nestedBranch
			}

			;(state.data[0].mainId === 0 ||
			state.data[0].mainId === state.data[0].id
				? tree
				: tree[0].children
			).forEach(getModifyFolderOrigin)

			updatedBranch = (
				state.data[0].mainId === 0 ||
				state.data[0].mainId === state.data[0].id
					? tree
					: tree[0].children
			)[originFolderIndex]

			if (updatedBranch?.id === action.payload?.id) {
				updatedBranch.name = action.payload?.name
				branchIsUpdated = true
			}

			if (!branchIsUpdated) {
				const branchChildren = []

				updatedBranch?.children?.forEach((branch) => {
					branchChildren.push(updateBranchWithNewFolder(branch))
				})

				updatedBranch.children = branchChildren
			}

			;(state.data[0].mainId === 0 ||
			state.data[0].mainId === state.data[0].id
				? tree
				: tree[0].children)[originFolderIndex] = updatedBranch
			state.branchInfo.tree = tree
		},
		updateActive: (state, action) => {
			if (!action.payload?.removeFile && !action.payload?.addFile) {
				let targetPos = Object.keys(state.active)[0]
				let a = {
					...state.active[targetPos],
					...action.payload.data,
					elemKey: state.active[targetPos].elemKey,
					elemType: state.active[targetPos].elemType,
				}

				state.active[targetPos] = a // On update l'élément actif (qui est une copie de son original dans la data du store du produit)

				state.data[0].files[
					state.data[0].files.findIndex(
						(f) => f.id === action.payload.id,
					)
				] = a // On l'update aussi dans son store original
			} else if (action.payload?.addFile) {
				delete action.payload?.addFile

				state.active[action.payload?.id] = action.payload
			} else {
				delete state.active[action.payload?.id]
			}

			state.slideLoading = false
		},
		updateData: (state, action) => {
			let a = []
			let repUsers = []

			if (action.payload.groups) {
				action.payload.groups.forEach((g) => {
					repUsers.push({ ...g, email: "Group" })
					return
				})
			}
			if (action.payload.users)
				repUsers = [...repUsers, ...action.payload.users]

			a.push({
				...state.data[0],
				...action.payload,
				users: repUsers,
				reps: action.payload.children
					? action.payload.children
					: state.data[0].reps,
			})

			state.data = a
		},
		saveBranch: (state, action) => {
			if (action.payload) state.branchInfo = action.payload
		},
		unselectAll: (state) => {
			state.active = {}
		},
		selectAll: (state) => {
			let a
			state.data[0].files.map((file) => {
				return (a = {
					...a,
					[file?.id]: { ...file, elemType: state.data?.[0]?.comp },
				})
			})
			state.active = a
		},
		setSearchVal: (state, action) => {
			state.searchVal = action.payload
		},
		initSearch: (state, action) => {
			//console.log(action)

			let a = []
			let og = state.data[0]
			a.push({
				...og,
				files: action.payload.files /*action.payload._embedded.items*/,
				id: undefined,
				name: "mLayout.compass.results",
				reps: null,
				actionBtn: null,
			})
			state.data = a

			state.searchMode = true
			state.searchResults = []
			state.active = {}
			state.loading = false
			state.branchInfo = null
			//state.pagin = { limit: action.payload.limit, page: action.payload.page, pages: action.payload.pages, total: action.payload.total }
		},
		manualFilter: (state, action) => {
			let val = action.payload.toUpperCase()

			if (state.searchResults.length === 0)
				state.searchResults = state.data[0].files

			let newFiles = []
			let tempoFiles = JSON.parse(JSON.stringify(state.searchResults))

			tempoFiles.map((item) => {
				if (
					item.nameToDisplay?.toUpperCase().includes(val) ||
					item.token?.toUpperCase().includes(val)
				)
					return newFiles.push(item)
				else return undefined
			})

			state.data[0].files = newFiles
			//state.pagin = {}
		},
		blockFilesUpload: (state) => {
			state.stopFilesUpload = true
			return
		},
		unblockFilesUpload: (state) => {
			delete state.stopFilesUpload
			return
		},
		addUncertainFile: (state, action) => {
			if (typeof state.uncertainFiles === "object") {
				state.uncertainFiles.push(action.payload)
			} else {
				state.uncertainFiles = [action.payload]
			}

			return
		},
		removeUncertainFiles: (state) => {
			delete state.uncertainFiles
			return
		},
		toggleUserNotifStatus: (state, action) => {
			for (let i = 0; i < state.data[0].users.length; i++) {
				const item = state.data[0].users[i]
				if (item.id === action.payload.id) {
					item.uploadNotification = !item.uploadNotification
				}
				if (item.isInGroup) {
					item.uploadNotification = !item.uploadNotification
				}
			}

			return state
		},
		setDuplicateUploadDecision: (state, action) => {
			if (typeof action.payload === "string") {
				state.duplicateUploadDecision = action.payload
			} else {
				state.duplicateUploadDecision = undefined
			}
		},
		setSharedSpaceRootFolders: (state, action) => {
			if (!!action?.payload) {
				state.sharedSpaceRootFolders = action?.payload
			}
		},
		setArboStatus: (state, action) => {
			if (action.payload?.space === "share") {
				state.isArboLoaded = {
					...state.isArboLoaded,
					share: true,
				}
			} else {
				state.isArboLoaded = {
					...state.isArboLoaded,
					perso: true,
				}
			}
		},
	},
})

export const { init } = filesSlice.actions

export default filesSlice.reducer

// You may wonder why there are multiple keys for the loader in the store (or you just read the first 2 lines of this files and are curious).
// You've probably noticed the existence of "loading" and "loader" by now. The "loading" key existed for quite some time now, and it used to
// manage the loader display (show/hide the loader as well as the completion %). When I added the time estimation feature, *NO TIME TO END
// THIS TEXT, if I ever push and merge this modification, please force me to finish it*
