import { createSlice } from "@reduxjs/toolkit"

const base = {
	threatsList: [],
}

const dashboardOverViewSlice = createSlice({
	name: "dashboardOverView",
	initialState: {
		data: null,
		openViewHelp: null,
		threatsListLoading: false,
	},
	reducers: {
		init: (state) => {
			state.data = base
			state.openViewHelp = null
		},
		setThreatsListLoading: (state, action) => {
			if (action.payload) {
				state.threatsListLoading = action.payload
			} else {
				state.threatsListLoading = false
			}
		},
		setThreatsList: (state, action) => {
			if (action.payload) {
				state.data.threatsList = action.payload
			} else {
				state.data.threatsList = {}
			}
		},
		setOpenViewHelp: (state, action) => {
			state.openViewHelp = action.payload
		},
	},
})

export const { init } = dashboardOverViewSlice.actions

export default dashboardOverViewSlice.reducer
