import { toastr } from "react-redux-toastr"
import I18n from "../i18n"
import { handleError, getLicenseUrl, sleep } from "../actions/generalUtils"

let LICENSE_URL = getLicenseUrl()

function headers(payload) {
	let a = {
		"Accept-Language": localStorage.getItem("i18nextLng"),
		Authorization: "Bearer " + payload.token,
		"X-Ls-Token": payload.xLsT,
	}

	if (!payload.formData) a = { ...a, "Content-Type": "application/json" }
	if (payload.isPatch)
		a = { ...a, "Content-Type": "application/merge-patch+json" }

	return a
}

export const getLicenseIds = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "license"

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res))
}

export const getLicenseInfo = (payload) => {
	//console.log(payload)

	let responseStatus
	let resCode
	let url = LICENSE_URL + "licenses/" + payload.license

	return fetch(url, {
		method: "GET",
		headers: { "Content-Type": "application/json" },
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			resCode = res.status
			return res
		})
		.then((res) => res.json())
		.then((res) =>
			!responseStatus ? /*handleError(res)*/ { res, resCode } : res,
		)
}

export const activateNew = (payload) => {
	//console.log(payload)

	let responseStatus
	let resCode
	let url = `${LICENSE_URL}licenses/${payload.id}?email=${encodeURIComponent(
		payload.email,
	)}`

	return fetch(url, {
		method: "GET",
		headers: { "Content-Type": "application/json" },
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			resCode = res.status
			return res
		})
		.then((res) => (responseStatus ? res.json() : "error"))
		.then((res) => (responseStatus ? res : { res, resCode }))
}

export const renewLicense = (payload) => {
	//console.log(payload)

	let responseSuccess
	let url = localStorage.getItem("apiUrl") + "license/renewal"
	let body = JSON.stringify(payload.data)
	let requestAborted = false

	return fetch(url, {
		method: "PUT",
		headers: headers(payload),
		body,
	})
		.catch((e) => {
			requestAborted = true
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			return e
		})
		.then((res) => {
			if (requestAborted) return
			responseSuccess = res?.ok
			return res
		})
		.then((res) => {
			if (requestAborted) return
			if (!responseSuccess) return res.json()
			return "renewed"
		})
		.then((res) => {
			if (requestAborted) return
			if (!responseSuccess) {
				handleError(res)
				return true
			}

			return res
		})
}

export const patchOld = (payload) => {
	//console.log(payload)

	let resCode
	let responseStatus
	let url = `${LICENSE_URL}licenses/${
		payload.data.oldId
	}?email=${encodeURIComponent(payload.data.email)}`

	return fetch(url, {
		method: "PATCH",
		headers: { "Content-Type": "application/merge-patch+json" },
		body: JSON.stringify({ expirationDate: payload.data.date }),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			resCode = res.status
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? { res, resCode } : res))
}

export const patchRenew = (payload, wrongUrl) => {
	let resCode
	let responseStatus
	let url = `${LICENSE_URL}licenses/${
		payload.data.id
	}?email=${encodeURIComponent(payload.data.email)}`

	if (wrongUrl)
		url = `${LICENSE_URL}license/maguewle/${
			payload.data.id
		}?email=${encodeURIComponent(payload.data.email)}`

	return fetch(url, {
		method: "PATCH",
		headers: { "Content-Type": "application/merge-patch+json" },
		body: JSON.stringify({ isValidated: true }),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			resCode = res.status
			return res
		})
		.then((res) => (!responseStatus ? res.json() : "renewed"))
		.then((res) => (!responseStatus ? { res, resCode } : res))
}

export const patchDeactivate = (payload, retries = 0) => {
	let responseStatus
	let url = `${LICENSE_URL}licenses/${
		payload.data.id
	}?email=${encodeURIComponent(payload.data.email)}`

	return fetch(url, {
		method: "PATCH",
		headers: { "Content-Type": "application/merge-patch+json" },
		body: JSON.stringify({ isValidated: false }),
	})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => {
			if (responseStatus) return "renewed"
			return res.json()
		})
		.then((res) => {
			if (responseStatus) return res
			return handleError(res)
		})
		.catch((e) => {
			if (retries < 5) {
				toastr.error(
					I18n.t("forServices.networkError"),
					I18n.t("forServices.triesLeft", {
						tryNumber: retries + 1,
					}),
				)

				return sleep(5000).then(() =>
					patchDeactivate(payload, retries + 1),
				)
			}

			toastr.error(I18n.t("licenses.view.contactSupport"))
			return handleError(e)
		})
}

export const getSlots = (payload) => {
	let responseStatus
	let url = localStorage.getItem("apiUrl") + "slots"

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res))
}
