import { toastr } from "react-redux-toastr"
import I18n from "../../i18n"
import { handleError } from "../../actions/generalUtils"

function headers(payload) {
	let a = {
		"Accept-Language": localStorage.getItem("i18nextLng"),
		Authorization: "Bearer " + payload.token,
		"X-Ls-Token": payload.xLsT,
	}

	if (!payload.formData) a = { ...a, "Content-Type": "application/json" }

	return a
}

function formQuery(data) {
	let query = ""
	let first = true

	if (data.offset) {
		first = false
		query += "?offset=" + data.offset
	}

	if (data.id) {
		if (first) {
			query += "?id=" + data.id
		} else {
			query += "&id=" + data.id
		}
		first = false
	}

	if (data.limit) {
		if (first) {
			query += "?limit=" + data.limit
		} else {
			query += "&limit=" + data.limit
		}
		first = false
	}

	if (data.search) {
		if (first) {
			query += "?search=" + encodeURIComponent(data.search)
		} else {
			query += "&search=" + encodeURIComponent(data.search)
		}
		first = false
	}

	if (data.organizationId) {
		if (first) {
			query += "?organizationId=" + data.organizationId
		} else {
			query += "&organizationId=" + data.organizationId
		}
		first = false
	}

	if (data.limitToOrga) {
		if (first) {
			query += "?allOrganizations=false"
		} else {
			query += "&allOrganizations=false"
		}
		first = false
	}

	if (typeof data.categoryId !== "undefined") {
		if (first) {
			query += "?categoryId=" + data.categoryId
		} else {
			query += "&categoryId=" + data.categoryId
		}
		first = false
	}

	if (typeof data.searchType !== "undefined") {
		if (first) {
			query += "?searchType=" + data.searchType
		} else {
			query += "&searchType=" + data.searchType
		}
		first = false
	}

	if (typeof data.getAD !== "undefined") {
		if (data.getAD) {
			if (first) {
				query += "?opt=ad"
			} else {
				query += "&opt=ad"
			}
			first = false
		} else {
			if (first) {
				query += "?opt=notAd"
			} else {
				query += "&opt=notAd"
			}
			first = false
		}
	}

	if (typeof data.groupId !== "undefined") {
		if (first) {
			query += "?groupId=" + data.groupId
		} else {
			query += "&groupId=" + data.groupId
		}
		first = false
	}

	if (data.sortType && data.sortBy) {
		let sortValue = ""

		if (data.sortType === "alpha") {
			sortValue = data.sortBy === "asc" ? "asc" : "desc"
		} else if (data.sortType === "date") {
			sortValue = data.sortBy === "asc" ? "dateAsc" : "dateDesc"
		}

		if (first) {
			query += "?sort=" + sortValue
		} else {
			query += "&sort=" + sortValue
		}
		first = false
	}

	if (typeof data.opt !== "undefined") {
		query += "&opt=" + data.opt
	}

	return query
}

export const getGroups = (payload) => {
	if (payload.preventGroupFetch) {
		return false
	}
	let responseStatus

	let url = localStorage.getItem("apiUrl") + "groups" + formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getGroupUsers = (payload) => {
	//console.log(payload)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") + "groups/users" + formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const postGroup = (payload) => {
	////console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "groups"

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const putGroup = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "groups/" + payload.id

	return fetch(url, {
		method: "PUT",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const deleteGroup = (payload) => {
	let responseStatus
	let url = localStorage.getItem("apiUrl") + "groups?ids=" + payload.id

	return fetch(url, {
		method: "DELETE",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const addUserToGroup = (payload) => {
	////console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "groups/users"

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const addUserToGroupV2 = (payload) => {
	////console.log(payload)

	let responseStatus
	let url = `${localStorage.getItem("apiUrl")}v2/groups/${
		payload?.data?.groupId
	}/users`

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const removeUserFromGroup = (payload) => {
	let responseStatus
	let url =
		localStorage.getItem("apiUrl") +
		"groups/user?groupId=" +
		payload.groupId +
		"&userIds=" +
		payload.userIds

	return fetch(url, {
		method: "DELETE",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getOrgs = (payload) => {
	////console.log(payload)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") + "organizations" + formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const postOrg = (payload) => {
	////console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "organizations"

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) =>
			!responseStatus
				? res?.code === 500
					? "badEncode"
					: handleError(res)
				: res,
		) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const putOrg = (payload) => {
	//console.log(payload)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") + "organizations/name/" + payload.id

	return fetch(url, {
		method: "PUT",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getOrgUsers = (payload) => {
	const sortType = localStorage.getItem("sortType")
	const sortBy = localStorage.getItem("sortBy")

	let responseStatus

	let dataForQuery = { ...payload, sortType, sortBy }
	delete dataForQuery.organizationId

	let url =
		localStorage.getItem("apiUrl") +
		"users/" +
		payload.organizationId +
		"/organization" +
		formQuery(dataForQuery)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const deleteOrga = (payload) => {
	let responseStatus
	let url = localStorage.getItem("apiUrl") + "organizations/" + payload.id

	return fetch(url, {
		method: "DELETE",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : "deleted")) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getUser = (payload) => {
	////console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "users/" + payload.id

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

// Get a user authorizations report. Require, in the payload.data, a valid userId
export const getUserReport = (payload) => {
	// Track the response status.
	let responseStatus

	// responseCode is used here to store the request response status code.
	// The reason for this is that error handling doesn’t come right after receptionning the request, and res then isn’t the response data, but the file.
	// Using a variable that differ from responseStatus allows me to send correct data to handleError
	let responseCode
	let url = `${localStorage.getItem("apiUrl")}user/report/pdf?id=${
		payload.data.userId
	}`

	// Make the request
	return (
		fetch(url, {
			method: "GET",
			headers: headers(payload),
		})
			// In case we have a network error, trigger a toast message
			.catch(() => {
				toastr.error(
					I18n.t("forServices.error"),
					I18n.t("forServices.netIssue"),
				)
			})
			// If the request succeeded, see if the backend returned an error.
			.then((res) => {
				responseStatus = res?.ok
				responseCode = res.status
				return res
			})
			// Convert the response into a binary file.
			.then((res) => res.blob())
			// If the server responded with an error, display a toast notification; else, return the previous returned value, aka the binary file.
			.then((res) =>
				!responseStatus
					? handleError({ status: responseCode, msg: "userNotFound" })
					: res,
			)
	)
}

export const getSortUser = (payload) => {
	let responseStatus
	let sortType = localStorage.getItem("sortType")
	let sortBy = localStorage.getItem("sortBy")

	const queryData = { sortType, sortBy, limit: payload.data.limit }

	let url =
		localStorage.getItem("apiUrl") +
		"users/" +
		payload.data.orgId +
		"/organization" +
		formQuery(queryData)

	// Make the request
	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const postApiUser = (payload) => {
	////console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "api-users"

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const postUser = (payload) => {
	////console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "users"

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const putApiUser = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "api-users/" + payload.id

	return fetch(url, {
		method: "PUT",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const putUser = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "users/" + payload.id

	return fetch(url, {
		method: "PUT",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const deactivateUser = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "users/deactivate/" + payload.id

	return fetch(url, {
		method: "PUT",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const reActivateUser = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "users/activate/" + payload.id

	return fetch(url, {
		method: "PUT",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const putUserModerator = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "users/moderators/" + payload.id

	return fetch(url, {
		method: "PUT",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const putUserAdmin = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "users/admin/" + payload.id

	return fetch(url, {
		method: "PUT",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const deleteUsers = (payload) => {
	let responseStatus

	let uri = ""

	if (payload.apiUser) uri = "api-users"
	else uri = "users"

	let url = localStorage.getItem("apiUrl") + uri + "?ids=" + payload.ids

	return fetch(url, {
		method: "DELETE",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const resendActivUser = (payload) => {
	////console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "users/validate"

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const setSsoUserPassword = (payload) => {
	let responseStatus
	let url =
		localStorage.getItem("apiUrl") + `users/password/sso/${payload.userId}`

	return fetch(url, {
		method: "PUT",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.erroor"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res))
}

export const removeSsoUserPassword = (payload) => {
	let responseStatus
	let url =
		localStorage.getItem("apiUrl") + `users/password/sso/${payload.userId}`

	return fetch(url, {
		method: "PATCH",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.erroor"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res))
}

export const removeMfaByAdmin = (payload) => {
	let responseStatus
	let url = `${localStorage.getItem("apiUrl")}management/otp?userId=${
		payload.userId
	}`

	return (
		fetch(url, {
			method: "DELETE",
			headers: headers(payload),
		})
			.catch((e) => {
				toastr.error(
					I18n.t("forServices.error"),
					I18n.t("forServices.netIssue"),
				)
				throw e
			})
			.then((res) => {
				responseStatus = res?.ok
				return res
			})
			.then((res) => {
				try {
					return res.json()
				} catch (error) {
					return res
				}
			})
			// We're getting in the catch if there's nothing in the response, which happens when the operation was a success, so yes, the catch is used to manage the success case...
			.catch((error) => error)
			.then((res) => (!responseStatus ? handleError(res) : res))
	) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}
