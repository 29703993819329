import { call, put, takeLeading, select } from "redux-saga/effects"
import * as Api from "./services"
import { toastr } from "react-redux-toastr"
import { addLCS } from "../actions/generalUtils"
import I18n from "../i18n"
import moment from "moment"

export function* getLicenseInfo(action) {
	try {
		addLCS(action)

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT }

		const ids = yield call(Api.getLicenseIds, payload)

		switch (ids) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			//case (true) : return removeLCS(action)
			default: {
				//console.log(ids)
				//removeLCS(action)

				yield put({ type: "license/setId", payload: ids.licenseId })
				yield put({ type: "license/setEmail", payload: ids.email })

				const info = yield call(Api.getLicenseInfo, {
					license: ids.licenseId,
				})

				//let us = yield select(state => state.auth.token.userSession)
				//if (moment(us.licenseExpirationDate).diff(moment(), "days") <= 0) yield put({ type: "action/openAction", payload: "licenseWarn" })

				if (info.resCode === 404)
					return yield put({ type: "license/setInfo" })
				else
					return yield put({ type: "license/setInfo", payload: info })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* activateNew(action) {
	try {
		yield put({ type: "actions/setLAR" })
		addLCS(action)

		const data = yield call(Api.activateNew, action.payload)

		if (data.resCode) {
			yield put({
				type: "action/setLAR",
				payload: { message: "licUpdateIssue" },
			})

			if (data.resCode === 405)
				return toastr.warning(I18n.t("licenses.toasts.notFound"))
			else {
				if (data.res.title)
					return toastr.warning(data.res.title, data.res.detail)
				else if (data.res["hydra:title"])
					return toastr.warning(
						data.res["hydra:title"],
						data.res["hydra:description"],
					)
			}
		} else {
			if (data.isValidated) {
				yield put({
					type: "action/setLAR",
					payload: { message: "licUpdateIssue" },
				})
				return toastr.warning(
					I18n.t("licenses.toasts.alreadyActivated"),
				)
			} else {
				// Everything is OK : we start poc renewal
				let xLsT = yield select((state) => state.auth.xLsToken)
				let token = yield select((state) => state?.auth?.token?.token)
				let payload = {
					token,
					xLsT,
					data: { ...action.payload, ...data },
				}

				payload.isValidated = false

				const ret = yield call(Api.patchRenew, payload)

				if (ret === "renewed") {
					if (data.resCode) {
						yield put({
							type: "action/setLAR",
							payload: { message: "licUpdateIssue" },
						})
						return toastr.warning(
							I18n.t("licenses.toasts.patchIssue"),
						)
					} else {
						const apiRenewData = yield call(
							Api.renewLicense,
							payload,
						)

						if (apiRenewData === "renewed") {
							let isNewLicense = yield select(
								(state) =>
									state?.auth?.token?.userSession
										?.isNewLicense,
							)

							if (isNewLicense) {
								if (data.resCode) {
									yield put({
										type: "action/setLAR",
										payload: { message: "licUpdateIssue" },
									})
									return toastr.warning(
										I18n.t("licenses.toasts.patchIssue"),
									)
								} else {
									toastr.success(
										I18n.t("licenses.toasts.activated"),
									)
									yield put({ type: "LICENSE_GET_INFO_SAGA" })
									return yield put({
										type: "action/setLAR",
										payload: { message: "licUpdated" },
									})
								}
							} else {
								toastr.success(
									I18n.t("licenses.toasts.activated"),
								)
								yield put({ type: "LICENSE_GET_INFO_SAGA" })
								return yield put({
									type: "action/setLAR",
									payload: { message: "licUpdated" },
								})
							}
						} else {
							yield deactivateLicense(payload)
						}
					}
				} else {
					yield deactivateLicense(payload)
				}
			}
		}
	} catch (error) {
		// console.log("error in activateNew saga", error)
	}
}

export function* checkLicense() {
	try {
		let expi = yield select(
			(state) => state.auth.token.userSession.licenseExpirationDate,
		)
		let uS = yield select((state) => state.auth.token.userSession)
		let uF = yield select(
			(state) => state.auth.token.userSession.userFormulas,
		)

		let sawLastWarn = localStorage.getItem("sawLastWarn")
		let sawFirstWarn = localStorage.getItem("sawFirstWarn")
		sawFirstWarn = undefined

		let untilExpi = moment(expi).add(1, "day").diff(moment(), "hours")
		let isAccredit =
			uS.isSuperAdmin ||
			uF.id === 1 ||
			uF.id === 3 ||
			uF.id === 5 ||
			uF.id === 9 ||
			uF.id === 11 ||
			uF.id === 13 ||
			uF.id === 15

		// Popups conditions
		if (untilExpi < 24) {
			if (!sawLastWarn) localStorage.setItem("sawLastWarn", true)
			return yield put({
				type: "action/openAction",
				payload: "licenseWarn",
			})
		} else if (uS.connexionNumber === 1) {
			return yield put({
				type: "action/openAction",
				payload: "licenseWarn",
			})
		} else if (untilExpi <= 169) {
			if (!sawFirstWarn && isAccredit) {
				localStorage.setItem("sawFirstWarn", true)
				return yield put({
					type: "action/openAction",
					payload: "licenseWarn",
				})
			} else return
		} else if (untilExpi <= 336) {
			if (!sawFirstWarn && isAccredit && !uS.isPocLicense) {
				localStorage.setItem("sawFirstWarn", true)
				return yield put({
					type: "action/openAction",
					payload: "licenseWarn",
				})
			} else return
		} else {
			localStorage.removeItem("sawFirstWarn")
			localStorage.removeItem("sawLastWarn")
			return
		}
	} catch (e) {
		//
	}
}

export function* getIndicators(action) {
	try {
		let data
		if (action.payload?.getFromLocalS) {
			data = JSON.parse(localStorage.getItem("licenseSlots"))
		} else {
			let payload
			let xLsT = yield select((state) => state.auth.xLsToken)
			let token = yield select((state) => state.auth.token.token)

			if (action?.payload?.token) payload = action.payload
			else payload = { token, xLsT }

			data = yield call(Api.getSlots, payload)
		}
		//console.log(data)
		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			default: {
				return yield put({ type: "license/setSlots", payload: data })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

function* deactivateLicense(payload) {
	try {
		const deactivateLicenseFetch = yield call(Api.patchDeactivate, {
			...payload,
			isValidated: false,
		})

		switch (deactivateLicenseFetch) {
			case "sessionExpired":
				toastr.error(I18n.t("licenses.view.contactSupport"))
				return yield put({
					type: "auth/showPadlock",
				})

			case "sessionDestroyed":
				toastr.error(I18n.t("licenses.view.contactSupport"))
				return yield put({
					type: "auth/sessionExpired",
				})

			case true: {
				toastr.error(I18n.t("licenses.view.contactSupport"))
				return yield put({
					type: "license/setSlots",
					payload: data,
				})
			}
		}

		return yield put({
			type: "action/setLAR",
			payload: { message: "licUpdateIssue" },
		})
	} catch (e) {
		//console.warn("error during license deactivation", e)
	}
}

export default function* generalSagas() {
	yield takeLeading("LICENSE_GET_INFO_SAGA", getLicenseInfo)
	yield takeLeading("LICENSE_ACTIVATE_NEW_SAGA", activateNew)
	yield takeLeading("LICENSE_CHECK_LICENSE_SAGA", checkLicense)
	yield takeLeading("LICENSE_GET_INDICATORS_SAGA", getIndicators)
}
