import { call, put, takeLatest, select } from "redux-saga/effects"

import * as Api from "./services"
import {
	addLCS,
	removeLCS,
	decodeBase64,
} from "../../../../actions/generalUtils"
import moment from "moment/moment"

// strength
export function* getPasswordStrength(action) {
	try {
		addLCS(action)

		let dataLocalStorage = JSON.parse(localStorage.getItem("dashboardLP"))
		let today = new Date()
		today.setHours(4)
		today.setMinutes(0)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		const payload = { token, xLsT, organizationId }
		let data
		let isLocal

		if (
			!dataLocalStorage ||
			!dataLocalStorage.passwordStrenght ||
			dataLocalStorage.callDate === null ||
			new Date(dataLocalStorage.callDate) < today
		) {
			isLocal = false
			data = yield call(Api.getPasswordStrength, payload)
		} else {
			isLocal = true
			data = dataLocalStorage.passwordStrenght
		}

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardLP/toggleLoading" })

				const callDate = new Date()

				if (!isLocal) {
					const payload = {
						callDate,
						key: "passwordStrenght",
						value: data,
					}

					yield put({ type: "dashboardLP/setLoader", payload })
				} else {
					yield put({
						type: "dashboardLP/setLoader",
						payload: { dataLocalStorage, isLocal: true },
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

export function* getPasswordStrengthList(action) {
	try {
		addLCS(action)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		const fullList = yield select((state) => state.dashboardLP.fullList)

		const metric = action.payload.metric
		const result = action.payload.result ? action.payload.result : 5
		const page = action.payload.page ? action.payload.page : 1
		const credentialFilter = action.payload.credentialFilter ? 1 : 0

		if (page > 1) {
			yield put({ type: "dashboardLP/paginLoading", payload: true })
		} else {
			yield put({ type: "dashboardLP/toggleLoading", payload: true })
		}

		yield put({ type: "dashboardLP/toggleLoading", payload: true })

		const payload = {
			token,
			xLsT,
			organizationId,
			metric,
			result,
			page,
			credentialFilter,
		}
		const data = yield call(Api.getPasswordStrengthList, payload)

		yield put({ type: "dashboardLP/paginLoading" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardLP/toggleLoading" })

				if (action.payload.fullList) {
					if (data.length === 0 || data.length < 19) {
						yield put({
							type: "dashboardLP/setPagingLimit",
							payload: false,
						})
					} else {
						yield put({
							type: "dashboardLP/setPagingLimit",
							payload: true,
						})
					}

					yield put({
						type: "dashboardLP/setFullList",
						payload: [...fullList, ...data],
					})
				} else if (action.payload.popup) {
					yield put({
						type: "dashboardLP/setFullList",
						payload: data,
					})
				} else {
					yield put({
						type: "dashboardLP/setShowList",
						payload: data,
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

// seniority frequency of use
export function* getCredential(action) {
	try {
		addLCS(action)

		let dataLocalStorage = JSON.parse(localStorage.getItem("dashboardLP"))
		let today = new Date()
		today.setHours(4)
		today.setMinutes(0)

		yield put({ type: "dashboardLP/setLAR" })

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		const payload = { token, xLsT, organizationId }
		let data
		let isLocal

		if (
			!dataLocalStorage ||
			!dataLocalStorage.credential ||
			dataLocalStorage.callDate === null ||
			new Date(dataLocalStorage.callDate) < today
		) {
			isLocal = false
			data = yield call(Api.getCredential, payload)
		} else {
			isLocal = true
			data = dataLocalStorage.credential
		}

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardLP/toggleLoading" })

				const callDate = new Date()

				if (!isLocal) {
					const payload = {
						callDate,
						key: "credential",
						value: data,
					}

					yield put({ type: "dashboardLP/setLoader", payload })
				} else {
					yield put({
						type: "dashboardLP/setLoader",
						payload: { dataLocalStorage, isLocal: true },
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

export function* getCredentialSeniorityList(action) {
	try {
		addLCS(action)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		const fullList = yield select((state) => state.dashboardLP.fullList)

		const metric = action.payload.metric
		const result = action.payload.result ? action.payload.result : 5
		const page = action.payload.page ? action.payload.page : 1
		const credentialFilter = action.payload.credentialFilter ? 1 : 0

		if (page > 1) {
			yield put({ type: "dashboardLP/paginLoading", payload: true })
		} else {
			yield put({ type: "dashboardLP/toggleLoading", payload: true })
		}

		yield put({ type: "dashboardLP/toggleLoading", payload: true })

		const payload = {
			token,
			xLsT,
			organizationId,
			metric,
			result,
			page,
			credentialFilter,
		}
		const data = yield call(Api.getPasswordSeniorityList, payload)

		yield put({ type: "dashboardLP/paginLoading" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardLP/toggleLoading" })

				if (action.payload.fullList) {
					if (data.length === 0 || data.length < 19) {
						yield put({
							type: "dashboardLP/setPagingLimit",
							payload: false,
						})
					} else {
						yield put({
							type: "dashboardLP/setPagingLimit",
							payload: true,
						})
					}

					yield put({
						type: "dashboardLP/setFullList",
						payload: [...fullList, ...data],
					})
				} else if (action.payload.popup) {
					yield put({
						type: "dashboardLP/setFullList",
						payload: data,
					})
				} else {
					yield put({
						type: "dashboardLP/setShowList",
						payload: data,
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

export function* getCrendentialUseList(action) {
	try {
		addLCS(action)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		const fullList = yield select((state) => state.dashboardLP.fullList)

		const metric = action.payload.metric
		const result = action.payload.result ? action.payload.result : 5
		const page = action.payload.page ? action.payload.page : 1
		const credentialFilter = action.payload.credentialFilter ? 1 : 0

		if (page > 1) {
			yield put({ type: "dashboardLP/paginLoading", payload: true })
		} else {
			yield put({ type: "dashboardLP/toggleLoading", payload: true })
		}

		yield put({ type: "dashboardLP/toggleLoading", payload: true })

		const payload = {
			token,
			xLsT,
			organizationId,
			metric,
			result,
			page,
			credentialFilter,
		}
		const data = yield call(Api.getPasswordUseList, payload)

		yield put({ type: "dashboardLP/paginLoading" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardLP/toggleLoading" })

				if (action.payload.fullList) {
					if (data.length === 0 || data.length < 19) {
						yield put({
							type: "dashboardLP/setPagingLimit",
							payload: false,
						})
					} else {
						yield put({
							type: "dashboardLP/setPagingLimit",
							payload: true,
						})
					}

					yield put({
						type: "dashboardLP/setFullList",
						payload: [...fullList, ...data],
					})
				} else if (action.payload.popup) {
					yield put({
						type: "dashboardLP/setFullList",
						payload: data,
					})
				} else {
					yield put({
						type: "dashboardLP/setShowList",
						payload: data,
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

//compartmentalization
export function* getCategoryCompartimentation(action) {
	try {
		addLCS(action)

		let dataLocalStorage = JSON.parse(localStorage.getItem("dashboardLP"))
		let today = new Date()
		today.setHours(4)
		today.setMinutes(0)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]
		const payload = { token, xLsT, organizationId }

		let data
		let isLocal

		if (
			!dataLocalStorage ||
			!dataLocalStorage.categoryCompartimentation ||
			dataLocalStorage.callDate === null ||
			new Date(dataLocalStorage.callDate) < today
		) {
			isLocal = false
			data = yield call(Api.getCategoryCompartimentation, payload)
		} else {
			isLocal = true
			data = dataLocalStorage.categoryCompartimentation
		}

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardLP/toggleLoading" })

				const callDate = new Date()

				if (!isLocal) {
					const payload = {
						callDate,
						key: "categoryCompartimentation",
						value: data,
					}

					yield put({ type: "dashboardLP/setLoader", payload })
				} else {
					yield put({
						type: "dashboardLP/setLoader",
						payload: { dataLocalStorage, isLocal: true },
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

export function* getCategoryCompartimentationList(action) {
	try {
		addLCS(action)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		const fullList = yield select((state) => state.dashboardLP.fullList)

		const metric = action.payload.metric
		const result = action.payload.result ? action.payload.result : 5
		const page = action.payload.page ? action.payload.page : 1
		const credentialFilter = action.payload.credentialFilter ? 1 : 0

		if (page > 1) {
			yield put({ type: "dashboardLP/paginLoading", payload: true })
		} else {
			yield put({ type: "dashboardLP/toggleLoading", payload: true })
		}

		yield put({ type: "dashboardLP/toggleLoading", payload: true })

		const payload = {
			token,
			xLsT,
			organizationId,
			metric,
			result,
			page,
			credentialFilter,
		}
		const data = yield call(Api.getCategoryCompartimentationList, payload)

		yield put({ type: "dashboardLP/paginLoading" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardLP/toggleLoading" })

				if (action.payload.fullList) {
					if (data.length === 0 || data.length < 19) {
						yield put({
							type: "dashboardLP/setPagingLimit",
							payload: false,
						})
					} else {
						yield put({
							type: "dashboardLP/setPagingLimit",
							payload: true,
						})
					}

					yield put({
						type: "dashboardLP/setFullList",
						payload: [...fullList, ...data],
					})
				} else if (action.payload.popup) {
					yield put({
						type: "dashboardLP/setFullList",
						payload: data,
					})
				} else {
					yield put({
						type: "dashboardLP/setShowList",
						payload: data,
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

export function* getPasswordPolicy(action) {
	try {
		addLCS(action)

		let dataLocalStorage = JSON.parse(localStorage.getItem("dashboardLP"))
		let today = new Date()
		today.setHours(4)
		today.setMinutes(0)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]
		const payload = { token, xLsT, organizationId }

		let data
		let isLocal

		if (
			!dataLocalStorage ||
			!dataLocalStorage.passwordPolicy ||
			dataLocalStorage.callDate === null ||
			new Date(dataLocalStorage.callDate) < today
		) {
			isLocal = false
			data = yield call(Api.getPasswordPolicy, payload)
		} else {
			isLocal = true
			data = dataLocalStorage.passwordPolicy
		}

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardLP/toggleLoading" })

				const callDate = new Date()

				if (!isLocal) {
					const payload = {
						callDate,
						key: "passwordPolicy",
						value: data,
					}

					yield put({ type: "dashboardLP/setLoader", payload })
				} else {
					yield put({
						type: "dashboardLP/setLoader",
						payload: { dataLocalStorage, isLocal: true },
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

export function* getPasswordPolicyList(action) {
	try {
		addLCS(action)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		const fullList = yield select((state) => state.dashboardLP.fullList)

		const metric = action.payload.metric
		const result = action.payload.result ? action.payload.result : 5
		const page = action.payload.page ? action.payload.page : 1
		const credentialFilter = action.payload.credentialFilter ? 1 : 0

		if (page > 1) {
			yield put({ type: "dashboardLP/paginLoading", payload: true })
		} else {
			yield put({ type: "dashboardLP/toggleLoading", payload: true })
		}

		yield put({ type: "dashboardLP/toggleLoading", payload: true })

		const payload = {
			token,
			xLsT,
			organizationId,
			metric,
			result,
			page,
			credentialFilter,
		}
		const data = yield call(Api.getPasswordPolicyList, payload)

		yield put({ type: "dashboardLP/paginLoading" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardLP/toggleLoading" })

				if (action.payload.fullList) {
					if (data.length === 0 || data.length < 19) {
						yield put({
							type: "dashboardLP/setPagingLimit",
							payload: false,
						})
					} else {
						yield put({
							type: "dashboardLP/setPagingLimit",
							payload: true,
						})
					}

					yield put({
						type: "dashboardLP/setFullList",
						payload: [...fullList, ...data],
					})
				} else if (action.payload.popup) {
					yield put({
						type: "dashboardLP/setFullList",
						payload: data,
					})
				} else {
					yield put({
						type: "dashboardLP/setShowList",
						payload: data,
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

export function* getLowStrengthPassword(action) {
	try {
		addLCS(action)

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT }

		yield put({ type: "dashboardLP/setActionLoading", payload: true })

		const lines = []
		let isComplete = false
		let offset = 1

		while (!isComplete) {
			const data = yield call(Api.exportLowStrength, {
				...payload,
				offset,
			})

			switch (data) {
				case "sessionExpired":
					return yield put({ type: "auth/showPadlock" })
				case "sessionDestroyed":
					return yield put({ type: "auth/sessionExpired" })
				case true:
					return removeLCS(action)
				default: {
					removeLCS(action)

					offset += 1

					const totalLinesInData = data
						?.split("------start---")?.[0]
						?.split(":")?.[1]

					data?.split("------start---")?.[1]
						?.split("---end---")?.[0]
						?.split(/[0-9]*\s+------/)
						?.forEach((line) => {
							if (!!line) {
								lines.push(line.slice(1, -1))
							}
						})

					if (
						Number(totalLinesInData) < 2500 ||
						!totalLinesInData ||
						!Number(totalLinesInData)
					) {
						isComplete = true
					}
				}
			}
		}

		const result = []

		for (let i = 0; i < lines.length; i++) {
			const line = lines[i + 1]

			if (!!line) {
				const cleanedLine = line.trim()
				let contentParts = cleanedLine.split(";")

				try {
					contentParts = decodeBase64(contentParts)
				} catch (err) {
					console.error(err)
				}

				let content = contentParts.join(";")

				result.push(content)
			}
		}

		const header = ["Name", "Path", "Login"]
		const csv = result.map((row) => {
			const contentArray = row.split(";")

			return contentArray
				.map((item) => {
					return item
				})
				.join(";")
		})

		csv.unshift(header.join(";"))

		const csvContent = csv.join("\n")

		const blob = new Blob([csvContent], {
			type: "text/csv;charset=utf-8;",
		})
		const url = URL.createObjectURL(blob)
		const link = document.createElement("a")

		link.href = url
		link.setAttribute(
			"download",
			`LockselfLowStrengthPassword-${moment().format("HH-mm-ss")}.csv`,
		)

		document.body.appendChild(link)

		link.click()

		document.body.removeChild(link)

		yield put({ type: "dashboardLP/setActionLoading" })
	} catch (error) {
		console.error(error)
	}
}

export default function* dashboardLPSagas() {
	yield takeLatest("DBLP_GET_PASSWORD_STRENGTH_SAGA", getPasswordStrength)
	yield takeLatest(
		"DBLP_GET_PASSWORD_STRENGTH_LIST_SAGA",
		getPasswordStrengthList,
	)

	yield takeLatest("DBLP_GET_CREDENTIAL_SAGA", getCredential)
	yield takeLatest(
		"DBLP_GET_CREDENTIAL_SENIORITY_LIST_SAGA",
		getCredentialSeniorityList,
	)
	yield takeLatest("DBLP_GET_CREDENTIAL_USE_LIST_SAGA", getCrendentialUseList)

	yield takeLatest(
		"DBLP_GET_CATEGORY_COMPARTIMENTATION_SAGA",
		getCategoryCompartimentation,
	)
	yield takeLatest(
		"DBLP_GET_CATEGORY_COMPARTIMENTATION_LIST_SAGA",
		getCategoryCompartimentationList,
	)

	yield takeLatest("DBLP_GET_PASSWORD_POLICY_SAGA", getPasswordPolicy)
	yield takeLatest(
		"DBLP_GET_PASSWORD_POLICY_LIST_SAGA",
		getPasswordPolicyList,
	)
	yield takeLatest(
		"DBLP_EXPORT_LOW_STRENGTH_PASSWORDS",
		getLowStrengthPassword,
	)
}
