import { call, put, takeLatest, select, takeLeading } from "redux-saga/effects"

import * as Api from "./services"
import {
	addLCS,
	downloadFiles,
	removeLCS,
	decodeBase64,
} from "../../../../actions/generalUtils"

import I18n from "../../../../i18n"

/* -------------------------------------------------------------------------- */
/*                        DASHBOARD MANAGEMENT LICENCE                        */
/* -------------------------------------------------------------------------- */
export function* getDashboardManagementLicence(action) {
	try {
		addLCS(action)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		const payload = { token, xLsT, organizationId }
		const data = yield call(Api.getDashboardManagementLicence, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardMNG/toggleLoading" })

				const payload = {
					key: "dashboardManagementLicence",
					value: data,
				}

				yield put({ type: "dashboardMNG/setLoader", payload })
			}
		}
	} catch (error) {
		console.error(error)
	}
}

/* -------------------------------------------------------------------------- */
/*                      DASHBOARD MANAGEMENT LICENCE LIST                     */
/* -------------------------------------------------------------------------- */
export function* getDashboardManagementLicenceList(action) {
	try {
		addLCS(action)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const fullList = yield select((state) => state.dashboardMNG.fullList)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]
		const result = action.payload.result ? action.payload.result : 5
		const page = action.payload.page ? action.payload.page : 1
		const filter = action.payload.filter ? action.payload.filter : "new"

		let dataLocalStorage = JSON.parse(localStorage.getItem("dashboardMNG"))
		let today = new Date()
		today.setHours(4)
		today.setMinutes(0)

		if (page > 1) {
			yield put({ type: "dashboardMNG/paginLoading", payload: true })
		} else {
			yield put({ type: "dashboardMNG/toggleLoading", payload: true })
		}
		yield put({ type: "dashboardMNG/toggleLoading", payload: true })

		const payload = { token, xLsT, organizationId, result, page, filter }

		if (
			action.payload.status &&
			["active", "pending"].includes(action.payload.status)
		) {
			payload.status = action.payload.status === "active" ? "1" : "0"
		}

		const apiResult = yield call(
			Api.getDashboardManagementLicenceList,
			payload,
		)
		let data

		if (
			new Date(dataLocalStorage.callDate) < today ||
			filter === "inactive"
		) {
			data = apiResult
		} else {
			data = apiResult.filter((items) => {
				if (new Date(items.creationDate * 1000) < today) {
					return items
				} else return
			})
		}

		yield put({ type: "dashboardMNG/paginLoading" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardMNG/toggleLoading" })

				if (action.payload.fullList) {
					if (data.length === 0 || data.length < 19) {
						yield put({
							type: "dashboardMNG/setPagingLimit",
							payload: false,
						})
					} else {
						yield put({
							type: "dashboardMNG/setPagingLimit",
							payload: true,
						})
					}

					yield put({
						type: "dashboardMNG/setFullList",
						payload: page === 1 ? data : [...fullList, ...data],
					})
				} else if (action.payload.popup) {
					yield put({
						type: "dashboardMNG/setFullList",
						payload: data,
					})
				} else {
					yield put({
						type: "dashboardMNG/setShowList",
						payload: data,
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

/* -------------------------------------------------------------------------- */
/*                          DASHBOARD GROUPS GENERAL                          */
/* -------------------------------------------------------------------------- */
export function* getDashboardGroupsGeneral(action) {
	try {
		addLCS(action)

		let dataLocalStorage = JSON.parse(localStorage.getItem("dashboardMNG"))
		let today = new Date()
		today.setHours(4)
		today.setMinutes(0)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		const payload = { token, xLsT, organizationId }
		let data
		let isLocal

		if (
			!dataLocalStorage ||
			!dataLocalStorage.dashboardGroupsGeneral ||
			dataLocalStorage.callDate === null ||
			new Date(dataLocalStorage.callDate) < today
		) {
			isLocal = false
			data = yield call(Api.getDashboardGroupsGeneral, payload)
		} else {
			isLocal = true
			data = dataLocalStorage.dashboardGroupsGeneral
		}

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				if (data.length === 0) {
					yield put({
						type: "accountOptions/setEmptyCron",
						payload: true,
					})
				} else {
					yield put({
						type: "accountOptions/setEmptyCron",
						payload: false,
					})
				}

				yield put({ type: "dashboardMNG/toggleLoading" })

				const callDate = new Date()

				if (!isLocal) {
					const payload = {
						callDate,
						key: "dashboardGroupsGeneral",
						value: data,
					}

					yield put({ type: "dashboardMNG/setLoader", payload })
				} else {
					yield put({
						type: "dashboardMNG/setLoader",
						payload: { dataLocalStorage, isLocal: true },
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

/* -------------------------------------------------------------------------- */
/*                              DASHBOARD GROUPS                              */
/* -------------------------------------------------------------------------- */
export function* getDashboardGroups(action) {
	try {
		addLCS(action)

		let dataLocalStorage = JSON.parse(localStorage.getItem("dashboardMNG"))
		let today = new Date()
		today.setHours(4)
		today.setMinutes(0)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		const payload = { token, xLsT, organizationId }
		let data
		let isLocal

		if (
			!dataLocalStorage ||
			!dataLocalStorage.dashboardGroups ||
			dataLocalStorage.callDate === null ||
			new Date(dataLocalStorage.callDate) < today
		) {
			isLocal = false
			data = yield call(Api.getDashboardGroups, payload)
		} else {
			isLocal = true
			data = dataLocalStorage.dashboardGroups
		}

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardMNG/toggleLoading" })

				const callDate = new Date()

				if (!isLocal) {
					const payload = {
						callDate,
						key: "dashboardGroups",
						value: data,
					}

					yield put({ type: "dashboardMNG/setLoader", payload })
				} else {
					yield put({
						type: "dashboardMNG/setLoader",
						payload: { dataLocalStorage, isLocal: true },
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

export function* getOrganisationData(action) {
	try {
		addLCS(action)

		let dataLocalStorage = JSON.parse(localStorage.getItem("dashboardMNG"))
		let today = new Date()
		today.setHours(4)
		today.setMinutes(0)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId = action.payload.subOrgaId
			? action.payload.subOrgaId
			: auth.token.userSession.organizationIds[auth.selectedOrg]

		const saveLocalStorage = action.payload.saveLocalStorage

		// const subOrgaList = yield select(state => state.dashboardMNG.subOrgaList)

		const payload = { token, xLsT, organizationId }
		let data
		let isLocal

		if (saveLocalStorage) {
			if (
				!dataLocalStorage ||
				!dataLocalStorage.organisationData ||
				dataLocalStorage.callDate === null ||
				new Date(dataLocalStorage.callDate) < today
			) {
				isLocal = false
				data = yield call(Api.getOrganisationData, payload)
			} else {
				isLocal = true
				data = dataLocalStorage.organisationData
			}
		} else {
			data = yield call(Api.getOrganisationData, payload)
		}

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardMNG/toggleLoading" })

				if (saveLocalStorage) {
					const callDate = new Date()

					if (!isLocal) {
						const payload = {
							callDate,
							key: "organisationData",
							value: data,
						}

						yield put({ type: "dashboardMNG/setLoader", payload })
					} else {
						yield put({
							type: "dashboardMNG/setLoader",
							payload: { dataLocalStorage, isLocal: true },
						})
					}
				} else {
					yield put({
						type: "dashboardMNG/setSubOrgaList",
						payload: data,
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

export function* getDistributionRolesData(action) {
	try {
		addLCS(action)

		let dataLocalStorage = JSON.parse(localStorage.getItem("dashboardMNG"))
		let today = new Date()
		today.setHours(4)
		today.setMinutes(0)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId = action.payload.subOrgaId
			? action.payload.subOrgaId
			: auth.token.userSession.organizationIds[auth.selectedOrg]

		const saveLocalStorage = action.payload.saveLocalStorage

		const payload = { token, xLsT, organizationId }
		let data
		let isLocal

		if (saveLocalStorage) {
			if (
				!dataLocalStorage ||
				!dataLocalStorage.distributionRolesData ||
				dataLocalStorage.callDate === null ||
				new Date(dataLocalStorage.callDate) < today
			) {
				isLocal = false
				data = yield call(Api.getDistributionRolesData, payload)
			} else {
				isLocal = true
				data = dataLocalStorage.distributionRolesData
			}
		} else {
			data = yield call(Api.getDistributionRolesData, payload)
		}

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardMNG/toggleLoading" })

				if (saveLocalStorage) {
					const callDate = new Date()

					if (!isLocal) {
						const payload = {
							callDate,
							key: "distributionRolesData",
							value: data,
						}

						yield put({ type: "dashboardMNG/setLoader", payload })
					} else {
						yield put({
							type: "dashboardMNG/setLoader",
							payload: { dataLocalStorage, isLocal: true },
						})
					}
				} else {
					yield put({
						type: "dashboardMNG/setStatsManagersList",
						payload: data,
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

export function* getManagersData(action) {
	try {
		addLCS(action)

		let dataLocalStorage = JSON.parse(localStorage.getItem("dashboardMNG"))
		let today = new Date()
		today.setHours(4)
		today.setMinutes(0)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId = action.payload.subOrgaId
			? action.payload.subOrgaId
			: auth.token.userSession.organizationIds[auth.selectedOrg]

		const saveLocalStorage = action.payload.saveLocalStorage

		const payload = { token, xLsT, organizationId }
		let data
		let isLocal

		if (saveLocalStorage) {
			if (
				!dataLocalStorage ||
				!dataLocalStorage.managersData ||
				dataLocalStorage.callDate === null ||
				new Date(dataLocalStorage.callDate) < today
			) {
				isLocal = false
				data = yield call(Api.getManagersData, payload)
			} else {
				isLocal = true
				data = dataLocalStorage.managersData
			}
		} else {
			data = yield call(Api.getManagersData, payload)
		}

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardMNG/toggleLoading" })

				if (saveLocalStorage) {
					const callDate = new Date()

					if (!isLocal) {
						const payload = {
							callDate,
							key: "managersData",
							value: data,
						}

						yield put({ type: "dashboardMNG/setLoader", payload })
					} else {
						yield put({
							type: "dashboardMNG/setLoader",
							payload: { dataLocalStorage, isLocal: true },
						})
					}
				} else {
					yield put({
						type: "dashboardMNG/setManagersList",
						payload: data,
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

// Download the report of a given user.
export function* getUserReport(action) {
	try {
		// Typical saga setup : activate the loader, get the authentication data, setup the payload and make the request.
		yield put({ type: "dashboardMNG/setActionLoading", payload: true })
		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, data: { userId: action.payload.userId } }
		const data = yield call(Api.getUserReport, payload)

		// Disable the loader when the request has ended
		yield put({ type: "dashboardMNG/setActionLoading" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return
			default: {
				// When we have the data, set the name we want to give to our file and trigger the download process
				const fileName =
					I18n.t("manage.report") + "-" + action.payload.userName
				return downloadFiles(data, fileName)
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* exportInactifUsers(action) {
	try {
		addLCS(action)

		yield put({
			type: "dashboardMNG/setActionLoading",
			payload: true,
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, data: action.payload }

		const lines = []
		let isComplete = false
		let offset = 1

		while (!isComplete) {
			const data = yield call(Api.exportInactifUsers, {
				...payload,
				offset,
			})

			switch (data) {
				case "sessionExpired":
					return yield put({ type: "auth/showPadlock" })
				case "sessionDestroyed":
					return yield put({ type: "auth/sessionExpired" })
				case true:
					return removeLCS(action)
				default: {
					removeLCS(action)

					offset += 1

					const totalLinesInData = data
						?.split("------start---")?.[0]
						?.split(":")?.[1]

					data?.split("------start---")?.[1]
						?.split("---end---")?.[0]
						?.split(/[0-9]*\s+------/)
						?.forEach((line) => {
							if (!!line) {
								lines.push(line.slice(1, -1))
							}
						})

					if (
						Number(totalLinesInData) < 2500 ||
						!totalLinesInData ||
						!Number(totalLinesInData)
					) {
						isComplete = true
					}
				}
			}
		}

		const result = []

		for (let i = 0; i < lines.length; i++) {
			const line = lines[i + 1]

			if (!!line) {
				const cleanedLine = line.trim()
				let contentParts = cleanedLine.split(";")

				try {
					contentParts = decodeBase64(contentParts)
				} catch (err) {
					console.error(err)
				}

				let content = contentParts.join(";")

				result.push(content)
			}
		}

		const header = ["Mail", "Last name", "First name", "Status"]
		const csv = result.map((row) => {
			const contentArray = row.split(";")

			return contentArray
				.map((item) => {
					return item
				})
				.join(";")
		})

		csv.unshift(header.join(";"))

		const csvContent = csv.join("\n")

		const blob = new Blob([csvContent], {
			type: "text/csv;charset=utf-8;",
		})
		const url = URL.createObjectURL(blob)
		const link = document.createElement("a")

		link.href = url
		link.setAttribute("download", "export.csv")

		document.body.appendChild(link)

		link.click()

		document.body.removeChild(link)

		yield put({ type: "dashboardMNG/setActionLoading" })
	} catch (error) {
		// console.error(error)
	}
}

export default function* dashboardMNGSagas() {
	yield takeLatest(
		"DBMNG_GET_DASHBOARD_MANAGEMENT_LICENCE",
		getDashboardManagementLicence,
	)
	yield takeLatest(
		"DBMNG_GET_DASHBOARD_MANAGEMENT_LICENCE_LIST",
		getDashboardManagementLicenceList,
	)

	yield takeLatest("DBSP_GET_ORGANISATION_DATA_SAGA", getOrganisationData)
	yield takeLatest(
		"DBSP_GET_DISTRIBUTION_ROLE_DATA_SAGA",
		getDistributionRolesData,
	)
	yield takeLatest("DBSP_GET_MANAGERS_DATA_SAGA", getManagersData)

	yield takeLatest(
		"DBMNG_GET_DASHBOARD_GROUPS_GENERAL",
		getDashboardGroupsGeneral,
	)
	yield takeLatest("DBMNG_GET_DASHBOARD_GROUPS", getDashboardGroups)

	yield takeLeading("DBLMNG_GET_USER_REPORT", getUserReport)
	yield takeLeading("DBMNG_EXPORT_INACTIF_USERS", exportInactifUsers)
}
