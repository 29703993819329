import React from "react"
import PropTypes from "prop-types"

export default function LoaderBars(props) {
	if (props.bullet) {
		return (
			<span id="loader-1">
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
			</span>
		)
	}
	return (
		<div
			className={`spinner ${props.mini ? "mini" : ""} ${
				props.stop ? "stop" : ""
			} ${props.customClass ?? ""}`}
		>
			<div className="rect rect1" />
			<div className="rect rect2" />
			<div className="rect rect3" />
			<div className="rect rect4" />
			{!props.mini && <div className="rect rect5" />}
		</div>
	)
}

LoaderBars.propTypes = {
	mini: PropTypes.bool,
	stop: PropTypes.bool,
	bullet: PropTypes.bool,
	customClass: PropTypes.string,
}
