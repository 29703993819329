import React, { useMemo } from "react"
import PropTypes from "prop-types"

import CustomFrame from "../components/controllers/CustomFrame"
import Whitemark from "./tools/WhitemarkExt"
import ShowIds from "./ShowIds"

/*
	ShowIdsFrame
	This frame allow to load css header on main page load
	Also to control showIds iframe size style

	Props
	styleLink -> Link to extension intern stylesheet
	showIdsStyle -> Base style applied to iframe (position and size)
	inputsToComplete -> Detected inputs to use
	targetedInput -> Input targeted by user (by clicking on our shell on it)
	showIds -> Show frame or not
	toggleShowIds -> function to toggle showIds value
*/

const ShowIdsFrameMemo = React.memo(function ShowIdsMemo({
	styleLink,
	showIdsStyle,
	inputsToComplete,
	targetedInput,
	showIds,
	toggleShowIds,
	cbLoading,
}) {
	const prodEnv = useMemo(() => process.env.NODE_ENV === "production", [])

	// Build head for css link import
	const styleHeadMemo = useMemo(() => {
		return !styleLink ? null : (
			<head>
				<link rel="stylesheet" href={styleLink} />
				<style
					dangerouslySetInnerHTML={{
						__html: "body { overflow: hidden } showIdsFrame { padding: 0; width: 100% }",
					}}
				/>
			</head>
		)
	}, [styleLink])

	// Manually build iframe style with base and style and generated showIdsStyle prop
	const customFrameStyle = useMemo(
		() => ({
			top: "100%",
			position: "absolute",
			zIndex: 2147483642,
			borderWidth: 0,
			overflow: "hidden",
			opacity: 0,
			...showIdsStyle,
			maxWidth: showIds ? "450px" : 0,
			minWidth: showIds ? "280px" : 0,
			maxHeight: showIds ? "420px" : 0,
			minHeight: showIds
				? showIdsStyle?.useMinHeight === "true"
					? showIdsStyle.minHeight
					: "300px"
				: 0,
		}),
		[showIds, showIdsStyle],
	)

	// ShowIds memoïzed frame
	const showIdsMemo = useMemo(
		() => (
			<ShowIds
				targetedInput={targetedInput}
				showIds={showIds}
				toggleShowIds={toggleShowIds}
				inputsToComplete={inputsToComplete}
				cbLoading={cbLoading}
			/>
		),
		[targetedInput, showIds, toggleShowIds, inputsToComplete],
	)

	return !prodEnv ? (
		<div style={customFrameStyle}>{showIdsMemo}</div>
	) : (
		<CustomFrame
			title="LockSelf auto-complete bloc"
			style={customFrameStyle}
		>
			<Whitemark extMode>
				{styleHeadMemo}
				{showIdsMemo}
			</Whitemark>
		</CustomFrame>
	)
})

export default ShowIdsFrameMemo

ShowIdsFrameMemo.propTypes = {
	styleLink: PropTypes.string,
	showIdsStyle: PropTypes.object,
	inputsToComplete: PropTypes.arrayOf(PropTypes.node),
	targetedInput: PropTypes.node,
	showIds: PropTypes.bool,
	toggleShowIds: PropTypes.func,
	cbLoading: PropTypes.func,
}
