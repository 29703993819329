import { createSlice } from "@reduxjs/toolkit"
// import { decryptOption } from "../../actions/generalUtils"

let json = localStorage.getItem("options")
let options = JSON.parse(json)
// let decrypt
// if (json) {
// 	let option = JSON.parse(json)
// 	decrypt = decryptOption(option)
// 	console.log("decryptt", decrypt)
// }

const base = [
	{
		name: "params.mainT",
		static: true,
		noSelect: true,
		forceBoardHelp: true,
		repName: "mLayout.categories",
		fileName: "mLayout.files",
	},
]

const paramsSlice = createSlice({
	name: "params",
	initialState: {
		active: [],
		loading: false,
		lastActionResult: null,
		isModerator: null,
		importState: {
			errors: 0,
			createdPasswords: 0,
			createdCategories: 0,
			showCategoriesResult: false,
		},
		importStep: {
			step: 1,
		},
		exportLoader: {
			active: false,
			remainingTime: "",
		},
		optAdministration: options ? options.administrationOptions : null,
		optLockPass: options ? options.lockpassOptions : null,
		optLockTransfer: options ? options.locktransferOptions : null,
		optLockFiles: options ? options.lockfilesOptions : null,
		optManagement: options ? options.managementOptions : null,
	},
	reducers: {
		init: (state) => {
			state.data = base
			state.history = []
			state.active = {}
			state.searchMode = false
			state.searchVal = ""
			state.optAdministration = options
				? options.administrationOptions
				: null
			state.optLockPass = options ? options.lockpassOptions : null
			state.optLockTransfer = options ? options.locktransferOptions : null
			state.optLockFiles = options ? options.lockfilesOptions : null
			state.optManagement = options ? options.managementOptions : null
		},
		toggleLoading: (state, action) => {
			if (action.payload) state.loading = action.payload
			else state.loading = !state.loading
		},
		setLAR: (state, action) => {
			if (!action.payload) state.lastActionResult = null
			else state.lastActionResult = action.payload
		},
		launchPass: (state, action) => {
			if (action.payload.launchPass) state.launchPass = true
			else state.launchPass = null
		},
		setImportState: (state, action) => {
			state.importState = { ...state.importState, ...action.payload }
		},
		setIsModerator: (state, action) => {
			if (!action.payload) state.actionLoading = null
			else state.actionLoading = action.payload
		},
		setOptions: (state, action) => {
			if (!action.payload) state.actionLoading = null
			else {
				state.optAdministration = action.payload.administrationOptions
				state.optLockPass = action.payload.lockpassOptions
				state.optLockTransfer = action.payload.locktransferOptions
				state.optLockFiles = action.payload.lockfilesOptions
				state.optManagement = action.payload.managementOptions
			}
		},
		setAdmin: (state, action) => {
			if (!action.payload) state.actionLoading = null
			else state.optAdministration = action.payload
		},
		setLP: (state, action) => {
			if (!action.payload) state.actionLoading = null
			else state.optLockPass = action.payload
		},
		setLT: (state, action) => {
			if (!action.payload) state.actionLoading = null
			else state.optLockTransfer = action.payload
		},
		setLF: (state, action) => {
			if (!action.payload) state.actionLoading = null
			else state.optLockFiles = action.payload
		},
		setManagement: (state, action) => {
			if (!action.payload) state.actionLoading = null
			else state.optManagement = action.payload
		},
		setWhitelistDomaine: (state, action) => {
			if (action.payload.isAllOrganizations) {
				state.optManagement.globalWhitelistDomains.push(action.payload)
			} else {
				state.optManagement.organizationWhitelistDomains.push(
					action.payload,
				)
			}
		},
		setWhitelist: (state, action) => {
			if (action.list) {
				state.optManagement.globalWhitelistDomains = action.payload
			} else {
				state.optManagement.organizationWhitelistDomains =
					action.payload
			}
		},
		setWhitelistIp: (state, action) => {
			if (!action.payload) state.actionLoading = null
			else state.optAdministration.whitelistIPs = action.payload
		},
		setData: (state, action) => {
			state.active = action.payload
		},
		toggleIsPersonalSpaceBlocked: (state) => {
			state.optLockPass.isPersonalSpaceBlocked =
				!state.optLockPass.isPersonalSpaceBlocked
		},
		setPasswordPolicyId: (state, action) => {
			state.optLockPass.passwordPolicyId = action.payload
		},
		toggleIsPersonalPPForced: (state) => {
			state.optLockPass.isPersonalPPForced =
				!state.optLockPass.isPersonalPPForced
		},
		toggleIsPersonalWithoutPP: (state) => {
			state.optLockPass.isPersonalWithoutPP =
				!state.optLockPass.isPersonalWithoutPP
		},
		toggleIsPersonalWithPP: (state) => {
			state.optLockPass.isPersonalWithPP =
				!state.optLockPass.isPersonalWithPP
		},
		toggleIsSharedPPForced: (state) => {
			state.optLockPass.isSharedPPForced =
				!state.optLockPass.isSharedPPForced
		},
		toggleIsSharedWithoutPP: (state) => {
			state.optLockPass.isSharedWithoutPP =
				!state.optLockPass.isSharedWithoutPP
		},
		toggleIsSharedWithPP: (state) => {
			state.optLockPass.isSharedWithPP = !state.optLockPass.isSharedWithPP
		},
		toggleIsOtpMobileOnlyAccess: (state) => {
			state.optLockPass.isOtpMobileOnlyAccess =
				!state.optLockPass.isOtpMobileOnlyAccess
		},
		setImportStep: (state, action) => {
			state.importStep = action.payload
		},
	},
})

export const { init } = paramsSlice.actions

export default paramsSlice.reducer
