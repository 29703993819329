import { createSlice } from "@reduxjs/toolkit"
import moment from "moment"

const base = [
	{
		name: "logs.title",
		static: true,
		noSelect: true,
		forceBoardHelp: false,
		searchable: true,
		fileName: "log",
		loadData: "LOGS_GET_SAGA",
		files: [],
	},
]

const logsSlice = createSlice({
	name: "dashboardLogs",
	initialState: {
		loading: false,
		data: null,
		organizationId: null,
		listOrga: [],
		filters: [],
		searchMode: false,
		searchVal: "",
		active: {},
		searchResults: [],
		lastActionResult: null,
		actionLoading: false,
		uploadLoading: null,
		type: "",
		dateIn: moment().subtract(1, "month").format("YYYY-MM-DD"),
		dateOut: moment().add(1, "day").format("YYYY-MM-DD"),
		hourIn: `${moment().format("HH:mm")}`,
		hourOut: `${moment().format("HH:mm")}`,
		paginLoading: false,
		errorNotFound: false,
	},
	reducers: {
		init: (state, action) => {
			state.data = base
			state.history = []
			state.active = {}
			state.searchMode = false
			state.searchVal = ""
			state.organizationId = ""
			state.type = ""
			state.dateIn = moment().subtract(1, "month").format("YYYY-MM-DD")
			state.dateOut = moment().add(1, "day").format("YYYY-MM-DD")
			state.hourIn = "00:00"
			state.hourOut = `${moment().format("HH:mm")}`

			if (action.payload?.setLoading) state.loading = true
		},
		setErrorNotFound: (state, action) => {
			state.errorNotFound = action?.payload ? action?.payload : false
			state.callRunning = false
		},
		savePageSize: (state, action) => {
			state.pageSize = action.payload
		},
		setOrga: (state, action) => {
			state.organizationId = action.payload
		},
		setListOrga: (state, action) => {
			if (!action.payload) state.listOrga = null
			else state.listOrga = action.payload
		},
		toggleLoading: (state, action) => {
			if (!action.payload) state.loading = false
			else state.loading = action.payload
		},
		setLAR: (state, action) => {
			if (!action.payload) state.lastActionResult = null
			else state.lastActionResult = action.payload
		},
		setActionLoading: (state, action) => {
			if (!action.payload) state.actionLoading = null
			else state.actionLoading = action.payload
		},
		paginLoading: (state, action) => {
			if (!action.payload) state.paginLoading = null
			else state.paginLoading = action.payload
		},
		setUploadLoading: (state, action) => {
			if (!action.payload) state.uploadLoading = null
			else state.uploadLoading = action.payload
		},
		setCR: (state, action) => {
			if (!action.payload) state.callRunning = false
			else state.callRunning = action.payload
		},
		setFilters: (state, action) => {
			if (action.payload) {
				let newFilters = [{ name: "all", val: "" }]

				action.payload.map((item) => {
					return newFilters.push({ name: item, val: item })
				})

				state.filters = newFilters
			} else {
				state.filters = []
			}
		},
		// The next 4 reducers define the time interval we want the logs to show. The setStartDate is special, because we need to compare the newDateIn and the oldDateOut to prevent incoherent values from being stored.
		setStartDate: (state, action) => {
			const newDateIn = action.payload

			if (action.payload.length < 11) {
				state.dateIn = newDateIn
			}
		},
		setStartHour: (state, action) => {
			state.hourIn = action.payload
		},
		setEndDate: (state, action) => {
			if (action.payload.length < 11) {
				state.dateOut = action.payload
			}
		},
		setEndHour: (state, action) => {
			state.hourOut = action.payload
		},
		setType: (state, action) => {
			state.type = action.payload
		},
		setElements: (state, action) => {
			////console.log(action)

			let a = []
			let og = state.data[0]
			a.push({ ...og, files: action.payload._embedded.items })
			state.data = a
			state.loading = false
			state.pagin = {
				limit: action.payload.limit,
				page: action.payload.page,
				pages: action.payload.pages,
				total: action.payload.total,
			}
		},
		addElements: (state, action) => {
			//console.log(action)

			// Ici on combine la data de fichiers/dossiers dans le state redux, et on y ajoute les fichiers récupérés depuis l'appel api
			let a = []
			let og = state.data[0]
			a.push({
				...og,
				files: [...og.files, ...action.payload._embedded.items],
			})
			state.data = a
			state.pagin = {
				limit: action.payload.limit,
				page: action.payload.page,
				pages: action.payload.pages,
				total: action.payload.total,
			}
		},
		setSearchVal: (state, action) => {
			state.searchVal = action.payload
		},
		initSearch: (state, action) => {
			//console.log(action)

			let a = []
			let og = state.data[0]
			a.push({
				...og,
				files: action.payload._embedded.items,
				name: "mLayout.compass.results",
				reps: null,
				actionBtn: null,
			})
			state.data = a

			state.searchMode = true
			state.searchResults = []
			state.searchVal = action.payload.search
			state.loading = false
			state.pagin = {
				limit: action.payload.limit,
				page: action.payload.page,
				pages: action.payload.pages,
				total: action.payload.total,
			}
		},
		manualFilter: (state, action) => {
			let val = action.payload.toUpperCase()

			if (state.searchResults.length === 0)
				state.searchResults = state.data[0].files

			let newFiles = []
			let tempoFiles = JSON.parse(JSON.stringify(state.searchResults))

			tempoFiles.map((item) => {
				if (
					item.userAgent?.toUpperCase().includes(val) ||
					item.userEmail?.toUpperCase().includes(val) ||
					item.userIp?.toUpperCase().includes(val) ||
					item.message?.toUpperCase().includes(val) ||
					item.object?.toUpperCase().includes(val)
				)
					return newFiles.push(item)
				else return undefined
			})

			state.data[0].files = newFiles
			state.pagin = {}
		},
	},
})

export const { init } = logsSlice.actions

export default logsSlice.reducer
