import React from "react"
import PropTypes from "prop-types"

import LoaderBars from "../../components/animations/LoaderBars"

import { ReactComponent as Logo } from "../../assets/miniLogo.svg"
import { ReactComponent as SuccessIcon } from "../../assets/images/success.svg"
import { ReactComponent as ErrorIcon } from "../../assets/images/error.svg"

const DomToastFrame = React.memo(function DomToast({
	successState,
	errorState,
	loading,
	text,
}) {
	return (
		<div id="followIdsFrame" className="domToastViewFrame">
			<div id="followIds">
				<div className="domToastView">
					{(successState || errorState) && (
						<div className="stateIcon">
							{successState && <SuccessIcon />}
							{errorState && <ErrorIcon />}
						</div>
					)}

					{loading ? (
						<LoaderBars mini />
					) : (
						<>
							<Logo />
							<p>{text}</p>
						</>
					)}
				</div>
			</div>
		</div>
	)
})

export default DomToastFrame

DomToastFrame.propTypes = {
	successState: PropTypes.bool,
	errorState: PropTypes.bool,
	loading: PropTypes.bool,
	text: PropTypes.string,
}
