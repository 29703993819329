import { toastr } from "react-redux-toastr"
import {
	handleError,
	requestPaginationLoop,
} from "../../../../actions/generalUtils"
import I18n from "../../../../i18n"

function headers(payload) {
	let a = {
		"Accept-Language": localStorage.getItem("i18nextLng"),
		Authorization: "Bearer " + payload.token,
		"X-Ls-Token": payload.xLsT,
	}

	if (!payload.formData) a = { ...a, "Content-Type": "application/json" }

	return a
}

function formQuery(d) {
	let q = ""
	let first = true

	if (d.offset) {
		first = false
		q += "?offset=" + d.offset
	}

	if (d.limit) {
		if (first) q += "?limit=" + d.limit
		else q += "&limit=" + d.limit
		first = false
	}

	if (d.search) {
		if (first) q += "?search=" + d.search
		else q += "&search=" + d.search
		first = false
	}

	if (typeof d.organizationId !== "undefined") {
		if (first) q += "?organizationId=" + d.organizationId
		else q += "&organizationId=" + d.organizationId
		first = false
	}

	if (typeof d.dateIn !== "undefined") {
		if (first) q += "?dateIn=" + d.dateIn
		else q += "&dateIn=" + d.dateIn
		first = false
	}

	if (typeof d.dateOut !== "undefined") {
		if (first) q += "?dateOut=" + d.dateOut
		else q += "&dateOut=" + d.dateOut
		first = false
	}

	if (typeof d.typeSearch !== "undefined" && d.typeSearch?.length > 0) {
		if (first) q += "?type=" + d.typeSearch
		else q += "&type=" + d.typeSearch
		first = false
	}

	return q
}

export async function getLogsForDownload(payload) {
	const url =
		localStorage.getItem("apiUrl") + "logs/content" + formQuery(payload)

	try {
		return await requestPaginationLoop(url, headers(payload))
	} catch (error) {
		console.error(error)

		toastr.error(
			I18n.t("forServices.error"),
			I18n.t("forServices.netIssue"),
		)
	}
}

export const getLogs = (payload) => {
	let responseStatus
	let url =
		localStorage.getItem("apiUrl") + "logs/content" + formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getLogsFilters = (payload) => {
	////console.log(payload)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") + "logs/filter" + formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getListOrganization = (payload) => {
	let url =
		localStorage.getItem("apiUrl") +
		"organizations?organizationId=" +
		payload.id

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			return res
		})
		.then((res) => res.json())
		.then((res) => res)
}

export const getExportLogs = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "logs/exports"

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}
