import { toastr } from "react-redux-toastr"
import I18n from "../../../../i18n"
import { handleError } from "../../../../actions/generalUtils"

function headers(payload) {
	let header = {
		"Accept-Language": localStorage.getItem("i18nextLng"),
		Authorization: "Bearer " + payload.token,
		"X-Ls-Token": payload.xLsT,
	}
	if (!payload.formData)
		header = { ...header, "Content-Type": "application/json" }

	return header
}

function formQuery(d, mode) {
	let query = ""

	let first = mode

	if (d.organizationId) {
		if (first) query += "/organization/" + d.organizationId
		else {
			query += "?organization=" + d.organizationId
		}
	}
	return query
}

/* -------------------------------------------------------------------------- */
/*                        DASHBOARD MANAGEMENT LICENCE                        */
/* -------------------------------------------------------------------------- */
export const getDashboardManagementLicence = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		"dashboard_management_licence" +
		formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

/* -------------------------------------------------------------------------- */
/*                      DASHBOARD MANAGEMENT LICENCE LIST                     */
/* -------------------------------------------------------------------------- */
export const getDashboardManagementLicenceList = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		`dashboard_management_licence/organization/${
			payload.organizationId
		}?page=${payload.page}&results=${payload.result}&filter=${
			payload.filter
		}${payload.status ? `&status=${payload.status}` : ""}`

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getOrganisationData = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		"dashboard_organization" +
		formQuery(payload, true)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getDistributionRolesData = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		"dashboard_management_role" +
		formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getManagersData = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		"dashboard_organization_manager" +
		formQuery(payload, true)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

/* -------------------------------------------------------------------------- */
/*                          DASHBOARD GROUPS GENERAL                          */
/* -------------------------------------------------------------------------- */
export const getDashboardGroupsGeneral = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		"dashboard_groups_general" +
		formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

/* -------------------------------------------------------------------------- */
/*                              DASHBOARD GROUPS                              */
/* -------------------------------------------------------------------------- */
export const getDashboardGroups = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") + "dashboard_groups" + formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

// Get a user authorizations report. Require, in the payload.data, a valid userId
export const getUserReport = (payload) => {
	// Track the response status.
	let responseStatus

	// responseCode is used here to store the request response status code.
	// The reason for this is that error handling doesn’t come right after receptionning the request, and res then isn’t the response data, but the file.
	// Using a variable that differ from responseStatus allows me to send correct data to handleError
	let responseCode
	let url = `${localStorage.getItem("apiUrl")}user/report/pdf?id=${
		payload.data.userId
	}`

	// Make the request
	return (
		fetch(url, {
			method: "GET",
			headers: headers(payload),
		})
			// In case we have a network error, trigger a toast message
			.catch(() => {
				toastr.error(
					I18n.t("forServices.error"),
					I18n.t("forServices.netIssue"),
				)
			})
			// If the request succeeded, see if the backend returned an error.
			.then((res) => {
				responseStatus = res?.ok
				responseCode = res.status
				return res
			})
			// Convert the response into a binary file.
			.then((res) => res.blob())
			// If the server responded with an error, display a toast notification; else, return the previous returned value, aka the binary file.
			.then((res) =>
				!responseStatus
					? handleError({ status: responseCode, msg: "userNotFound" })
					: res,
			)
	)
}

export const exportInactifUsers = (payload) => {
	let responseStatus
	let url =
		localStorage.getItem("apiUrl") +
		`user/status/export?organizationId=${payload.data}`

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.text())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}
