import { call, put, takeLatest, select } from "redux-saga/effects"

import * as Api from "./services"

import { addLCS, removeLCS } from "../../../../actions/generalUtils"

/* -------------------------------------------------------------------------- */
/*                                THREATS LIST                                */
/* -------------------------------------------------------------------------- */
export function* getThreatsList(action) {
	try {
		addLCS(action)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		yield put({
			type: "dashboardOverView/setThreatsListLoading",
			payload: true,
		})

		const payload = { token, xLsT, organizationId }
		const data = yield call(Api.getThreatsList, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				yield put({
					type: "dashboardOverView/setThreatsList",
					payload: data,
				})
				yield put({ type: "dashboardOverView/setThreatsListLoading" })
			}
		}
	} catch (error) {
		console.error(error)
	}
}

export default function* dashboardOverViewSagas() {
	yield takeLatest("DBOVERVIEW_GET_THREATS_LIST", getThreatsList)
}
