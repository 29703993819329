import { all } from "redux-saga/effects"

import authSagas from "./pages/login/sagas"
import transferSagas from "./pages/transfer/sagas"
import passSagas from "./pages/pass/sagas"
import filesSagas from "./pages/files/sagas"
import manageSagas from "./pages/manage/sagas"
import paramsSagas from "./pages/params/sagas"
import gSagas from "./actions/sagas"
import licenseSagas from "./license/sagas"
import popupSagas from "./popup/sagas"
import dashboardLPSagas from "./pages/dashboard/products/pass/sagas"
import dashboardSPSagas from "./pages/dashboard/products/sharedStorage/sagas"
import dashboardMNGSagas from "./pages/dashboard/products/management/sagas"
import dashboardLogsSagas from "./pages/dashboard/products/logs/sagas"
import dashboardOverViewSagas from "./pages/dashboard/products/overView/sagas"

export default function* appSagas() {
	yield all([
		authSagas(),
		transferSagas(),
		passSagas(),
		filesSagas(),
		manageSagas(),
		paramsSagas(),
		gSagas(),
		licenseSagas(),
		popupSagas(),
		dashboardLPSagas(),
		dashboardSPSagas(),
		dashboardMNGSagas(),
		dashboardLogsSagas(),
		dashboardOverViewSagas(),
	])
}
