import { toastr } from "react-redux-toastr"
import I18n from "../i18n"
import { handleError, toast } from "../actions/generalUtils"

const headers = (payload) => {
	let a = {
		"Accept-Language": localStorage.getItem("i18nextLng"),
		Authorization: "Bearer " + payload.token,
		"X-Ls-Token": payload.xLsT,
	}

	if (!payload.formData) a = { ...a, "Content-Type": "application/json" }

	return a
}

function formQuery(d) {
	let q = ""
	let first = true

	if (d.offset) {
		first = false
		q += "?offset=" + d.offset
	}

	if (d.id) {
		if (first) q += "?id=" + d.id
		else q += "&id=" + d.id
		first = false
	}

	if (d.limit) {
		if (first) q += "?limit=" + d.limit
		else q += "&limit=" + d.limit
		first = false
	}

	if (d.search) {
		if (first) q += "?search=" + d.search
		else q += "&search=" + d.search
		first = false
	}

	if (d.organizationId) {
		if (first) q += "?organizationId=" + d.organizationId
		else q += "&organizationId=" + d.organizationId
		first = false
	}

	if (typeof d.categoryId !== "undefined") {
		if (first) q += "?categoryId=" + d.categoryId
		else q += "&categoryId=" + d.categoryId
		first = false
	}

	if (typeof d.searchType !== "undefined") {
		if (first) q += "?searchType=" + d.searchType
		else q += "&searchType=" + d.searchType
		first = false
	}

	return q
}

export const createPass = (payload) => {
	let responseStatus

	// Setup the request.
	const url = window.localStorage.getItem("apiUrl") + "credentials"

	const requestConfig = {
		method: "POST",
		crossDomain: true,
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	}

	// Make the request
	return fetch(url, requestConfig)
		.catch((error) => {
			toast(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
				"error",
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => {
			if (responseStatus) return res
			else return handleError(res)
		})
}

export const getDomainPass = (payload) => {
	let responseStatus

	// Setup the request.
	const url =
		window.localStorage.getItem("apiUrl") +
		"credentials/decrypt/domain?webSite=" +
		payload.data.url
	const requestConfig = {
		method: "GET",
		crossDomain: true,
		headers: headers(payload),
	}

	// Make the request
	return fetch(url, requestConfig)
		.catch((error) => {
			toast(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
				"error",
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => {
			if (responseStatus) return res
			else return handleError(res)
		})
}

export const getPassDetails = (payload) => {
	let responseStatus

	// Setup the request.
	const url =
		window.localStorage.getItem("apiUrl") +
		"credentials/decrypt/" +
		payload.data
	const requestConfig = {
		method: "GET",
		crossDomain: true,
		headers: headers(payload),
	}

	// Make the request
	return fetch(url, requestConfig)
		.catch((error) => {
			toast(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
				"error",
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => {
			if (responseStatus) {
				return res
			} else if (res.code === 404) {
				return "NotFound"
			} else {
				return handleError(res)
			}
		})
}

export const sendPasswordUsageLog = (payload) => {
	let responseStatus

	// Setup the request
	const url =
		window.localStorage.getItem("apiUrl") +
		"credentials/logs/" +
		payload.data.credentialId
	const requestConfig = {
		method: "POST",
		crossDomain: true,
		headers: headers(payload),
	}

	// Make the request
	return fetch(url, requestConfig)
		.catch((error) => {
			toast(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
				"error",
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => (!responseStatus ? res.json() : res))
		.then((res) => {
			if (responseStatus) {
				return res
			} else if (res.code === 404) {
				return "NotFound"
			} else {
				return handleError(res)
			}
		})
}

export const getCredentials = (payload) => {
	//console.log("getCredentials service payload -> ", payload)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") + "credentials" + formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) =>
			!responseStatus
				? handleError(res)
				: res?.filter(
						(pwd) => !pwd?.trashName || pwd?.trashName?.length <= 0,
				  ),
		) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas

	/*.then(res => (!responseStatus
		? payload.search ? { limit: 10, page: 1, pages: 1, total: 0, _embedded: {items: []} } : handleError(res)
		: res)
	)*/
}

export const getRootCats = (payload) => {
	////console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "categories/root"

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getPasswordPolicy = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "passwordpolicies/" + payload.id

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
		.catch((e) => {
			console.error(e)
			return toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
		})
}
