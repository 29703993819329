import { createSlice } from "@reduxjs/toolkit"

const base = [
	{
		id: 0,
		name: "mLayout.personnalSpace",
		static: true,
		noSelect: true,
		showHelp: "lT",
		boardHelp: "lT",
		forceBoardHelp: true,
		repName: "mLayout.categories",
		fileName: "mLayout.files",
		icon: "personal",
		actionBtn: [
			{ name: "transfer.addTransfer", action: "addTransfer" },
			{ name: "transfer.addDeposit", action: "addDeposit" },
			{ name: "transfer.addContact", action: "addContact" },
		],
		reps: [
			{
				name: "transfer.transfers",
				static: true,
				showHelp: "transfers",
				boardHelp: "transfers",
				loadData: "LT_GET_ACTIVE_TRANSFERS_SAGA",
				repName: "mLayout.folder",
				fileName: "transfer.transfers",
				comp: "Transfer",
				actionBtn: [
					{ name: "transfer.addTransfer", action: "addTransfer" },
				],
				searchable: true,
				reps: [
					{
						name: "mLayout.archives",
						static: true,
						showHelp: "transfers",
						loadData: "LT_GET_ARCHIVE_TRANSFERS_SAGA",
						fileName: "transfer.transfers",
						comp: "Transfer",
						searchable: true,
						noSelect: true,
					},
				],
			},
			{
				name: "transfer.deposits",
				static: true,
				showHelp: "deposits",
				boardHelp: "deposits",
				loadData: "LT_GET_ACTIVE_DEPOSITS_SAGA",
				repName: "mLayout.folder",
				fileName: "transfer.deposits",
				comp: "Deposit",
				actionBtn: [
					{ name: "transfer.addDeposit", action: "addDeposit" },
				],
				searchable: true,
				reps: [
					{
						name: "mLayout.archives",
						static: true,
						showHelp: "deposits",
						searchable: true,
						loadData: "LT_GET_ARCHIVE_DEPOSITS_SAGA",
						fileName: "transfer.deposits",
						comp: "Deposit",
						noSelect: true,
					},
				],
			},
			{
				name: "transfer.contactList",
				static: true,
				showHelp: "contacts",
				boardHelp: "contacts",
				loadData: "LT_GET_CONTACTS_SAGA",
				fileName: "transfer.contacts",
				comp: "Contact",
				searchable: true,
				actionBtn: [
					{ name: "transfer.addContact", action: "addContact" },
				],
			},
		],
	},
	{
		id: -1,
		icon: "share",
		name: "mLayout.shared",
		static: true,
		searchable: true,
		reps: [
			{
				name: "transfer.deposits",
				static: true,
				showHelp: "sharedDeposits",
				boardHelp: "sharedDeposits",
				searchable: true,
				loadData: "LT_GET_SHARED_DEPOSITS_SAGA",
				fileName: "transfer.deposits",
				comp: "Deposit",
			},
		],
	},
]

const transferSlice = createSlice({
	name: "transfer",
	initialState: {
		base: base,
		loading: false,
		data: null,
		history: [],
		active: {},
		searchMode: false,
		searchVal: "",
		searchResults: [],
		lastActionResult: null,
		hasArchive: null,
		paginLoading: false,
		arbo: [],
	},
	reducers: {
		init: (state) => {
			////console.log("INIT")
			state.data = base
			state.history = []
			state.active = {}
			state.searchMode = false
			state.searchVal = ""
			state.pageSize = 9
			state.arbo = base
		},
		setErrorNotFound: (state, action) => {
			state.errorNotFound = action?.payload ? action?.payload : false
			state.callRunning = false
		},
		setCR: (state, action) => {
			if (!action.payload) state.callRunning = false
			else state.callRunning = action.payload
		},
		savePageSize: (state, action) => {
			state.pageSize = action.payload
		},
		updatePagin: (state, action) => {
			if (action.payload) state.pagin = action.payload
		},
		toggleLoading: (state) => {
			state.loading = !state.loading
		},
		setLAR: (state, action) => {
			if (!action.payload) state.lastActionResult = null
			else state.lastActionResult = action.payload
		},
		paginLoading: (state, action) => {
			if (!action.payload) state.paginLoading = null
			else state.paginLoading = action.payload
		},
		addElements: (state, action) => {
			//console.log(action)

			// Ici on combine la data de fichiers/dossiers dans le state redux, et on y ajoute les fichiers récupérés depuis l'appel api
			let a = []
			let og = state.data[0]
			a.push({
				...og,
				files: [...og.files, ...action.payload._embedded.items],
				reps: og.reps ? og.reps : [],
			})
			state.data = a
			state.active = {}
			state.pagin = {
				limit: action.payload.limit,
				page: action.payload.page,
				pages: action.payload.pages,
				total: action.payload.total,
			}
		},
		enterInFolder: (state, action) => {
			//console.log(action)
			let pt = action.payload.productTarget
				? action.payload.productTarget
				: 0 // Quel sous produit on vise ? N'existe que si l'on est à la base du produit et depuis le compass affichant tous les sous produits

			if (action.payload.justRefresh) {
				// Lorsqu'on reste dans le même repertoire mais qu'on update seulement les données (par ex après post ou delete)

				// Ici on combine la data de fichiers/dossiers dans le state redux, et on y ajoute les fichiers récupérés depuis l'appel api
				let a = []
				let og = state.data[0]
				a.push({
					...og,
					files: action.payload?._embedded?.items,
					reps: og.reps ? og.reps : [],
				})

				if (action.payload.retriveActive) {
					let a = action.payload._embedded.items.find(
						(item) =>
							item.id ===
							state.active[Object.keys(state.active)[0]].id,
					)
					let elemType =
						state.active[Object.keys(state.active)[0]].elemType
					let ai = Object.keys(state.active)[0]

					state.active = { [ai]: { ...a, elemType } }
				} else state.active = {}

				state.data = a
				state.pagin = {
					limit: action.payload.limit,
					page: action.payload.page,
					pages: action.payload.pages,
					total: action.payload.total,
				}

				if (action.payload._embedded)
					state.hasArchive = action.payload._embedded.hasArchive
				else state.hasArchive = null

				return
			}

			if (!action.payload.historyState) {
				//si on entre dans un nouveau dossier (non depuis l'historique du compass)

				if (action.payload.static) {
					// On s'occupe de save en historique un élément qui est défini dans la base statique
					// on sauvegarde dans l'historique les infos du répertoire, sauf les files qui seront rappellés si on y retourne
					state.history.push({
						name: state.data[pt].name,
						static: state.data[pt].static,
						loadData: state.data[pt].loadData,
						historyState: JSON.stringify(state.history),
						repName: state.data[pt].repName,
						fileName: state.data[pt].fileName,
						comp: state.data[pt].comp,
						reps: JSON.stringify(state.data[pt].reps),
						noSelect: state.data[pt].noSelect ? true : false,
						showHelp: state.data[pt].showHelp
							? state.data[pt].showHelp
							: false,
						boardHelp: state.data[pt].boardHelp
							? state.data[pt].boardHelp
							: false,
						forceBoardHelp: state.data[pt].forceBoardHelp
							? state.data[pt].forceBoardHelp
							: false,
						actionBtn: state.data[pt].actionBtn,
						searchable: state.data[pt].searchable ? true : false,
					})
				} // Le cas où il n'est pas statique sera prévu pour Lock files, pass..

				let a = []

				if (action.payload?.isArchives) {
					const { domTarget } = action.payload
					const parentFolder = state.data[pt]?.reps[domTarget]
					const parentFolderHistoryData = {
						...state.history[0],
						...parentFolder,
					}
					const currentFolderData = {
						...parentFolder,
						...state.data[pt]?.reps[domTarget]?.reps[0],
						files: action.payload?._embedded?.items,
						reps: [],
					}

					state.history.push(parentFolderHistoryData)
					delete currentFolderData.actionBtn

					a.push(currentFolderData)
				} else {
					// Ici on combine la data de fichiers/dossiers dans le state redux, et on y ajoute les fichiers récupérés depuis l'appel api
					let og = state.data[pt].reps[action.payload.domTarget]
					a.push({
						...og,
						files: action.payload?._embedded?.items,
						reps: og?.reps ? og?.reps : [],
					})
				}

				state.data = a
				state.active = {}
				state.pagin = {
					limit: action.payload.limit,
					page: action.payload.page,
					pages: action.payload.pages,
					total: action.payload.total,
				}
				state.loading = false

				if (action.payload._embedded)
					state.hasArchive = action.payload._embedded.hasArchive
				else state.hasArchive = null
			} else {
				// ici on revient donc sur un dossier précedent

				state.history = JSON.parse(action.payload.historyState)

				let ns = []
				let p = action.payload

				ns.push({
					name: p.name,
					static: p.static,
					loadData: p.loadData,
					files: action.payload._embedded.items,
					repName: p.repName,
					fileName: p.fileName,
					comp: p.comp,
					reps: JSON.parse(action.payload.reps),
					noSelect: p.noSelect ? true : false,
					showHelp: p.showHelp ? p.showHelp : false,
					boardHelp: p.boardHelp ? p.boardHelp : false,
					forceBoardHelp: p.forceBoardHelp ? p.forceBoardHelp : false,
					actionBtn: p.actionBtn,
					searchable: p.searchable ? true : false,
				})

				state.data = ns
				state.active = {}
				state.loading = false
				state.pagin = {
					limit: action.payload.limit,
					page: action.payload.page,
					pages: action.payload.pages,
					total: action.payload.total,
				}

				if (action.payload._embedded)
					state.hasArchive = action.payload._embedded.hasArchive
				else state.hasArchive = null
			}
		},
		manageSelected: (state, action) => {
			if (state.active[action.payload.elemKey]) {
				// Si l'élément est déjà dans les actifs on le retire
				delete state.active[action.payload.elemKey]
			} else {
				if (action.payload.uniSelect)
					state.active = {
						[action.payload.elemKey]: action.payload,
					}
				// Si on est en séléction unique (clic sur la card) on reset la sélec par l'élément
				else
					state.active = {
						...state.active,
						[action.payload.elemKey]: action.payload,
					} // sinon on le rajoute dans la sélec
			}
		},
		updateActive: (state, action) => {
			if (
				!action.payload?.removeTransfer &&
				!action.payload?.addTransfer
			) {
				let targetPos = Object.keys(state.active)[0]
				////console.log("SLICE active targetPos : ", target);
				if (targetPos) {
					let a = {
						...action.payload,
						elemKey: state.active[targetPos].elemKey,
						elemType: state.active[targetPos].elemType,
					}
					state.active[targetPos] = a // On update l'élément actif (qui est une copie de son original dans la data du store du produit)
					state.data[0].files[
						state.data[0].files.findIndex(
							(f) => f.id === action.payload.id,
						)
					] = a // On l'update aussi dans son store original
				}
			} else if (action.payload?.addTransfer) {
				delete action.payload?.addTransfer

				state.active[action.payload?.id] = action.payload
			} else {
				delete state.active[action.payload?.id]
			}
		},
		updateNewActive: (state, action) => {
			let targetPos = Object.keys(state.active)[0]
			////console.log("SLICE active targetPos : ", target);
			if (targetPos) {
				let a = { ...action.payload, elemKey: 0, elemType: "Deposit" }
				state.active = { [action.payload.id]: a } // On update l'élément actif (qui est une copie de son original dans la data du store du produit)
				state.data[0].files[targetPos] = a // On l'update aussi dans son store original
			}
		},
		selectById: (state, action) => {
			let elem = state.data[0].files.find((x) => x.id === action.payload)

			if (elem) {
				let elemPos = state.data[0].files.findIndex(
					(f) => f.id === action.payload,
				)

				state.active = { [elemPos]: elem }
			}
		},
		unselectAll: (state) => {
			state.active = {}
		},
		selectAll: (state) => {
			let a
			state.data[0].files.map((file) => {
				return (a = {
					...a,
					[file?.id]: { ...file, elemType: state.data?.[0]?.comp },
				})
			})
			state.active = a
		},
		setSearchVal: (state, action) => {
			state.searchVal = action.payload
		},
		initSearch: (state, action) => {
			//console.log(action)

			let a = []
			let og = state.data[0]
			a.push({
				...og,
				files: action.payload._embedded.items,
				name: "mLayout.compass.results",
				reps: null,
				actionBtn: null,
			})
			state.data = a

			state.searchMode = true
			state.searchResults = []
			state.searchVal = action.payload.search
			state.active = {}
			state.loading = false
			state.pagin = {
				limit: action.payload.limit,
				page: action.payload.page,
				pages: action.payload.pages,
				total: action.payload.total,
			}
		},
		manualFilter: (state, action) => {
			let val = action.payload.toUpperCase()

			if (state.searchResults.length === 0)
				state.searchResults = state.data[0].files

			let newFiles = []
			let tempoFiles = JSON.parse(JSON.stringify(state.searchResults))

			tempoFiles.map((item) => {
				if (
					item.name?.toUpperCase().includes(val) ||
					item.token?.toUpperCase().includes(val) ||
					item.email?.toUpperCase().includes(val) ||
					item.phoneNumber?.toUpperCase().includes(val) ||
					item.firstname?.toUpperCase().includes(val) ||
					item.lastname?.toUpperCase().includes(val)
				)
					return newFiles.push(item)
				else return undefined
			})

			state.data[0].files = newFiles
			state.pagin = {}
		},
		blockFilesUpload: (state) => {
			state.stopFilesUpload = true
		},
		unblockFilesUpload: (state) => {
			delete state.stopFilesUpload
		},
		addUncertainFile: (state, action) => {
			if (typeof state.uncertainFiles === "object") {
				state.uncertainFiles.push(action.payload)
			} else {
				state.uncertainFiles = [action.payload]
			}

			return
		},
		removeUncertainFiles: (state) => {
			delete state.uncertainFiles
			return
		},
	},
})

export const {
	initTranfer,
	enterInFolder,
	manageSelected,
	updateActive,
	unselectAll,
	selectAll,
} = transferSlice.actions

export default transferSlice.reducer
