import { configureStore } from "@reduxjs/toolkit"
import appReducers from "../reducers"
import createSagaMiddleware from "redux-saga"
import * as Sentry from "@sentry/react"

let sentryReduxEnhancer = (defaultEnhancers) => defaultEnhancers
let reduxConfig = {
	reducer: appReducers,
	middleware: (getDefaultMiddleware) => {
		return getDefaultMiddleware({ serializableCheck: false }).concat(
			sagaMiddleware,
		)
	},
}

if (process.env.REACT_APP_SENTRY_USAGE === "true") {
	sentryReduxEnhancer = Sentry.createReduxEnhancer({
		actionTransformer: () => {
			return null
		},
		stateTransformer: (state) => {
			// The dashboard state contains cyclics references. Since it doesn't contains any sensible data, we're
			// simply going to get it out of the deep copy mecanism.
			const amputedState = { ...state }
			delete amputedState.dashboardLP
			delete amputedState.dashboardSP
			delete amputedState.dashboardMNG
			delete amputedState.dashboardLogs
			delete amputedState.dashboardOverView

			const newState = JSON.parse(JSON.stringify(amputedState))
			newState.auth.xLsToken = "mock xLsToken"
			if (newState.auth.token) {
				newState.auth.token.token = "mock token"
			}

			newState.pass.data?.forEach((dataItem) => {
				dataItem.files = dataItem.files?.map((file) => {
					if (file.password) {
						return { ...file, password: "mock password" }
					}
					return file
				})
			})

			Object.keys(newState.pass?.active)?.forEach((selectedFile) => {
				if (newState.pass.active[selectedFile].password) {
					newState.pass.active[selectedFile].password =
						"mock password"
				}
			})

			newState.dashboardLP = state.dashboardLP
			newState.dashboardSP = state.dashboardSP
			newState.dashboardMNG = state.dashboardMNG
			newState.dashboardLogs = state.dashboardLogs
			newState.dashboardOverview = state.dashboardOverview

			return newState
		},
		attachReduxState: true,
	})

	reduxConfig.enhancers = (defaultEnhancers) =>
		defaultEnhancers.concat(sentryReduxEnhancer)
}

const sagaMiddleware = createSagaMiddleware()

export default configureStore(reduxConfig)

export { sagaMiddleware }
