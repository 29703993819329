import { call, put, takeLatest, select, spawn, all } from "redux-saga/effects"

import * as Api from "./services"
import {
	addLCS,
	backwardCompApi,
	removeLCS,
} from "../../../../actions/generalUtils"

//////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////     dashboard monitoring      ///////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
export function* getDashboardMonitoringUsage(action) {
	try {
		addLCS(action)

		let dataLocalStorage = JSON.parse(localStorage.getItem("dashboardSP"))
		let today = new Date()
		today.setHours(4)
		today.setMinutes(0)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		const payload = { token, xLsT, organizationId }
		let data
		let isLocal

		if (
			!dataLocalStorage ||
			!dataLocalStorage.dashboardMonitoring ||
			dataLocalStorage.callDate === null ||
			new Date(dataLocalStorage.callDate) < today
		) {
			isLocal = false
			data = yield call(Api.getDashboardMonitoringUsage, payload)
		} else {
			isLocal = true
			data = dataLocalStorage.dashboardMonitoring
		}

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardSP/toggleLoading" })

				const callDate = new Date()

				if (!isLocal) {
					const payload = {
						callDate,
						key: "dashboardMonitoring",
						value: data,
					}

					yield put({ type: "dashboardSP/setLoader", payload })
				} else {
					yield put({
						type: "dashboardSP/setLoader",
						payload: { dataLocalStorage, isLocal: true },
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

export function* getDashboardMonitoringUsageGraph(action) {
	try {
		addLCS(action)

		let dataLocalStorage = JSON.parse(localStorage.getItem("dashboardSP"))
		let today = new Date()
		today.setHours(4)
		today.setMinutes(0)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		const payload = { token, xLsT, organizationId }
		let data
		let isLocal

		if (
			!dataLocalStorage ||
			!dataLocalStorage.dashboardMonitoringGraph ||
			dataLocalStorage.callDate === null ||
			new Date(dataLocalStorage.callDate) < today
		) {
			isLocal = false
			data = yield call(Api.getDashboardMonitoringUsageGraph, payload)
		} else {
			isLocal = true
			data = dataLocalStorage.dashboardMonitoringGraph
		}

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardSP/toggleLoading" })

				const callDate = new Date()

				if (!isLocal) {
					const payload = {
						callDate,
						key: "dashboardMonitoringGraph",
						value: data,
					}

					yield put({ type: "dashboardSP/setLoader", payload })
				} else {
					yield put({
						type: "dashboardSP/setLoader",
						payload: { dataLocalStorage, isLocal: true },
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////     storage      //////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
export function* getStorageData(action) {
	try {
		addLCS(action)

		let dataLocalStorage = JSON.parse(localStorage.getItem("dashboardSP"))
		let today = new Date()
		today.setHours(4)
		today.setMinutes(0)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		const payload = { token, xLsT, organizationId }
		let data
		let isLocal

		if (
			!dataLocalStorage ||
			!dataLocalStorage.storageData ||
			dataLocalStorage.callDate === null ||
			new Date(dataLocalStorage.callDate) < today
		) {
			isLocal = false
			data = yield call(Api.getStorageData, payload)
		} else {
			isLocal = true
			data = dataLocalStorage.storageData
		}

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardSP/toggleLoading" })

				const callDate = new Date()

				if (!isLocal) {
					const payload = {
						callDate,
						key: "storageData",
						value: data,
					}

					yield put({ type: "dashboardSP/setLoader", payload })
				} else {
					yield put({
						type: "dashboardSP/setLoader",
						payload: { dataLocalStorage, isLocal: true },
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

export function* getStorageDataPassList(action) {
	try {
		addLCS(action)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		const fullList = yield select((state) => state.dashboardSP.fullList)

		const result = action.payload.result ? action.payload.result : 5
		const page = action.payload.page ? action.payload.page : 1

		if (page > 1) {
			yield put({ type: "dashboardSP/paginLoading", payload: true })
		} else {
			yield put({ type: "dashboardSP/toggleLoading", payload: true })
		}

		yield put({ type: "dashboardSP/toggleLoading", payload: true })

		const payload = { token, xLsT, organizationId, result, page }
		const data = yield call(Api.getStorageDataPassList, payload)

		yield put({ type: "dashboardSP/paginLoading" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardSP/toggleLoading" })

				if (action.payload.fullList) {
					if (data.length === 0 || data.length < 19) {
						yield put({
							type: "dashboardSP/setPagingLimit",
							payload: false,
						})
					} else {
						yield put({
							type: "dashboardSP/setPagingLimit",
							payload: true,
						})
					}

					yield put({
						type: "dashboardSP/setFullList",
						payload: [...fullList, ...data],
					})
				} else if (action.payload.popup) {
					yield put({
						type: "dashboardSP/setFullList",
						payload: data,
					})
				} else {
					yield put({
						type: "dashboardSP/setShowList",
						payload: data,
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

export function* getStorageDataTransferList(action) {
	try {
		addLCS(action)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		const fullList = yield select((state) => state.dashboardSP.fullList)

		const result = action.payload.result ? action.payload.result : 5
		const page = action.payload.page ? action.payload.page : 1

		if (page > 1) {
			yield put({ type: "dashboardSP/paginLoading", payload: true })
		} else {
			yield put({ type: "dashboardSP/toggleLoading", payload: true })
		}

		yield put({ type: "dashboardSP/toggleLoading", payload: true })

		const payload = { token, xLsT, organizationId, result, page }
		const data = yield call(Api.getStorageDataTransferList, payload)

		yield put({ type: "dashboardSP/paginLoading" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardSP/toggleLoading" })

				if (action.payload.fullList) {
					if (data.length === 0 || data.length < 19) {
						yield put({
							type: "dashboardSP/setPagingLimit",
							payload: false,
						})
					} else {
						yield put({
							type: "dashboardSP/setPagingLimit",
							payload: true,
						})
					}

					yield put({
						type: "dashboardSP/setFullList",
						payload: [...fullList, ...data],
					})
				} else if (action.payload.popup) {
					yield put({
						type: "dashboardSP/setFullList",
						payload: data,
					})
				} else {
					yield put({
						type: "dashboardSP/setShowList",
						payload: data,
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

export function* getStorageDataDepositBoxList(action) {
	try {
		addLCS(action)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		const fullList = yield select((state) => state.dashboardSP.fullList)

		const result = action.payload.result ? action.payload.result : 5
		const page = action.payload.page ? action.payload.page : 1

		if (page > 1) {
			yield put({ type: "dashboardSP/paginLoading", payload: true })
		} else {
			yield put({ type: "dashboardSP/toggleLoading", payload: true })
		}

		yield put({ type: "dashboardSP/toggleLoading", payload: true })

		const payload = { token, xLsT, organizationId, result, page }
		const data = yield call(Api.getStorageDataDepositBoxList, payload)

		yield put({ type: "dashboardSP/paginLoading" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardSP/toggleLoading" })

				if (action.payload.fullList) {
					if (data.length === 0 || data.length < 19) {
						yield put({
							type: "dashboardSP/setPagingLimit",
							payload: false,
						})
					} else {
						yield put({
							type: "dashboardSP/setPagingLimit",
							payload: true,
						})
					}

					yield put({
						type: "dashboardSP/setFullList",
						payload: [...fullList, ...data],
					})
				} else if (action.payload.popup) {
					yield put({
						type: "dashboardSP/setFullList",
						payload: data,
					})
				} else {
					yield put({
						type: "dashboardSP/setShowList",
						payload: data,
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

export function* getStorageDataSharedSpaceList(action) {
	try {
		addLCS(action)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		const fullList = yield select((state) => state.dashboardSP.fullList)

		const result = action.payload.result ? action.payload.result : 5
		const page = action.payload.page ? action.payload.page : 1

		if (page > 1) {
			yield put({ type: "dashboardSP/paginLoading", payload: true })
		} else {
			yield put({ type: "dashboardSP/toggleLoading", payload: true })
		}

		yield put({ type: "dashboardSP/toggleLoading", payload: true })

		const payload = { token, xLsT, organizationId, result, page }
		const data = yield call(Api.getStorageDataSharedSpaceList, payload)

		yield put({ type: "dashboardSP/paginLoading" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardSP/toggleLoading" })

				if (action.payload.fullList) {
					if (data.length === 0 || data.length < 19) {
						yield put({
							type: "dashboardSP/setPagingLimit",
							payload: false,
						})
					} else {
						yield put({
							type: "dashboardSP/setPagingLimit",
							payload: true,
						})
					}

					yield put({
						type: "dashboardSP/setFullList",
						payload: [...fullList, ...data],
					})
				} else if (action.payload.popup) {
					yield put({
						type: "dashboardSP/setFullList",
						payload: data,
					})
				} else {
					yield put({
						type: "dashboardSP/setShowList",
						payload: data,
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

export function* getStorageDataPersonalSpaceList(action) {
	try {
		addLCS(action)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		const fullList = yield select((state) => state.dashboardSP.fullList)

		const result = action.payload.result ? action.payload.result : 5
		const page = action.payload.page ? action.payload.page : 1

		if (page > 1) {
			yield put({ type: "dashboardSP/paginLoading", payload: true })
		} else {
			yield put({ type: "dashboardSP/toggleLoading", payload: true })
		}

		yield put({ type: "dashboardSP/toggleLoading", payload: true })

		const payload = { token, xLsT, organizationId, result, page }
		const data = yield call(Api.getStorageDataPersonalSpaceList, payload)

		yield put({ type: "dashboardSP/paginLoading" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardSP/toggleLoading" })

				if (action.payload.fullList) {
					if (data.length === 0 || data.length < 19) {
						yield put({
							type: "dashboardSP/setPagingLimit",
							payload: false,
						})
					} else {
						yield put({
							type: "dashboardSP/setPagingLimit",
							payload: true,
						})
					}

					yield put({
						type: "dashboardSP/setFullList",
						payload: [...fullList, ...data],
					})
				} else if (action.payload.popup) {
					yield put({
						type: "dashboardSP/setFullList",
						payload: data,
					})
				} else {
					yield put({
						type: "dashboardSP/setShowList",
						payload: data,
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

//////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////   exhibition area   //////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
export function* getFolderlExhibitionArea(action) {
	try {
		addLCS(action)

		let dataLocalStorage = JSON.parse(localStorage.getItem("dashboardSP"))
		let today = new Date()
		today.setHours(4)
		today.setMinutes(0)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		const payload = { token, xLsT, organizationId }
		let data
		let isLocal

		if (
			!dataLocalStorage ||
			!dataLocalStorage.folderExhiArea ||
			dataLocalStorage.callDate === null ||
			new Date(dataLocalStorage.callDate) < today
		) {
			isLocal = false
			data = yield call(Api.getFolderlExhibitionArea, payload)
		} else {
			isLocal = true
			data = dataLocalStorage.folderExhiArea
		}

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardSP/toggleLoading" })

				const callDate = new Date()

				if (!isLocal) {
					const payload = {
						callDate,
						key: "folderExhiArea",
						value: data,
					}

					yield put({ type: "dashboardSP/setLoader", payload })
				} else {
					yield put({
						type: "dashboardSP/setLoader",
						payload: { dataLocalStorage, isLocal: true },
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

export function* getFolderlExhibitionAreaList(action) {
	try {
		addLCS(action)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		const fullList = yield select((state) => state.dashboardSP.fullList)

		const metric = action.payload.metric
		const result = action.payload.result ? action.payload.result : 5
		const page = action.payload.page ? action.payload.page : 1
		const credentialFilter = action.payload.credentialFilter
			? "files"
			: "shareCount"

		if (page > 1) {
			yield put({ type: "dashboardSP/paginLoading", payload: true })
		} else {
			yield put({ type: "dashboardSP/toggleLoading", payload: true })
		}

		yield put({ type: "dashboardSP/toggleLoading", payload: true })

		const payload = {
			token,
			xLsT,
			organizationId,
			metric,
			result,
			page,
			credentialFilter,
		}
		const data = yield call(Api.getFolderlExhibitionAreaList, payload)

		yield put({ type: "dashboardSP/paginLoading" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardSP/toggleLoading" })

				if (action.payload.fullList) {
					if (data.length === 0 || data.length < 19) {
						yield put({
							type: "dashboardSP/setPagingLimit",
							payload: false,
						})
					} else {
						yield put({
							type: "dashboardSP/setPagingLimit",
							payload: true,
						})
					}

					yield put({
						type: "dashboardSP/setFullList",
						payload: [...fullList, ...data],
					})
				} else if (action.payload.popup) {
					yield put({
						type: "dashboardSP/setFullList",
						payload: data,
					})
				} else {
					yield put({
						type: "dashboardSP/setShowList",
						payload: data,
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

export function* getTransferExhibitionArea(action) {
	try {
		addLCS(action)

		let dataLocalStorage = JSON.parse(localStorage.getItem("dashboardSP"))
		let today = new Date()
		today.setHours(4)
		today.setMinutes(0)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		const payload = { token, xLsT, organizationId }
		let data
		let isLocal

		if (
			!dataLocalStorage ||
			!dataLocalStorage.transferExhiArea ||
			dataLocalStorage.callDate === null ||
			new Date(dataLocalStorage.callDate) < today
		) {
			isLocal = false
			data = yield call(Api.getTransferExhibitionArea, payload)
		} else {
			isLocal = true
			data = dataLocalStorage.transferExhiArea
		}

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardSP/toggleLoading" })

				const callDate = new Date()

				if (!isLocal) {
					const payload = {
						callDate,
						key: "transferExhiArea",
						value: data,
					}

					yield put({ type: "dashboardSP/setLoader", payload })
				} else {
					yield put({
						type: "dashboardSP/setLoader",
						payload: { dataLocalStorage, isLocal: true },
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

export function* getTransferExhibitionAreaList(action) {
	try {
		addLCS(action)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		const fullList = yield select((state) => state.dashboardSP.fullList)

		const metric = action.payload.metric
		const result = action.payload.result ? action.payload.result : 5
		const page = action.payload.page ? action.payload.page : 1
		const credentialFilter = action.payload.credentialFilter ? 1 : 0

		if (page > 1) {
			yield put({ type: "dashboardSP/paginLoading", payload: true })
		} else {
			yield put({ type: "dashboardSP/toggleLoading", payload: true })
		}

		yield put({ type: "dashboardSP/toggleLoading", payload: true })

		const payload = {
			token,
			xLsT,
			organizationId,
			metric,
			result,
			page,
			credentialFilter,
		}
		const data = yield call(Api.getTransferExhibitionAreaList, payload)

		yield put({ type: "dashboardSP/paginLoading" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardSP/toggleLoading" })

				if (action.payload.fullList) {
					if (data.length === 0 || data.length < 19) {
						yield put({
							type: "dashboardSP/setPagingLimit",
							payload: false,
						})
					} else {
						yield put({
							type: "dashboardSP/setPagingLimit",
							payload: true,
						})
					}

					yield put({
						type: "dashboardSP/setFullList",
						payload: [...fullList, ...data],
					})
				} else if (action.payload.popup) {
					yield put({
						type: "dashboardSP/setFullList",
						payload: data,
					})
				} else {
					yield put({
						type: "dashboardSP/setShowList",
						payload: data,
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

export function* getDepositExhibitionArea(action) {
	try {
		addLCS(action)

		let dataLocalStorage = JSON.parse(localStorage.getItem("dashboardSP"))
		let today = new Date()
		today.setHours(4)
		today.setMinutes(0)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		const payload = { token, xLsT, organizationId }
		let data
		let isLocal

		if (
			!dataLocalStorage ||
			!dataLocalStorage.depositExhiArea ||
			dataLocalStorage.callDate === null ||
			new Date(dataLocalStorage.callDate) < today
		) {
			isLocal = false
			data = yield call(Api.getDepositExhibitionArea, payload)
		} else {
			isLocal = true
			data = dataLocalStorage.depositExhiArea
		}

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardSP/toggleLoading" })

				const callDate = new Date()

				if (!isLocal) {
					const payload = {
						callDate,
						key: "depositExhiArea",
						value: data,
					}

					yield put({ type: "dashboardSP/setLoader", payload })
				} else {
					yield put({
						type: "dashboardSP/setLoader",
						payload: { dataLocalStorage, isLocal: true },
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

export function* getDepositExhibitionAreaList(action) {
	try {
		addLCS(action)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		const fullList = yield select((state) => state.dashboardSP.fullList)

		const metric = action.payload.metric
		const result = action.payload.result ? action.payload.result : 5
		const page = action.payload.page ? action.payload.page : 1
		const credentialFilter = action.payload.credentialFilter ? 1 : 0

		if (page > 1) {
			yield put({ type: "dashboardSP/paginLoading", payload: true })
		} else {
			yield put({ type: "dashboardSP/toggleLoading", payload: true })
		}

		yield put({ type: "dashboardSP/toggleLoading", payload: true })

		const payload = {
			token,
			xLsT,
			organizationId,
			metric,
			result,
			page,
			credentialFilter,
		}
		const data = yield call(Api.getDepositExhibitionAreaList, payload)

		yield put({ type: "dashboardSP/paginLoading" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardSP/toggleLoading" })

				if (action.payload.fullList) {
					if (data.length === 0 || data.length < 19) {
						yield put({
							type: "dashboardSP/setPagingLimit",
							payload: false,
						})
					} else {
						yield put({
							type: "dashboardSP/setPagingLimit",
							payload: true,
						})
					}

					yield put({
						type: "dashboardSP/setFullList",
						payload: [...fullList, ...data],
					})
				} else if (action.payload.popup) {
					yield put({
						type: "dashboardSP/setFullList",
						payload: data,
					})
				} else {
					yield put({
						type: "dashboardSP/setShowList",
						payload: data,
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

//////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////     highlighted     //////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
export function* getLargeShareDeposit(action) {
	try {
		addLCS(action)

		let dataLocalStorage = JSON.parse(localStorage.getItem("dashboardSP"))
		let today = new Date()
		today.setHours(4)
		today.setMinutes(0)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		const payload = { token, xLsT, organizationId }
		let data
		let isLocal

		if (
			!dataLocalStorage ||
			!dataLocalStorage.largeShareDeposit ||
			dataLocalStorage.callDate === null ||
			new Date(dataLocalStorage.callDate) < today
		) {
			isLocal = false
			data = yield call(Api.getLargeShareDeposit, payload)
		} else {
			isLocal = true
			data = dataLocalStorage.largeShareDeposit
		}

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardSP/toggleLoading" })

				const callDate = new Date()

				if (!isLocal) {
					const payload = {
						callDate,
						key: "largeShareDeposit",
						value: data,
					}

					yield put({ type: "dashboardSP/setLoader", payload })
				} else {
					yield put({
						type: "dashboardSP/setLoader",
						payload: { dataLocalStorage, isLocal: true },
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

export function* getLargeShareDepositList(action) {
	try {
		addLCS(action)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		const fullList = yield select((state) => state.dashboardSP.fullList)

		const metric = action.payload.metric
		const result = action.payload.result ? action.payload.result : 5
		const page = action.payload.page ? action.payload.page : 1
		const mode = action.payload.mode

		if (page > 1) {
			yield put({ type: "dashboardSP/paginLoading", payload: true })
		} else {
			yield put({ type: "dashboardSP/toggleLoading", payload: true })
		}

		yield put({ type: "dashboardSP/toggleLoading", payload: true })

		const payload = {
			token,
			xLsT,
			organizationId,
			metric,
			result,
			page,
			mode,
		}
		const data = yield call(Api.getLargeShareDepositList, payload)

		yield put({ type: "dashboardSP/paginLoading" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardSP/toggleLoading" })

				if (action.payload.fullList) {
					if (data.length === 0 || data.length < 19) {
						yield put({
							type: "dashboardSP/setPagingLimit",
							payload: false,
						})
					} else {
						yield put({
							type: "dashboardSP/setPagingLimit",
							payload: true,
						})
					}

					yield put({
						type: "dashboardSP/setFullList",
						payload: [...fullList, ...data],
					})
				} else if (action.payload.popup) {
					yield put({
						type: "dashboardSP/setFullList",
						payload: data,
					})
				} else {
					yield put({
						type: "dashboardSP/setShowList",
						payload: data,
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

export function* getOldShare(action) {
	try {
		addLCS(action)

		let dataLocalStorage = JSON.parse(localStorage.getItem("dashboardSP"))
		let today = new Date()
		today.setHours(4)
		today.setMinutes(0)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		const payload = { token, xLsT, organizationId }
		let data
		let isLocal

		if (
			!dataLocalStorage ||
			!dataLocalStorage.oldShare ||
			dataLocalStorage.callDate === null ||
			new Date(dataLocalStorage.callDate) < today
		) {
			isLocal = false
			data = yield call(Api.getOldShare, payload)
		} else {
			isLocal = true
			data = dataLocalStorage.oldShare
		}

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardSP/toggleLoading" })

				const callDate = new Date()

				if (!isLocal) {
					const payload = {
						callDate,
						key: "oldShare",
						value: data,
					}

					yield put({ type: "dashboardSP/setLoader", payload })
				} else {
					yield put({
						type: "dashboardSP/setLoader",
						payload: { dataLocalStorage, isLocal: true },
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

export function* getOldShareList(action) {
	try {
		addLCS(action)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		const fullList = yield select((state) => state.dashboardSP.fullList)

		const metric = action.payload.metric
		const result = action.payload.result ? action.payload.result : 5
		const page = action.payload.page ? action.payload.page : 1

		if (page > 1) {
			yield put({ type: "dashboardSP/paginLoading", payload: true })
		} else {
			yield put({ type: "dashboardSP/toggleLoading", payload: true })
		}

		yield put({ type: "dashboardSP/toggleLoading", payload: true })

		const payload = { token, xLsT, organizationId, metric, result, page }
		const data = yield call(Api.getOldShareList, payload)

		yield put({ type: "dashboardSP/paginLoading" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardSP/toggleLoading" })

				if (action.payload.fullList) {
					if (data.length === 0 || data.length < 19) {
						yield put({
							type: "dashboardSP/setPagingLimit",
							payload: false,
						})
					} else {
						yield put({
							type: "dashboardSP/setPagingLimit",
							payload: true,
						})
					}

					yield put({
						type: "dashboardSP/setFullList",
						payload: [...fullList, ...data],
					})
				} else if (action.payload.popup) {
					yield put({
						type: "dashboardSP/setFullList",
						payload: data,
					})
				} else {
					yield put({
						type: "dashboardSP/setShowList",
						payload: data,
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

export function* getOldDeposit(action) {
	try {
		addLCS(action)

		let dataLocalStorage = JSON.parse(localStorage.getItem("dashboardSP"))
		let today = new Date()
		today.setHours(4)
		today.setMinutes(0)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		const payload = { token, xLsT, organizationId }
		let data
		let isLocal

		if (
			!dataLocalStorage ||
			!dataLocalStorage.oldDeposit ||
			dataLocalStorage.callDate === null ||
			new Date(dataLocalStorage.callDate) < today
		) {
			isLocal = false
			data = yield call(Api.getOldDeposit, payload)
		} else {
			isLocal = true
			data = dataLocalStorage.oldDeposit
		}

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardSP/toggleLoading" })

				const callDate = new Date()

				if (!isLocal) {
					const payload = {
						callDate,
						key: "oldDeposit",
						value: data,
					}

					yield put({ type: "dashboardSP/setLoader", payload })
				} else {
					yield put({
						type: "dashboardSP/setLoader",
						payload: { dataLocalStorage, isLocal: true },
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

export function* getOldDepositList(action) {
	try {
		addLCS(action)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		const fullList = yield select((state) => state.dashboardSP.fullList)

		const metric = action.payload.metric
		const result = action.payload.result ? action.payload.result : 5
		const page = action.payload.page ? action.payload.page : 1

		if (page > 1) {
			yield put({ type: "dashboardSP/paginLoading", payload: true })
		} else {
			yield put({ type: "dashboardSP/toggleLoading", payload: true })
		}

		yield put({ type: "dashboardSP/toggleLoading", payload: true })

		const payload = { token, xLsT, organizationId, metric, result, page }
		const data = yield call(Api.getOldDepositList, payload)

		yield put({ type: "dashboardSP/paginLoading" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardSP/toggleLoading" })

				if (action.payload.fullList) {
					if (data.length === 0 || data.length < 19) {
						yield put({
							type: "dashboardSP/setPagingLimit",
							payload: false,
						})
					} else {
						yield put({
							type: "dashboardSP/setPagingLimit",
							payload: true,
						})
					}

					yield put({
						type: "dashboardSP/setFullList",
						payload: [...fullList, ...data],
					})
				} else if (action.payload.popup) {
					yield put({
						type: "dashboardSP/setFullList",
						payload: data,
					})
				} else {
					yield put({
						type: "dashboardSP/setShowList",
						payload: data,
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

//////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////       opening       //////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
export function* getMapDownload(action) {
	try {
		addLCS(action)

		let dataLocalStorage = JSON.parse(localStorage.getItem("dashboardSP"))
		let today = new Date()
		today.setHours(4)
		today.setMinutes(0)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		const payload = { token, xLsT, organizationId, limit: 2500 }
		let data
		let isLocal

		if (backwardCompApi("1.23.11")) {
			isLocal = false

			yield put({
				type: "dashboardSP/mapDownloadsIpsStatus",
				payload: "started",
			})
			yield put({ type: "dashboardSP/toggleLoading" })

			// the spawn function will launch the function without blocking the current saga AND without attaching itself to the current saga
			// this means it will stop only when the function has ended
			yield spawn(getPaginatedMapDownload, { ...action, payload })

			return
		} else {
			if (
				!dataLocalStorage ||
				!dataLocalStorage.mapDownload ||
				dataLocalStorage.callDate === null ||
				new Date(dataLocalStorage.callDate) < today
			) {
				isLocal = false

				data = yield call(Api.getMapDownload, payload)
			} else {
				isLocal = true
				data = dataLocalStorage.mapDownload
			}
		}

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardSP/toggleLoading" })

				const callDate = new Date()

				if (!isLocal) {
					const payload = {
						callDate,
						key: "mapDownload",
						value: data,
					}

					yield put({ type: "dashboardSP/setLoader", payload })
				} else {
					yield put({
						type: "dashboardSP/setLoader",
						payload: { dataLocalStorage, isLocal: true },
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

export function* getRecipientDomain(action) {
	try {
		addLCS(action)

		let dataLocalStorage = JSON.parse(localStorage.getItem("dashboardSP"))
		let today = new Date()
		today.setHours(4)
		today.setMinutes(0)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		const payload = { token, xLsT, organizationId }
		let data
		let isLocal

		if (
			!dataLocalStorage ||
			!dataLocalStorage.recipientDomain ||
			dataLocalStorage.callDate === null ||
			new Date(dataLocalStorage.callDate) < today
		) {
			isLocal = false
			data = yield call(Api.getRecipientDomain, payload)
		} else {
			isLocal = true
			data = dataLocalStorage.recipientDomain
		}

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardSP/toggleLoading" })

				const callDate = new Date()

				if (!isLocal) {
					const payload = {
						callDate,
						key: "recipientDomain",
						value: data,
					}

					yield put({ type: "dashboardSP/setLoader", payload })
				} else {
					yield put({
						type: "dashboardSP/setLoader",
						payload: { dataLocalStorage, isLocal: true },
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

export function* getRecipientDomainList(action) {
	try {
		addLCS(action)

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const auth = yield select((state) => state.auth)
		const organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		const fullList = yield select((state) => state.dashboardSP.fullList)

		const domain = action.payload.domain
		const result = action.payload.result ? action.payload.result : 5
		const page = action.payload.page ? action.payload.page : 1

		if (page > 1) {
			yield put({ type: "dashboardSP/paginLoading", payload: true })
		} else {
			yield put({ type: "dashboardSP/toggleLoading", payload: true })
		}

		yield put({ type: "dashboardSP/toggleLoading", payload: true })

		const payload = { token, xLsT, organizationId, domain, result, page }
		const data = yield call(Api.getRecipientDomainList, payload)

		yield put({ type: "dashboardSP/paginLoading" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardSP/toggleLoading" })

				if (action.payload.fullList) {
					if (data.length === 0 || data.length < 19) {
						yield put({
							type: "dashboardSP/setPagingLimit",
							payload: false,
						})
					} else {
						yield put({
							type: "dashboardSP/setPagingLimit",
							payload: true,
						})
					}

					yield put({
						type: "dashboardSP/setFullList",
						payload: [...fullList, ...data],
					})
				} else if (action.payload.popup) {
					yield put({
						type: "dashboardSP/setFullList",
						payload: data,
					})
				} else {
					yield put({
						type: "dashboardSP/setShowList",
						payload: data,
					})
				}
			}
		}
	} catch (error) {
		console.error(error)
	}
}

// IP PAGINATION FUNCTIONS
function handleRequestsForMapIpsPagination(
	dataArray,
	error,
	index,
	parallelPagesNumber,
	action,
) {
	const data = dataArray
		.map((pageData) => {
			switch (pageData) {
				case "sessionExpired":
					error = "sessionExpired"

					return null
				case "sessionDestroyed":
					error = "sessionDestroyed"

					return null
				case true:
					removeLCS(action)

					error = "notFound"

					return null
				default: {
					removeLCS(action)
				}
			}

			return pageData?._embedded?.items
		})
		?.flat(2)

	if (data.filter((value) => !value)?.length > 0 || !!error) {
		index = parallelPagesNumber
		isCompleted = true

		return []
	}

	return data
}

export function* getPaginatedMapDownload(action) {
	let isCompleted = false
	let offset = 1

	const initialRequestData = yield call(Api.getMapDownloadPagination, {
		...action.payload,
		offset,
	})

	switch (initialRequestData) {
		case "sessionExpired":
			return yield put({ type: "auth/showPadlock" })
		case "sessionDestroyed":
			return yield put({ type: "auth/sessionExpired" })
		case true:
			return removeLCS(action)
		default: {
			removeLCS(action)

			yield put({
				type: "dashboardSP/addNewMapDownloadsIps",
				payload: initialRequestData?._embedded?.items,
			})
		}
	}

	if (
		initialRequestData._embedded.items?.length < initialRequestData.limit ||
		initialRequestData.pages === 1
	) {
		isCompleted = true
	}

	if (!isCompleted) {
		// we divide the total page count by 4 to know how many loops of 4 parallel requests we'll have to do
		// 4 is an arbitrary number to allow parallelisation without getting blocked by the browser
		const parallelPagesNumber = initialRequestData.pages / 4
		// we find the remainder incase the total page count is not absolutely divisable by 4
		const remainderPages = initialRequestData.pages % 4
		let index = 1
		// lastFetchedPage default value is 1 because we made the call for the first page above
		let lastFetchedPage = 1
		let error = null

		while (index <= parallelPagesNumber) {
			const fetchedPages = yield all(
				// we create an array with four values because we want to call 4 pages in parallel
				[1, 2, 3, 4].map((parallelPageNumber) => {
					return call(Api.getMapDownloadPagination, {
						...action.payload,
						offset: lastFetchedPage + parallelPageNumber,
					})
				}),
			)

			const data = handleRequestsForMapIpsPagination(
				fetchedPages,
				error,
				index,
				parallelPagesNumber,
				action,
			)

			if (!error) {
				yield put({
					type: "dashboardSP/addNewMapDownloadsIps",
					payload: data,
				})
			}

			index += 1
			lastFetchedPage += 4

			if (lastFetchedPage * initialRequestData.limit >= 50000) {
				index += parallelPagesNumber
				isCompleted = true
			}
		}

		if (!isCompleted && remainderPages > 0 && !error) {
			let lastPages = remainderPages

			while (
				(lastPages + lastFetchedPage) * initialRequestData?.limit >
				50000
			) {
				lastPages -= 1
			}

			const remainderFetchedPages = yield all(
				// we create an array based on the remainder number to loop on the remaning pages
				Array.fill(lastPages).map((_, parallelPageNumber) => {
					return call(Api.getMapDownloadPagination, {
						...action.payload,
						offset: lastFetchedPage + parallelPageNumber,
					})
				}),
			)

			const remainderData = handleRequestsForMapIpsPagination(
				remainderFetchedPages,
				error,
				index,
				parallelPagesNumber,
				action,
			)

			if (!error) {
				yield put({
					type: "dashboardSP/addNewMapDownloadsIps",
					payload: remainderData,
				})
			}
		}

		if (!!error) {
			switch (error) {
				case "sessionExpired":
					return yield put({ type: "auth/showPadlock" })
				case "sessionDestroyed":
					return yield put({ type: "auth/sessionExpired" })
				case "notFound":
					return removeLCS(action)
				default: {
					removeLCS(action)
				}
			}

			return
		}
	}

	yield put({
		type: "dashboardSP/mapDownloadsIpsStatus",
		payload: "completed",
	})
	yield put({
		type: "DBSP_GET_MAP_DOWNLOADS_IPS_PAGINATION_COMPLETED",
	})
}
// END IP PAGINATION FUNCTIONS

export default function* dashboardSPSagas() {
	yield takeLatest(
		"DBSP_GET_DASHBOARD_MONITORING_USAGE",
		getDashboardMonitoringUsage,
	)
	yield takeLatest(
		"DBSP_GET_DASHBOARD_MONITORING_USAGE_GRAPH",
		getDashboardMonitoringUsageGraph,
	)
	yield takeLatest("DBSP_GET_STORAGE_DATA_SAGA", getStorageData)
	yield takeLatest(
		"DBSP_GET_STORAGE_DATA_PASS_LIST_SAGA",
		getStorageDataPassList,
	)
	yield takeLatest(
		"DBSP_GET_STORAGE_DATA_TRANSFER_LIST_SAGA",
		getStorageDataTransferList,
	)
	yield takeLatest(
		"DBSP_GET_STORAGE_DATA_DEPOSIT_BOX_LIST_SAGA",
		getStorageDataDepositBoxList,
	)
	yield takeLatest(
		"DBSP_GET_STORAGE_DATA_SHARED_SPACE_LIST_SAGA",
		getStorageDataSharedSpaceList,
	)
	yield takeLatest(
		"DBSP_GET_STORAGE_DATA_PERSONAL_SPACE_LIST_SAGA",
		getStorageDataPersonalSpaceList,
	)

	yield takeLatest(
		"DBSP_GET_INTERNAL_EXHIBITION_AREA_SAGA",
		getFolderlExhibitionArea,
	)
	yield takeLatest(
		"DBSP_GET_INTERNAL_EXHIBITION_AREA_LIST_SAGA",
		getFolderlExhibitionAreaList,
	)

	yield takeLatest(
		"DBSP_GET_TRANSFER_EXHIBITION_AREA_SAGA",
		getTransferExhibitionArea,
	)
	yield takeLatest(
		"DBSP_GET_TRANSFER_EXHIBITION_AREA_LIST_SAGA",
		getTransferExhibitionAreaList,
	)

	yield takeLatest(
		"DBSP_GET_DEPOSIT_EXHIBITION_AREA_SAGA",
		getDepositExhibitionArea,
	)
	yield takeLatest(
		"DBSP_GET_DEPOSIT_EXHIBITION_AREA_LIST_SAGA",
		getDepositExhibitionAreaList,
	)

	yield takeLatest("DBSP_GET_SHARE_DEPOSIT_SAGA", getLargeShareDeposit)
	yield takeLatest(
		"DBSP_GET_SHARE_DEPOSIT_LIST_SAGA",
		getLargeShareDepositList,
	)

	yield takeLatest("DBSP_GET_OLD_SHARE_SAGA", getOldShare)
	yield takeLatest("DBSP_GET_OLD_SHARE_LIST_SAGA", getOldShareList)

	yield takeLatest("DBSP_GET_OLD_DEPOSIT_SAGA", getOldDeposit)
	yield takeLatest("DBSP_GET_OLD_DEPOSIT_LIST_SAGA", getOldDepositList)

	yield takeLatest("DBSP_GET_MAP_DOWNLOAD_SAGA", getMapDownload)
	yield takeLatest("DBSP_GET_RECIPIENT_DOMAIN_SAGA", getRecipientDomain)

	yield takeLatest(
		"DBSP_GET_RECIPIENT_DOMAIN_LIST_SAGA",
		getRecipientDomainList,
	)
}
