//////////////////////////////////////////////////////////////////////////////////////////
//
// WhitemarkExt
// Applies inline style to a root div to declare colors to use -> ref in scss variables ground
// Props are only dom childrens
// Contrary to layout/Whitemark.jsx we targetting localStorage as token storage
//
//////////////////////////////////////////////////////////////////////////////////////////

import React from "react"
import PropTypes from "prop-types"

const WhitemarkExt = (props) => {
	let w = localStorage.getItem("token")
		? JSON.parse(localStorage.getItem("token"))?.userSession?.userWhitemark
		: undefined
	let style

	if (w) {
		style = {
			"--ogOrange2": w.colorIntern2 ? `#${w.colorIntern2}` : "#F47532",
			"--ogBlack": w.colorIntern3 ? `#${w.colorIntern3}` : "#181A3A",
			"--ogBlue": w.colorIntern1 ? `#${w.colorIntern1}` : "#39499B",
			"--ogActionBlue": w.colorIntern4 ? `#${w.colorIntern4}` : "#2833B7",
			"--ogButton": w.colorIntern6 ? `#${w.colorIntern6}` : "#F47532",
		}
	} else {
		style = {
			"--ogOrange": "#F47532",
			"--ogBlack": "#181A3A",
			"--ogBlue": "#39499B",
			"--ogActionBlue": "#2833B7",
			"--ogButton": "#F47532",
		}
	}

	return <div style={style}>{props.children}</div>
}

export default WhitemarkExt

WhitemarkExt.propTypes = {
	children: PropTypes.node,
}
