import React, { useEffect, useState, useMemo, useCallback, useRef } from "react"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"
import _ from "lodash"

import LoaderBars from "../../components/animations/LoaderBars"
import Mapping from "../../components/Mapping"

import {
	backwardCompApi,
	copyToClip,
	getIcons,
	getValidUrl,
	utilPPname,
} from "../../actions/generalUtils"

import {
	generatePass,
	capitalize,
	callBackground,
	passRespectPolicy,
	getNameFromDomain2,
} from "../tools/extensionUtils"

import TooltipHelper from "../../stories/tooltip/TooltipHelper"
import { Button } from "../../stories/buttons/Button"

import { NEWPWD, DEFAULTNEWPWD } from "../tools/VariablesName"

import { ReactComponent as Place } from "../../assets/images/place.svg"
import { ReactComponent as Key } from "../../assets/images/key.svg"
import { ReactComponent as Eye } from "../../assets/images/eye.svg"
import { ReactComponent as EyeClosed } from "../../assets/images/eyeClosed.svg"
import { ReactComponent as Reload } from "../../assets/images/reload.svg"
import { ReactComponent as Clone } from "../../assets/images/clone.svg"
import { ReactComponent as ErrorSign } from "../../assets/images/errorSign.svg"
import { ReactComponent as Close } from "../../assets/images/close.svg"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

const CreatePassMemo = React.memo(function CreatePass({
	loadFavIcon,
	activeTabUrl,
	domainLitteralName,
	openedMap,
	toggleMapCB,
	submit,
	useData,
	hideResetBtn,
	newPwd = DEFAULTNEWPWD,
	setNewPwd,
	pwdProps,
	mappingOrigin,
	fetchMappingData,
	isPersoSpaceBlocked,
	optionsLockpassPasswordPolicy,
	...rest
}) {
	const dispatch = useDispatch()
	const navigate =
		mappingOrigin === "extension_followIds" ? null : useNavigate()
	const [t] = useTranslation()
	const location = rest?.location

	const [pp, setPP] = useState()
	const setPPRetrievedOnce = useState(false)[1]
	const [ppIsRespected, setPpIsRespected] = useState(false)

	const [targetCat, setTargetCat] = useState({})
	const [_openedMap, _setOpenMap] = useState(false)

	const [faviconStep, setFaviconStep] = useState(1)
	const setUrl = useState("")[1]
	const [credentialName, setCredentialName] = useState("")
	const [passwordIsShow, setPasswordIsShow] = useState(false)
	const [displayOptionals, setDisplayOptionals] = useState(false)
	const [displayedUrl, setDisplayedUrl] = useState(activeTabUrl)
	const [sensibleData, setSensibleData] = useState({})
	const [showResetBtn, setShowResetBtn] = useState(false)
	const [errorMessageSubmit, setErrorMessageSubmit] = useState(false)
	const [correctPasswordPolicyIsLoaded, setCorrectPasswordPolicyIsLoaded] =
		useState(false)

	const actionLoading = useSelector((state) => state?.action?.loader)
	const userHasAccess = useSelector(
		(state) => state?.extension?.userHasAccess,
	)
	const isLockPassPersoSpaceBlocked = !backwardCompApi("1.22.14")
		? false
		: mappingOrigin === "extension_followIds"
		? isPersoSpaceBlocked
		: useSelector(
				(state) => state.params?.optLockPass?.isPersonalSpaceBlocked,
		  )
	const lockpassOptionsPasswordPolicy = !backwardCompApi("1.22.14")
		? null
		: mappingOrigin === "extension_followIds"
		? optionsLockpassPasswordPolicy
		: useSelector(
				(state) => state?.extension?.lockpassOptionsPasswordPolicy,
		  )

	const _defaultCat = !isLockPassPersoSpaceBlocked
		? {
				name: t("mLayout.personnalSpace"),
				id: 0,
				initVal: true,
		  }
		: {
				name: t("mapping.choiceRequired"),
				id: null,
				initVal: null,
		  }

	const debouncedUrl = useMemo(() => _.debounce(setUrl, 500), [])

	const nameRef = useRef(null)
	const passRef = useRef(null)

	const changeProps = useCallback(
		(key, value, sensible = false) => {
			if (sensible) {
				editObject(key, value, setSensibleData)
			} else {
				editObject(key, value, setNewPwd)
			}
		},
		[setNewPwd],
	)

	useEffect(() => {
		_toggleMap(false)
	}, [])

	useEffect(() => {
		if (!userHasAccess && navigate !== null) {
			navigate("/popup/generate", { replace: true })
		}
	}, [location, userHasAccess])

	// Use active tab url passed from index router
	useEffect(() => {
		if (activeTabUrl) {
			setDisplayedUrl(activeTabUrl)
		}
	}, [activeTabUrl])

	useEffect(() => {
		// console.log("loca", location?.state);
		if (location?.state) {
			if (location.state?.password) {
				changeProps(NEWPWD.PASSWORD, location.state.password, true)
			}

			if (location.state?.pp) {
				setPP(location.state.pp)
			}

			setPPRetrievedOnce(true)
		}
	}, [location, changeProps])

	useEffect(() => {
		if (!backwardCompApi("1.22.14")) {
			setCorrectPasswordPolicyIsLoaded(true)

			return
		}

		const passwordPolicyLoaded = !!lockpassOptionsPasswordPolicy
			? lockpassOptionsPasswordPolicy?.isLoaded
			: true
		const passwordPolicyPersonalOptionsSettings =
			!!lockpassOptionsPasswordPolicy?.personalSpace
				? lockpassOptionsPasswordPolicy?.personalSpace
				: location?.state?.optionsLockpassPasswordPolicy
		const passwordPolicyForced =
			!!lockpassOptionsPasswordPolicy?.personalSpace
				? lockpassOptionsPasswordPolicy?.personalSpace?.isForced
				: location?.state?.optionsLockpassPasswordPolicy?.isForced

		if (
			!!passwordPolicyPersonalOptionsSettings &&
			passwordPolicyForced &&
			(targetCat?.mainId === 0 || targetCat?.id === 0)
		) {
			/* eslint-disable-next-line no-unused-vars */
			const { isForced, ...optionsPasswordPolicy } =
				passwordPolicyPersonalOptionsSettings

			setPP(optionsPasswordPolicy)
		}

		if (passwordPolicyLoaded && !correctPasswordPolicyIsLoaded) {
			setCorrectPasswordPolicyIsLoaded(true)
		}
	}, [location, targetCat, lockpassOptionsPasswordPolicy])

	useEffect(() => {
		if (
			backwardCompApi("1.22.14") &&
			lockpassOptionsPasswordPolicy?.isLoaded &&
			!lockpassOptionsPasswordPolicy?.personalSpace &&
			!!location?.state &&
			"optionsLockpassPasswordPolicy" in location?.state &&
			mappingOrigin !== "extension_followIds"
		) {
			/* eslint-disable-next-line no-unused-vars */
			const { optionsLockpassPasswordPolicyData, ...locationState } =
				location?.state

			navigate(location.pathname, { replace: true, state: locationState })
		}
	}, [lockpassOptionsPasswordPolicy, location])

	// Set default category target
	useEffect(() => {
		if (
			(!backwardCompApi("1.22.14") && newPwd[NEWPWD.CAT]) ||
			(backwardCompApi("1.22.14") &&
				newPwd[NEWPWD.CAT] &&
				((isLockPassPersoSpaceBlocked &&
					newPwd[NEWPWD.CAT]?.id !== 0 &&
					newPwd[NEWPWD.CAT]?.mainId !== 0) ||
					!isLockPassPersoSpaceBlocked))
		) {
			setTargetCat(newPwd[NEWPWD.CAT])
		} else {
			if (JSON.stringify(targetCat) !== JSON.stringify(_defaultCat)) {
				setTargetCat(_defaultCat)
			}
		}
	}, [newPwd])

	useEffect(() => {
		if (sensibleData[NEWPWD.PASSWORD]?.length) {
			if (pp) {
				setPpIsRespected(
					passRespectPolicy(sensibleData[NEWPWD.PASSWORD], pp),
				)
			} else {
				setPpIsRespected(true)
			}
		} else {
			setPpIsRespected(pp ? false : true)
		}
	}, [sensibleData, pp])

	useEffect(() => {
		const DEFAULTVALUES = { [NEWPWD.URL]: activeTabUrl }

		if (
			checkIfObjectIsEmpty(newPwd, DEFAULTVALUES) &&
			checkIfObjectIsEmpty(sensibleData)
		) {
			setShowResetBtn(false)
		} else {
			setShowResetBtn(true)
		}

		if (
			newPwd?.[NEWPWD.NAME]?.length > 0 &&
			sensibleData?.[NEWPWD.PASSWORD]?.length > 0
		) {
			setErrorMessageSubmit(false)
		}
	}, [newPwd, sensibleData, activeTabUrl])

	useEffect(() => {
		if (!pwdProps) return
		changeProps(NEWPWD.PASSWORD, pwdProps, true)
	}, [pwdProps, changeProps])

	useEffect(() => {
		if (typeof targetCat?.id !== "undefined") {
			// setPP()

			if (JSON.stringify(targetCat) !== JSON.stringify(_defaultCat)) {
				changeProps(NEWPWD.CAT, targetCat)
			}

			if (targetCat?.passwordPolicyId) {
				setPPRetrievedOnce(false)
				loadPasswordPolicy()
			} else {
				if (
					backwardCompApi("1.22.14") &&
					!!lockpassOptionsPasswordPolicy?.personalSpace &&
					lockpassOptionsPasswordPolicy?.personalSpace?.isForced &&
					(targetCat?.mainId === 0 || targetCat?.id === 0)
				) {
					/* eslint-disable-next-line no-unused-vars */
					const { isForced, ...passwordPolicy } =
						lockpassOptionsPasswordPolicy?.personalSpace

					setPP(passwordPolicy)
				} else {
					setPP({})
					setPpIsRespected(true)
				}
			}
		}
	}, [targetCat, changeProps])

	useEffect(() => {
		if (
			backwardCompApi("1.22.14") &&
			!lockpassOptionsPasswordPolicy?.personalSpace &&
			mappingOrigin !== "extension_followIds"
		) {
			dispatch({ type: "POPUP_GET_USER_LOCKPASS_OPTIONS_SAGA" })
		}
	}, [])

	useEffect(() => {
		if (newPwd[NEWPWD.URL]) {
			setDisplayedUrl(newPwd[NEWPWD.URL])
		}
	}, [activeTabUrl, newPwd])

	useEffect(() => {
		if (activeTabUrl === displayedUrl) {
			changeProps(NEWPWD.URL, undefined)
		} else {
			changeProps(NEWPWD.URL, displayedUrl)
		}
	}, [activeTabUrl, displayedUrl, changeProps])

	// If we have url state but no name -> create default one
	useEffect(() => {
		if (!credentialName?.length && !useData?.domain?.length) {
			if (domainLitteralName?.length) {
				setCredentialName(domainLitteralName)
			} else if (displayedUrl?.length) {
				defaultCredentialName(displayedUrl)
			} else if (activeTabUrl?.length) {
				defaultCredentialName(activeTabUrl)
			}
		}
	}, [
		displayedUrl,
		activeTabUrl,
		credentialName?.length,
		useData?.domain?.length,
	])

	useEffect(() => {
		if (typeof openedMap === "boolean" && _openedMap !== openedMap)
			_setOpenMap(openedMap)
	}, [openedMap, _openedMap])

	function checkIfObjectIsEmpty(object, except = {}) {
		let result = true
		let objectCopy = JSON.parse(JSON.stringify(object))
		for (const [keyExcept, valueExcept] of Object.entries(except)) {
			for (const [key1, value1] of Object.entries(objectCopy)) {
				if (keyExcept === key1 && value1 === valueExcept) {
					objectCopy[key1] = ""
				}
			}
		}
		for (const item of Object.entries(objectCopy)) {
			if (item[1] !== "") {
				result = false
			}
		}
		return result
	}

	function _toggleMap(newVal) {
		// Utility CallBack | Used in FormFrame in followIds to hide popup title and maxx width when map is opened
		if (toggleMapCB) toggleMapCB(newVal)

		return _setOpenMap(newVal)
	}

	function loadPasswordPolicy() {
		function cb(pp) {
			setPP(pp)
			return setPPRetrievedOnce(true)
		}

		return callBackground(
			{ message: "LSgetCatPP", id: targetCat.passwordPolicyId },
			cb,
		)
	}

	function defaultCredentialName(_displayedUrl) {
		return setCredentialName(capitalize(getNameFromDomain2(_displayedUrl)))
	}

	// Hide/show the password if the user click on the show/add password
	const handleDisplayClick = (e) => {
		e.preventDefault()
		setPasswordIsShow(!passwordIsShow)
	}

	// Generate a random password if the user click on generate password
	const handleGenerateClick = async (event) => {
		event.preventDefault()

		const newPassword = await generatePass(pp)

		return changeProps(NEWPWD.PASSWORD, newPassword, true)
	}

	// Create the password when clicking on the submit button
	const handleSubmit = (event) => {
		event.preventDefault()

		if (!ppIsRespected || newPwd[NEWPWD.NAME]?.length <= 0) {
			setErrorMessageSubmit(true)

			if (!newPwd[NEWPWD.NAME]) {
				if (!nameRef.current) return
				nameRef.current.focus()
			} else if (!sensibleData[NEWPWD.PASSWORD]) {
				if (!passRef.current) return
				passRef.current.focus()
			}
			return
		}

		// Define the mandatory keys in the payload. For now, category will be set exclusively on Personal space.
		const payload = {
			name: newPwd[NEWPWD.NAME] ? newPwd[NEWPWD.NAME] : credentialName,
			domain: newPwd[NEWPWD.URL] ? newPwd[NEWPWD.URL] : activeTabUrl,
			categoryId: targetCat.id,
			username: newPwd[NEWPWD.USERNAME] ? newPwd[NEWPWD.USERNAME] : "",
			password: sensibleData[NEWPWD.PASSWORD],
			totp: newPwd[NEWPWD.TOTP] ? newPwd[NEWPWD.TOTP] : "",
			isAlert: false,
			description: newPwd[NEWPWD.DESCRIPTION]
				? newPwd[NEWPWD.DESCRIPTION]
				: "",
			opt1: sensibleData[NEWPWD.OPT1] ? sensibleData[NEWPWD.OPT1] : "",
			opt2: sensibleData[NEWPWD.OPT2] ? sensibleData[NEWPWD.OPT2] : "",
			opt3: sensibleData[NEWPWD.OPT3] ? sensibleData[NEWPWD.OPT3] : "",
			type: "",
		}

		resetVals()
		return submit(payload)
	}

	const handleDisplayUrl = (event) => {
		event.preventDefault()
		setDisplayedUrl(event.target.value)
		debouncedUrl(event.target.value)
	}

	function resetVals() {
		clearObjectValues(sensibleData, setSensibleData)
		clearObjectValues(newPwd, setNewPwd)
		setCredentialName("")
		setDisplayedUrl(activeTabUrl)
		setPasswordIsShow(false)
		setTargetCat(_defaultCat)
		setPP()
		setPPRetrievedOnce(false)

		if (activeTabUrl?.length) {
			debouncedUrl(activeTabUrl)
			defaultCredentialName(activeTabUrl)
		}
	}

	function clearObjectValues(object, setObj) {
		for (const [key] of Object.entries(object)) {
			editObject(key, "", setObj)
		}
	}

	const fieldsWithMaxLength = [
		"username",
		"password",
		"description",
		"opt1",
		"opt2",
		"opt3",
	]

	function onChangeSensible(e) {
		const key = e.target?.dataset.field
		const value = e.target.value

		if (fieldsWithMaxLength.includes(key) && value?.length > 200) return

		return editObject(key, value, setSensibleData)
	}

	function onChangeField(e) {
		const key = e.target?.dataset.field
		const value = e.target.value

		if (fieldsWithMaxLength.includes(key) && value?.length > 200) return

		return editObject(key, value, setNewPwd)
	}

	function editObject(key, value, func) {
		func((prev) => ({
			...prev,
			[key]: value,
		}))
	}

	function keyPressed(e) {
		if (e.key === "Enter") {
			e.preventDefault()
			handleSubmit(e)
		}
		return
	}

	function renderForm() {
		if (location) location.state = { ...location.state, catmap: false }

		return (
			<>
				<div className="formContainer">
					{showResetBtn && !hideResetBtn ? (
						<div className="resetVals">
							<div
								className="resetVals-action"
								onClick={() => resetVals()}
							>
								<Close />
								<span>{t("popup.create.reset")}</span>
							</div>
						</div>
					) : null}

					{/*================ URL FIELD ================*/}
					<div className="inputAndLabel">
						<label>{t("popup.create.url")}</label>
						<div className="inputContainer favIcon">
							{loadFavIcon !== false &&
								getIcons(
									{
										name: credentialName,
										domain: displayedUrl,
									},
									getValidUrl,
									faviconStep,
									setFaviconStep,
								)}
							<input
								type="text"
								data-lockself="lockself"
								placeholder="https://"
								value={displayedUrl}
								data-field={NEWPWD.URL}
								onChange={handleDisplayUrl}
								onKeyDown={keyPressed}
							/>
						</div>
					</div>

					{/*================ CATEGORY SELECT ================*/}
					<div className="inputAndLabel">
						<label>{t("popup.create.location")}</label>

						<div
							className="fileLine clickable"
							onClick={() => _toggleMap(true)}
						>
							<div className="fileLine-content">
								<Place className="orange" />
								<p className="fileLine-content-title">
									{t(targetCat?.name)}
								</p>
							</div>
							<span className="fileLine-action">{t("edit")}</span>
						</div>
					</div>

					{/* ================ MAIN INPUTS ================ */}
					<div className="inputAndLabel">
						<label>
							{t("popup.create.name")}
							<TooltipHelper
								isInExtension
								hasIcon
								rightPos
								content={t("popup.create.mandatoryText")}
							>
								*
							</TooltipHelper>
						</label>

						<input
							type="text"
							data-lockself="lockself"
							placeholder={t("popup.create.name")}
							value={newPwd[NEWPWD.NAME]}
							data-field={NEWPWD.NAME}
							onChange={onChangeField}
							ref={nameRef}
							onKeyDown={keyPressed}
						/>
					</div>

					<div className="inputAndLabel">
						<label>{t("popup.create.login")}</label>

						<div className="inputContainer">
							<div className="withButtonsInput">
								<input
									type="text"
									data-lockself="lockself"
									placeholder={t("popup.create.login")}
									value={newPwd[NEWPWD.USERNAME]}
									data-field={NEWPWD.USERNAME}
									onChange={onChangeField}
									onKeyDown={keyPressed}
								/>
								<div className="inputGradient"></div>
							</div>

							<div className="buttonsContainer">
								<button
									onClick={(event) => {
										event.preventDefault()
										return copyToClip(
											newPwd[NEWPWD.USERNAME],
											"popup.clipboard.copyLogin",
											true,
											"popup.clipboard.copyLoginTitle",
										)
									}}
								>
									<Clone />
								</button>
							</div>
						</div>
					</div>

					<div className="inputAndLabel">
						<label>
							{t("popup.create.password") +
								(pp?.id ? ` (${utilPPname(pp, t, true)})` : "")}
							<TooltipHelper
								isInExtension
								hasIcon
								rightPos
								content={t("popup.create.mandatoryText")}
							>
								*
							</TooltipHelper>
						</label>
						<div className="inputContainer">
							<div className="withButtonsInput">
								<input
									data-lockself="lockself"
									type={passwordIsShow ? "text" : "password"}
									placeholder={t("popup.create.password")}
									value={sensibleData[NEWPWD.PASSWORD] ?? ""}
									data-field={NEWPWD.PASSWORD}
									onChange={onChangeSensible}
									ref={passRef}
									onKeyDown={keyPressed}
								/>
								<div className="inputGradient"></div>
							</div>
							<div className="buttonsContainer">
								<button onClick={handleDisplayClick}>
									{passwordIsShow ? <Eye /> : <EyeClosed />}
								</button>

								<button onClick={handleGenerateClick}>
									<Reload />
								</button>

								<button
									onClick={(event) => {
										event.preventDefault()
										return copyToClip(
											sensibleData[NEWPWD.PASSWORD],
											"popup.clipboard.copyPassword",
											true,
											"popup.clipboard.copyPasswordTitle",
										)
									}}
								>
									<Clone />
								</button>
							</div>
						</div>

						{!ppIsRespected &&
							sensibleData[NEWPWD.PASSWORD]?.length > 0 && (
								<div className="errorContainer">
									<ErrorSign />
									<b>{t("popup.passDoNotRespectPolicy")}</b>
								</div>
							)}
					</div>

					<div className="inputAndLabel">
						<label>{t("popup.create.totp")}</label>

						<div className="inputContainer">
							<div className="withButtonsInput">
								<input
									type="text"
									data-lockself="lockself"
									placeholder={t("pass.aPass.otpPlaceholder")}
									value={newPwd[NEWPWD.TOTP]}
									data-field={NEWPWD.TOTP}
									onChange={onChangeField}
									onKeyDown={keyPressed}
								/>
								<div className="inputGradient"></div>
							</div>
						</div>
					</div>

					{/* ================ OPTIONAL INPUTS ================ */}
					{displayOptionals && (
						<div className="optionalForm">
							<div className="inputAndLabel">
								<label>{t("popup.create.description")}</label>
								<input
									data-lockself="lockself"
									type="text"
									placeholder="Description"
									value={newPwd[NEWPWD.DESCRIPTION]}
									data-field={NEWPWD.DESCRIPTION}
									onChange={onChangeField}
									onKeyDown={keyPressed}
								/>
							</div>

							<div className="inputAndLabel">
								<label>{t("popup.create.opt1")}</label>
								<input
									data-lockself="lockself"
									type="text"
									placeholder={t("popup.create.opt1")}
									value={sensibleData[NEWPWD.OPT1]}
									data-field={NEWPWD.OPT1}
									onChange={onChangeSensible}
									onKeyDown={keyPressed}
								/>
							</div>

							<div className="inputAndLabel">
								<label>{t("popup.create.opt2")}</label>
								<input
									data-lockself="lockself"
									type="text"
									placeholder={t("popup.create.opt2")}
									value={sensibleData[NEWPWD.OPT2]}
									data-field={NEWPWD.OPT2}
									onChange={onChangeSensible}
									onKeyDown={keyPressed}
								/>
							</div>

							<div className="inputAndLabel">
								<label>{t("popup.create.opt3")}</label>
								<input
									data-lockself="lockself"
									type="text"
									placeholder={t("popup.create.opt3")}
									value={sensibleData[NEWPWD.OPT3]}
									data-field={NEWPWD.OPT3}
									onChange={onChangeSensible}
									onKeyDown={keyPressed}
								/>
							</div>
						</div>
					)}
				</div>

				{/* ================ BUTTONS ================ */}
				<div className="showDetailsContainer">
					<div
						className="showDetailsContainer-action"
						onClick={() => setDisplayOptionals(!displayOptionals)}
					>
						{displayOptionals
							? t("popup.create.hideDescription")
							: t("popup.create.displayDescription")}
					</div>
				</div>

				{errorMessageSubmit && (
					<div className="errorContainer">
						<ErrorSign />
						<p>{t("popup.create.emptyFields")}</p>
					</div>
				)}

				<div className="buttons createPassBut">
					<Button
						onClick={handleSubmit}
						type="submit"
						disabled={
							!ppIsRespected ||
							newPwd[NEWPWD.NAME]?.length <= 0 ||
							targetCat?.id === null
						}
						Icon={<Key className="icon" />}
						label={t("add")}
					/>
				</div>
			</>
		)
	}

	function renderMap() {
		if (!!actionLoading) {
			return (
				<div className="loaderBarsFrame">
					<LoaderBars />
				</div>
			)
		}

		if (location) {
			location.state = { ...location.state, catmap: true }
		}

		return (
			<Mapping
				label={t("pass.aPass.parentRepHelp")}
				target={targetCat}
				setTarget={setTargetCat}
				actionBtnLabel={t("pass.catsMap.confirmCat")}
				secondBtnLabel={t("pass.catsMap.cancel")}
				close={() => _toggleMap(false)}
				type="addPass"
				product="pass"
				origin={mappingOrigin}
				hasSecondBtn
				isInExtension
				hasSingleRow={!fetchMappingData}
				fetchMappingDataFromExtension={fetchMappingData}
				isPersoSpaceBlocked={isPersoSpaceBlocked}
			/>
		)
	}

	return (
		<div
			id="CreatePass"
			className={`popupPage ${!!actionLoading ? "centerChilds" : ""}
				${fetchMappingData ? " hasInitialMappingButtonsStyle" : " mapDisplayStyle"}
			`}
		>
			{/* {!noHeader && <PopupHeader showGoBack={true} title={t("popup.create.title")}/>} */}

			{!correctPasswordPolicyIsLoaded && <LoaderBars />}

			{correctPasswordPolicyIsLoaded && (
				<form className={_openedMap ? "mapForm" : ""}>
					{_openedMap ? renderMap() : renderForm()}
				</form>
			)}
		</div>
	)
})

export default CreatePassMemo

CreatePassMemo.defaultProps = {
	mappingOrigin: "extension_popup",
	fetchMappingData: false,
	isPersoSpaceBlocked: false,
	optionsLockpassPasswordPolicy: null,
}

CreatePassMemo.propTypes = {
	loadFavIcon: PropTypes.bool,
	activeTabUrl: PropTypes.string,
	domainLitteralName: PropTypes.string,
	openedMap: PropTypes.any,
	toggleMapCB: PropTypes.func,
	submit: PropTypes.func,
	useData: PropTypes.object,
	hideResetBtn: PropTypes.bool,
	newPwd: PropTypes.object,
	setNewPwd: PropTypes.func,
	pwdProps: PropTypes.any,
	mappingOrigin: PropTypes.string,
	fetchMappingData: PropTypes.bool,
	isPersoSpaceBlocked: PropTypes.bool,
	optionsLockpassPasswordPolicy: PropTypes.object,
}
