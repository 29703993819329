import { combineReducers } from "redux"

import { reducer as toastrReducer } from "react-redux-toastr"

import accountOptionsReducer from "./actions/accountOptions/slice"
import actionReducer from "./actions/slice"
import authReducer from "./pages/login/slice"
import transferReducer from "./pages/transfer/slice"
import passReducer from "./pages/pass/slice"
import filesReducer from "./pages/files/slice"
import manageReducer from "./pages/manage/slice"
import paramsReducer from "./pages/params/slice"
import licenseReducer from "./license/slice"
import extensionReducer from "./popup/slice"
import dashboardLPSlice from "./pages/dashboard/products/pass/slice"
import dashboardSPSlice from "./pages/dashboard/products/sharedStorage/slice"
import dasboardMNGSlice from "./pages/dashboard/products/management/slice"
import dashboardLogsSlice from "./pages/dashboard/products/logs/slice"
import dashboardOverViewSlice from "./pages/dashboard/products/overView/slice"

export default combineReducers({
	accountOptions: accountOptionsReducer,
	auth: authReducer,
	action: actionReducer,
	transfer: transferReducer,
	pass: passReducer,
	files: filesReducer,
	toastr: toastrReducer,
	manage: manageReducer,
	params: paramsReducer,
	license: licenseReducer,
	extension: extensionReducer,
	dashboardLP: dashboardLPSlice,
	dashboardSP: dashboardSPSlice,
	dashboardMNG: dasboardMNGSlice,
	dashboardLogs: dashboardLogsSlice,
	dashboardOverView: dashboardOverViewSlice,
})
