import { createSlice } from "@reduxjs/toolkit"

const base = {
	callDate: "",
	folderExhiArea: null,
	dashboardMonitoring: null,
	dashboardMonitoringGraph: null,
	transferExhiArea: null,
	depositExhiArea: null,
	largeShareDeposit: null,
	oldShare: null,
	oldDeposit: null,
	storageData: null,
	mapDownload: null,
	recipientDomain: null,
}

const dashboardSPSlice = createSlice({
	name: "dashboardSP",
	initialState: {
		productLoading: true,
		loading: true,
		data: null,
		openList: null,
		infoSelect: [],
		valueSelect: null,
		showList: [],
		fullList: [],
		layoutNavIndex: 0,
		boardNavIndex: 0,
		selectIndex: null,
		selectDataComponentUse: [],
		oldDataComponantUse: [],
		selectMetricComponent: null,
		selectSagaList: [],
		paginLoading: false,
		storageListInfo: null,
		domainActive: null,
		pagingLimit: true,
		popupType: null,
		mapDownloadsIps: [],
		mapDownloadsIpsStatus: "pending",
	},
	reducers: {
		init: (state) => {
			state.data = base
			state.loading = false
			state.productLoading = true
			state.openList = null
			state.infoSelect = []
			state.layoutNavIndex = 0
			state.boardNavIndex = 0
			state.showList = []
			state.fullList = []
			state.selectDataComponentUse = []
			state.oldDataComponantUse = []
			state.selectMetricComponent = null
			state.selectSagaList = []
			state.paginLoading = false
			state.valueSelect = null
			state.selectIndex = null
			state.storageListInfo = null
			state.domainActive = null
			state.pagingLimit = true
		},
		setLoader: (state, action) => {
			const rightPayload = [
				"callDate",
				"dashboardMonitoring",
				"dashboardMonitoringGraph",
				"folderExhiArea",
				"transferExhiArea",
				"depositExhiArea",
				"largeShareDeposit",
				"oldShare",
				"oldDeposit",
				"storageData",
				"mapDownload",
				"recipientDomain",
			]
			let payloadIsOk = false

			if (action.payload?.isLocal) {
				if (action.payload?.dataLocalStorage) {
					Object.keys(action.payload?.dataLocalStorage).forEach(
						(item) => {
							const itemIsInRightPayload = rightPayload.find(
								(searchedItem) => searchedItem === item,
							)

							if (itemIsInRightPayload) payloadIsOk = true
						},
					)
				}

				if (!action.payload) state.data = null
				else if (payloadIsOk) {
					state.data = {
						...state.data,
						...action.payload?.dataLocalStorage,
					}

					localStorage.setItem(
						"dashboardSP",
						JSON.stringify(state.data),
					)
				}
			} else {
				if (action.payload) {
					if (rightPayload.includes(action.payload.key))
						payloadIsOk = true
				}

				if (!action.payload) state.data = null
				else if (payloadIsOk) {
					state.data.callDate = action.payload?.callDate
					state.data[action.payload?.key] = action.payload?.value

					localStorage.setItem(
						"dashboardSP",
						JSON.stringify(state.data),
					)
				}
			}
		},
		setProductLoading: (state, action) => {
			if (action.payload) state.productLoading = action.payload
			else state.productLoading = false
		},
		toggleLoading: (state, action) => {
			if (!action.payload) state.loading = false
			else state.loading = action.payload
		},
		paginLoading: (state, action) => {
			if (!action.payload) state.paginLoading = false
			else state.paginLoading = action.payload
		},
		setLAR: (state, action) => {
			if (!action.payload) state.lastActionResult = null
			else state.lastActionResult = action.payload
		},
		setOpenList: (state, action) => {
			if (!action.payload) state.openList = null
			else state.openList = action.payload
		},
		setInfoSelect: (state, action) => {
			if (!action.payload) state.infoSelect = []
			else state.infoSelect = action.payload
		},
		setValueSelect: (state, action) => {
			if (!action.payload) state.valueSelect = null
			else state.valueSelect = action.payload
		},
		setLayoutNavIndex: (state, action) => {
			if (!action.payload) state.layoutNavIndex = 0
			else state.layoutNavIndex = action.payload
		},
		setBoardNavIndex: (state, action) => {
			if (!action.payload) state.boardNavIndex = 0
			else state.boardNavIndex = action.payload
		},
		setShowList: (state, action) => {
			if (!action.payload) state.showList = []
			else state.showList = action.payload
		},
		setFullList: (state, action) => {
			if (!action.payload) state.fullList = []
			else state.fullList = action.payload
		},
		setSelectDataComponentUse: (state, action) => {
			if (!action.payload) state.selectDataComponentUse = []
			else state.selectDataComponentUse = action.payload
		},
		setOldDataComponantUse: (state, action) => {
			state.oldDataComponantUse = action.payload
		},
		setSelectMetricComponent: (state, action) => {
			if (!action.payload && action.payload !== 0) {
				state.selectMetricComponent = null
			} else state.selectMetricComponent = action.payload
		},
		setSelectSagaList: (state, action) => {
			if (!action.payload) state.selectSagaList = []
			else state.selectSagaList = action.payload
		},
		setSelectIndex: (state, action) => {
			if (!action.payload && action.payload !== 0)
				state.selectIndex = null
			else state.selectIndex = action.payload
		},
		setStorageListInfo: (state, action) => {
			if (!action.payload) state.storageListInfo = null
			else state.storageListInfo = action.payload[0]
		},
		setDomainActive: (state, action) => {
			if (!action.payload) state.domainActive = null
			else state.domainActive = action.payload
		},
		setPagingLimit: (state, action) => {
			state.pagingLimit = action.payload
		},
		setPopupType: (state, action) => {
			state.popupType = action.payload
		},
		addNewMapDownloadsIps: (state, action) => {
			state.mapDownloadsIps = action.payload
				? [...state.mapDownloadsIps, ...action.payload]
				: []
		},
		setMapDownloadsIpsStatus: (state, action) => {
			state.mapDownloadsIpsStatus =
				typeof action.payload === "string" ? action.payload : "pending"
		},
	},
})

export const { init } = dashboardSPSlice.actions

export default dashboardSPSlice.reducer
