import React from "react"
import { call, put, takeLeading, select } from "redux-saga/effects"
import * as Api from "./services"
import { toastr } from "react-redux-toastr"
import { addLCS, removeLCS, rebuildBase64 } from "./generalUtils"
import I18n from "../i18n"

export function* getSmsQuota(action) {
	try {
		//console.log(action)
		yield put({ type: "actions/setLAR" })
		addLCS(action)

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT }

		const data = yield call(Api.getSmsQuota, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				//console.log(data)
				return yield put({
					type: "action/setLAR",
					payload: { message: "quotaRetrieved", data },
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getMeta(action) {
	try {
		//console.log(action)
		yield put({ type: "actions/setLAR" })
		addLCS(action)

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT }

		const data = yield call(Api.getMeta, payload)
		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				//console.log(data)
				return yield put({
					type: "action/setLAR",
					payload: { message: "getMeta", data },
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getSsoCheck(action) {
	try {
		yield put({ type: "actions/setLAR" })
		addLCS(action)

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, ...action.payload }

		yield put({ type: "params/setActionLoading", payload: true })
		const data = yield call(Api.getSsoCheck, payload)
		yield put({ type: "params/setActionLoading" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				removeLCS(action)
				return yield put({
					type: "action/setLAR",
					payload: { message: "ssoCheckError" },
				})
			default: {
				removeLCS(action)
				//console.log(data)
				return yield put({
					type: "action/setLAR",
					payload: { message: "getSsoCheck", data },
				})
			}
		}
	} catch (error) {
		//console.log(error)
		yield put({ type: "params/setActionLoading" })
		return yield put({
			type: "action/ssoCheckError",
		})
	}
}

export function* delSso(action) {
	try {
		//console.log(action, "action");
		addLCS(action)
		yield put({ type: "params/setLAR" })
		yield put({ type: "action/setActionLoader", payload: true })

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT }

		const data = yield call(Api.delSso, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				toastr.success(I18n.t("params.pp.removeSuccess"))
				return yield put({
					type: "params/setLAR",
					payload: { message: "successDelSso" },
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getSso(action) {
	try {
		addLCS(action)
		yield put({ type: "params/setLAR" })
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, ...action.payload }

		const data = yield call(Api.getSso, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				//toastr.success(I18n.t("params.pp.removeSuccess"))
				return yield put({
					type: "params/setLAR",
					payload: { message: "successGetSso", data },
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* ssoUrl(action) {
	try {
		yield put({ type: "actions/setLAR" })
		addLCS(action)

		let xLsT = yield select((state) => state.auth.xLsToken)

		let payload = {
			xLsT,
			xmlMetadata: action.payload.url,
			onlyGroups: action.payload.groupADBlockConnetion,
		}

		yield put({
			type: "action/setActionLoader",
			payload: {
				transparency: true,
			},
		})
		const data = yield call(Api.ssoUrl, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				//console.log(data)
				toastr.success(I18n.t("params.iSSO.importDone"))
				return yield put({
					type: "action/setLAR",
					payload: { message: "urlPost", data },
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* ssoXml(action) {
	try {
		// console.log(action,"action");
		yield put({ type: "actions/setLAR" })
		addLCS(action)
		let xmlFile

		if (action.payload.url) {
			xmlFile = yield call(Api.getExternalFile, action.payload.url)
		} else {
			xmlFile = action.payload.file
		}

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = {
			token,
			xLsT,
			file: xmlFile,
			onlyGroups: action.payload.groupADBlockConnetion,
		}

		yield put({
			type: "action/setActionLoader",
			payload: {
				transparency: true,
			},
		})
		const data = yield call(Api.ssoXml, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				//console.log(data)
				toastr.success(I18n.t("params.iSSO.importDone"))
				return yield put({
					type: "action/setLAR",
					payload: { message: "xmlPost" },
				})
			}
		}
	} catch (error) {
		yield put({ type: "params/setActionLoading" })
		// console.log("error in ssoXml saga", error)
	}
}

export function* getInternalWhitemark(action) {
	try {
		//console.log(action)
		yield put({ type: "actions/setLAR" })
		addLCS(action)

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT }

		const data = yield call(Api.getInternalWhitemark, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				if (data)
					yield put({
						type: "action/setLAR",
						payload: { message: "whitemark", data },
					})

				return
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* changeColorWm(action) {
	try {
		yield put({ type: "actions/setLAR" })
		addLCS(action)

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let auth = yield select((state) => state.auth)
		let organizationId = yield select(
			(state) =>
				state.auth.token.userSession.organizationIds[auth.selectedOrg],
		)
		let whitemarks = yield select(
			(state) => state.auth.token.userSession.userWhitemark,
		)
		let payload = {
			token,
			xLsT,
			data: { ...whitemarks, ...action.payload.data, organizationId },
		}

		const data = yield call(Api.changeColorWm, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true: {
				removeLCS(action)
				// Since the loader isn't managed by redux store, but directly with the whitemark popup, we need to
				// set the lar to allow the component to stop the loader
				//UWU
				toastr.error("", "", {
					component: (
						<div
							dangerouslySetInnerHTML={{
								__html: `<b>${I18n.t(
									"params.whitemark.toastr.internalFailed",
								)}</b>`,
							}}
						/>
					),
				})

				return yield put({
					type: "action/setLAR",
					payload: { message: "WmPosted" },
				})
			}
			default: {
				const currentToken = JSON.parse(localStorage.getItem("token"))
				const newToken = { ...currentToken }

				// The images send by the backend are in base64 format and have been truncated. Before storing them,
				// put back the beginning of the base64.
				const newWhitemark = {
					...data,
					logoUrl: rebuildBase64(data.logoUrl, "image", "svg+xml"),
				}

				newToken.userSession.userWhitemark = {
					...newToken.userSession.userWhitemark,
					...newWhitemark,
				}
				localStorage.setItem("token", JSON.stringify(newToken))

				removeLCS(action)
				toastr.warning("", "", {
					component: (
						<div
							dangerouslySetInnerHTML={{
								__html: `<b>${I18n.t(
									"params.whitemark.toastr.internalSuccess",
								)}</b>`,
							}}
						/>
					),
				})
				yield put({
					type: "auth/updateWm",
					payload: { internalWhitemarkChanged: true },
				})

				return yield put({
					type: "action/setLAR",
					payload: { message: "WmPosted" },
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* changeColorWmExternal(action) {
	try {
		yield put({ type: "actions/setLAR" })
		addLCS(action)

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let auth = yield select((state) => state.auth)
		let organizationId = yield select(
			(state) =>
				state.auth.token.userSession.organizationIds[auth.selectedOrg],
		)
		let whitemarks = yield select(
			(state) => state.auth.token.userSession.userWhitemark,
		)
		let payload = {
			token,
			xLsT,
			data: { ...whitemarks, ...action.payload.data, organizationId },
		}

		const data = yield call(Api.changeColorWmExternal, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true: {
				removeLCS(action)

				// Since the loader isn't managed by redux store, but directly with the whitemark popup, we need to
				// set the lar to allow the component to stop the loader
				toastr.error("", "", {
					component: (
						<div
							dangerouslySetInnerHTML={{
								__html: `<b>${I18n.t(
									"params.whitemark.toastr.externalFailed",
								)}</b>`,
							}}
						/>
					),
				})

				return yield put({
					type: "action/setLAR",
					payload: { message: "ExternalWmPosted" },
				})
			}
			default: {
				const currentToken = JSON.parse(localStorage.getItem("token"))
				const newToken = { ...currentToken }

				// The images send by the backend are in base64 format and have been truncated. Before storing them,
				// put back the beginning of the base64.
				const newWhitemark = {
					...data,
					favicon: rebuildBase64(data.favicon, "image", "svg+xml"),
					banner: rebuildBase64(data.banner, "image", "png"),
					background: rebuildBase64(data.background, "image", "png"),
				}

				newToken.userSession.userWhitemark = {
					...newToken.userSession.userWhitemark,
					...newWhitemark,
				}
				localStorage.setItem("token", JSON.stringify(newToken))

				removeLCS(action)
				toastr.success(
					I18n.t("params.whitemark.toastr.externalSuccess"),
				)
				return yield put({
					type: "action/setLAR",
					payload: { message: "ExternalWmPosted" },
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* updateUserConnexionNumber(action) {
	try {
		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, connexionNumber: action.payload }

		yield put({
			type: "auth/updateUserConnexionNumber",
			payload: action.payload,
		})
		return yield call(Api.patchConnexion, payload)
	} catch (error) {
		/*console.log(error)*/
	}
}

export default function* generalSagas() {
	yield takeLeading("G_GET_SMS_QUOTA_SAGA", getSmsQuota)
	yield takeLeading("G_GET_INTERNAL_WHITEMARK_SAGA", getInternalWhitemark)
	yield takeLeading("G_CHANGE_COLOR_WHITEMARK_SAGA", changeColorWm)
	yield takeLeading(
		"G_CHANGE_COLOR_WHITEMARK_EXTERNAL_SAGA",
		changeColorWmExternal,
	)
	yield takeLeading("G_GET_SSO_SAGA", getSso)
	yield takeLeading("G_DELETE_SSO_SAGA", delSso)
	yield takeLeading("G_POST_SSO_URL_SAGA", ssoUrl)
	yield takeLeading("G_POST_SSO_XML_SAGA", ssoXml)
	yield takeLeading(
		"G_UPDATE_USER_CONNEXION_NUMBER_SAGA",
		updateUserConnexionNumber,
	)
	yield takeLeading("G_GET_META_SAGA", getMeta)
	yield takeLeading("G_GET_SSO_CHECK_SAGA", getSsoCheck)
}
