import { toastr } from "react-redux-toastr"
import I18n from "../../i18n"
import { handleError, backwardCompApi, sleep } from "../../actions/generalUtils"

function headers(payload) {
	let a = {
		"Accept-Language": localStorage.getItem("i18nextLng"),
		Authorization: "Bearer " + payload.token,
		"X-Ls-Token": payload.xLsT,
	}

	if (!payload.formData) a = { ...a, "Content-Type": "application/json" }

	return a
}

function formQuery(data) {
	let query = ""
	let first = true

	if (data.offset) {
		first = false
		query += "?offset=" + data.offset
	}

	if (data.limit) {
		if (first) query += "?limit=" + data.limit
		else query += "&limit=" + data.limit
		first = false
	}

	if (data.search) {
		if (first) query += "?search=" + data.search
		else query += "&search=" + data.search
		first = false
	}

	if (data.organizationId) {
		if (first) query += "?organizationId=" + data.organizationId
		else query += "&organizationId=" + data.organizationId
		first = false
	}

	if (typeof data.categoryId !== "undefined") {
		if (first) query += "?categoryId=" + data.categoryId
		else query += "&categoryId=" + data.categoryId
		first = false
	}

	if (typeof data.searchType !== "undefined") {
		if (first) query += "?searchType=" + data.searchType
		else query += "&searchType=" + data.searchType
		first = false
	}

	if (data.folderId) {
		if (first) query += "?folderId=" + data.folderId
		else query += "&folderId=" + data.folderId
	}

	return query
}

export const getFile = (payload) => {
	////console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "files/file/" + payload.id

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getFiles = (payload) => {
	////console.log(payload)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") + "files/search?search=" + payload.search

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getFolder = (payload) => {
	////console.log(payload)

	let responseStatus
	let sortType = localStorage.getItem("sortType")
	let sortBy = localStorage.getItem("sortBy")

	let url =
		sortType === null
			? localStorage.getItem("apiUrl") +
			  "files/specific/folder/" +
			  payload.id
			: localStorage.getItem("apiUrl") +
			  "files/specific/folder/" +
			  payload.id +
			  "?sort=" +
			  (sortType === "alpha" ? "" : sortType) +
			  (sortType === "alpha"
					? sortBy
					: sortBy.charAt(0).toUpperCase() + sortBy.slice(1))

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getTree = (payload) => {
	////console.log(payload)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") + "files/folder" + formQuery(payload)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getPersoTree = (payload) => {
	////console.log(payload)

	let responseStatus
	let sortType = localStorage.getItem("sortType")
	let sortBy = localStorage.getItem("sortBy")

	let url =
		sortType === null
			? localStorage.getItem("apiUrl") + "files/folder/perso"
			: localStorage.getItem("apiUrl") +
			  "files/folder/perso" +
			  "?sort=" +
			  (sortType === "alpha" ? "" : sortType) +
			  (sortType === "alpha"
					? sortBy
					: sortBy.charAt(0).toUpperCase() + sortBy.slice(1))

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => {
			// Check files that are empty. We must replace the "NAN bytes" string by "0 byte".
			const filesList = res[0].files
			for (let i = 0; i < filesList.length; i++) {
				if (filesList[i].size === "NAN bytes")
					filesList[i].size = "0 byte"
			}
			return res
		})
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getRootFolders = (payload) => {
	////console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "files/root/folder"

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getBranch = (payload) => {
	////console.log(payload)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") +
		"files/branch/folder?mainId=" +
		payload.mainId

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const uploadFiles = (payload, retries = 0) => {
	let responseStatus
	let url = localStorage.getItem("apiUrl") + "files/file"

	const data = new FormData()
	data.append("chunkIndex", payload.data.chunkIndex)

	// The sendEmail key is only set when uploading the last chunk. We can
	// use this condition to also append the totalFiles key.
	if (payload.data.sendEmail) {
		data.append("totalFiles", payload.data.totalFiles)
		data.append("sendEmail", payload.data.sendEmail)
	}

	data.append("file", payload.data.filePart)
	data.append("totalChunkCount", payload.data.totalChunkCount)
	data.append("totalSize", payload.data.totalSize)
	data.append("uuid", payload.data.uuid)
	data.append("folderId", payload.data.folderId)
	data.append("firstUpload", payload.data.firstUpload)

	return (
		fetch(url, {
			method: "POST",
			headers: headers({ ...payload, formData: true }),
			body: data,
		})
			.then((res) => {
				responseStatus = res?.ok
				return res
			})
			.then((res) => res.json())
			// .then(res => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
			.then((res) => {
				const feedbackForSaga = { ...res }
				if (retries > 0) feedbackForSaga.uncertainChunk = true

				if (responseStatus) {
					return feedbackForSaga
				}

				// If we have a 409, we must simply proceed the next chunk, hence the return without handleError despite
				// the code designing an error in the payload.
				if (res.code === 409) return feedbackForSaga

				return handleError(res)
			})
			.catch((e) => {
				if (retries < 5) {
					toastr.error(
						I18n.t("forServices.networkError"),
						I18n.t("forServices.triesLeft", {
							tryNumber: retries + 1,
						}),
					)
					return sleep(5000).then(() =>
						uploadFiles(payload, retries + 1),
					)
				}

				return handleError(e)
			})
	)
}

export const getFolderReport = (payload) => {
	let responseStatus
	let responseCode
	let url = `${localStorage.getItem("apiUrl")}folders/report/pdf?folderId=${
		payload.data.folderId
	}`

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch(() => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			// console.log(e)
		})
		.then((res) => {
			responseStatus = res?.ok
			responseCode = res.status
			return res
		})
		.then((res) => res.blob())
		.then((res) =>
			!responseStatus
				? handleError({ status: responseCode, msg: "folder not found" })
				: res,
		)
}

export const postFolder = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "files/folder"

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify(payload.action),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => {
			return res
		})
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const putFolder = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "files/folder/" + payload.id

	return fetch(url, {
		method: "PUT",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const deleteFolder = (payload) => {
	let responseStatus
	let apiVersion = backwardCompApi("1.11.1") ? `&cascade=${1}` : null
	let url =
		localStorage.getItem("apiUrl") +
		`files/folder?ids=${payload.action}${apiVersion}`

	return fetch(url, {
		method: "DELETE",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const getFolderSize = (payload) => {
	//console.log(payload)
	let responseStatus
	let url =
		localStorage.getItem("apiUrl") +
		"files/folder/total/size?folderId=" +
		payload.id

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const putNotification = (payload) => {
	//console.log(payload)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") +
		"files/folder/notification/" +
		payload.id

	return fetch(url, {
		method: "PUT",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const downloadFile = (payload) => {
	return new Promise(function (resolve, reject) {
		let xhr = new XMLHttpRequest()

		xhr.open(
			"GET",
			`${localStorage.getItem("apiUrl")}files/file?id=${
				payload.action.id
			}`,
		)
		xhr.setRequestHeader("X-LS-Token", payload.xLsT)
		xhr.setRequestHeader("Content-Type", "application/octet-stream")
		xhr.setRequestHeader("Authorization", "Bearer " + payload.token)
		xhr.setRequestHeader("Access-Control-Allow-Headers", "Origin")
		xhr.responseType = "blob"
		xhr.send()

		xhr.onload = function (event) {
			let blob = this.response //new Blob([data], {type: "application/json"})

			if (!payload.noDirectDL) {
				let fileName = payload.action.fileName
				let a = document.createElement("a")
				a.href = URL.createObjectURL(blob)
				a.download = fileName
				document.body.appendChild(a)
				a.click()
				document.body.removeChild(a)
			}
			return resolve(event)
		}

		xhr.onerror = function () {
			return reject({
				status: this.status,
				statusText: xhr.statusText,
			})
		}
	})
}

/*
export const downloadFolder = payload => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl")+'download/generate/token'

    let body = JSON.stringify({
    	method: "downloadFolder",
        folderId: payload.folderId,
        allFolder: payload.recursive
    })


    function finalDownload(link) {
    	window.location.assign(link)
    	return "Success"
    }

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body
	})
	.catch(e => {
		toastr.error(I18n.t("forServices.error"), I18n.t("forServices.netIssue"))
		throw e;
	})
	.then(res => {responseStatus = res?.ok;return res})
	.then(res => res.json())
	.then(res => (!responseStatus ? handleError(res) : finalDownload(res.link))) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}


export const downloadSelectedFiles = payload => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl")+'download/generate/token'

    let body = JSON.stringify({
    	method: "downloadFiles",
        folderId: payload.folderId,
        fileIds: payload.fileIds
    })


    function finalDownload(link) {
    	window.location.assign(link)
    	return "Success"
    }

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body
	})
	.catch(e => {
		toastr.error(I18n.t("forServices.error"), I18n.t("forServices.netIssue"))
		throw e;
	})
	.then(res => {responseStatus = res?.ok;return res})
	.then(res => res.json())
	.then(res => (!responseStatus ? handleError(res) : finalDownload(res.link))) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}
*/

export const putFile = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "files/file/" + payload.id

	return fetch(url, {
		method: "PUT",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const deleteFile = (payload) => {
	let responseStatus
	let url =
		localStorage.getItem("apiUrl") + `files/file?ids=${payload.action}`

	return fetch(url, {
		method: "DELETE",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const folderAddGroup = (payload) => {
	//console.log(payload)

	let responseStatus
	const url = localStorage.getItem("apiUrl") + "files/group"

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify(payload.action),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const folderAddUser = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "files/user"

	return fetch(url, {
		method: "POST",
		headers: headers(payload),
		body: JSON.stringify(payload.action),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const folderDelGroup = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "files/group?ids=" + payload.ids

	return fetch(url, {
		method: "DELETE",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const folderDelUser = (payload) => {
	//console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "files/user?ids=" + payload.ids

	return fetch(url, {
		method: "DELETE",
		headers: headers(payload),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => (responseStatus ? res : res.json()))
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const folderChangeUserGrade = (payload) => {
	//console.log(payload)

	let responseStatus
	let url =
		localStorage.getItem("apiUrl") +
		"files/user/" +
		payload.userIdToSetRight

	return fetch(url, {
		method: "PUT",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const folderChangeGroupGrade = (payload) => {
	let responseStatus
	let url =
		localStorage.getItem("apiUrl") +
		"files/groups/rights/" +
		payload.groupIdToSetRight

	return fetch(url, {
		method: "PUT",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}

export const moveFolder = (payload) => {
	// console.log(payload)

	let responseStatus
	let url = localStorage.getItem("apiUrl") + "files/folder/move/" + payload.id

	return fetch(url, {
		method: "PUT",
		headers: headers(payload),
		body: JSON.stringify(payload.data),
	})
		.catch((e) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw e
		})
		.then((res) => {
			responseStatus = res?.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}
