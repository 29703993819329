import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"

export default function TooltipHelper(props) {
	const {
		className,
		content,
		children,
		topPos,
		bottomPos,
		rightPos: initialRightPos,
		leftPos: initialLeftPos,
		active,
		hasIcon,
		Icon,
		isChildrenHidden,
		isInCompass,
		hasEllipsis,
		iconCN,
		isInExtension,
		isInCardRepo,
		logs,
		location,
		isInLogin,
		labelClass,
	} = props

	const [tooltipPosition, setTooltipPosition] = useState("top")
	const [isHovering, setIsHovering] = useState(false)
	const ref = useRef(null)
	const contentRef = useRef(null)
	const triggerRef = useRef(null)
	const childrenCopyRef = useRef(null)
	const rightPos = initialRightPos
	const leftPos = initialLeftPos
	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	})

	useEffect(() => {
		const handleResize = () => {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			})
		}

		window.addEventListener("resize", handleResize)

		// Nettoyer l'écouteur d'événement lorsque le composant est démonté
		return () => {
			window.removeEventListener("resize", handleResize)
		}
	}, [windowSize.width]) // L'effet ne s'exécute qu'une fois au montage du composant grâce au tableau de dépendances vide

	useEffect(() => {
		const trigger = triggerRef.current

		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						updateTooltipPosition()
					}
				})
			},
			{
				root: null,
				threshold: 0.1,
			},
		)

		if (trigger) {
			observer.observe(trigger)
		}

		return () => {
			if (trigger) {
				observer.unobserve(trigger)
			}
		}
	}, [isHovering])

	const checkContentWidthOrHeight = () => {
		const children = triggerRef.current
		const childrenCopy = childrenCopyRef.current

		if (isInCompass && childrenCopy.offsetWidth > children.offsetWidth) {
			return true
		}

		if (
			(!isInCompass &&
				childrenCopy?.offsetHeight > children?.offsetHeight) ||
			childrenCopy?.offsetWidth > children?.offsetWidth
		) {
			return true
		}

		return false
	}

	const splitedContent = () => {
		const children = triggerRef.current
		const childrenCopy = childrenCopyRef.current
		const tooltipContent = content

		const lettersToShow = Math.min(
			Math.floor(windowSize.width / 325),
			tooltipContent.length / 2,
		)

		if (childrenCopy && children) {
			if (childrenCopy?.offsetWidth > children?.offsetWidth) {
				const firstLetters = tooltipContent.slice(0, lettersToShow)
				const lastLetters = tooltipContent.slice(-lettersToShow)
				return firstLetters + "..." + lastLetters
			} else {
				return content
			}
		}
	}

	const checkEllipsis = () => {
		if (hasEllipsis) {
			return triggerRef.current.innerText.includes("...")
		}
	}

	const updateTooltipPosition = () => {
		const element = contentRef.current
		const triggerRect = triggerRef.current?.getBoundingClientRect()

		if (element) {
			if (isInExtension) {
				if (leftPos) {
					return setTooltipPosition("left")
				} else if (rightPos) {
					return setTooltipPosition("right")
				} else if (bottomPos) {
					return setTooltipPosition("bottom")
				} else if (topPos) {
					return setTooltipPosition("top")
				}
			}
			const viewportHeight = window.innerHeight
			const viewportWidth = window.innerWidth
			const tooltipHeight = element.offsetHeight
			const tooltipWidth = element.offsetWidth
			const centeredLeft =
				triggerRect.left + (triggerRect.width - tooltipWidth) / 2

			const lineHeight = 20
			if (rightPos) {
				element.style.left = `${triggerRect.right + 8}px`
				element.style.top = `${
					triggerRect.top +
					triggerRect.height / 2 -
					element.offsetHeight / 2
				}px`

				if (element.getBoundingClientRect().right > viewportWidth) {
					element.style.left = `${
						triggerRect.left - 10 - tooltipWidth
					}px`

					if (element.getBoundingClientRect().left < 0) {
						element.style.top = `${
							triggerRect.top - element.offsetHeight - 5
						}px`
						element.style.left = `${centeredLeft}px`
						setTooltipPosition("top")
					} else {
						setTooltipPosition("left")
					}
				} else {
					setTooltipPosition("right")
				}
			} else if (leftPos) {
				element.style.left = `${triggerRect.left - 10 - tooltipWidth}px`
				element.style.top = `${
					triggerRect.top +
					triggerRect.height / 2 -
					element.offsetHeight / 2
				}px`

				if (element.getBoundingClientRect().left < 0) {
					element.style.left = `${triggerRect.right + 8}px`

					if (element.getBoundingClientRect().right > viewportWidth) {
						element.style.top = `${
							triggerRect.top - element.offsetHeight - 5
						}px`
						element.style.left = `${centeredLeft}px`
						setTooltipPosition("top")
					} else {
						setTooltipPosition("right")
					}
				} else {
					setTooltipPosition("left")
				}
			} else if (bottomPos) {
				element.style.top = `${triggerRect.bottom + 5}px`
				element.style.left = `${centeredLeft}px`

				if (triggerRect.bottom + tooltipHeight > viewportHeight) {
					element.style.top = `${
						triggerRect.top - tooltipHeight - 5
					}px`
					setTooltipPosition("top")
				} else {
					setTooltipPosition("bottom")
				}
			} else {
				element.style.top = `${triggerRect.top - tooltipHeight - 5}px`
				element.style.left = `${centeredLeft}px`
				setTooltipPosition("top")
			}

			if (element.offsetHeight > lineHeight * 5) {
				element.classList.add("expanded")
			} else {
				element.classList.remove("expanded")
			}
		}
	}

	const handleMouseEnter = () => {
		if (checkContentWidthOrHeight() || hasIcon || checkEllipsis()) {
			setIsHovering(true)
			updateTooltipPosition()
		}
	}

	const handleMouseLeave = () => {
		setIsHovering(false)
	}

	const getClassNames = () => {
		switch (tooltipPosition) {
			case "top":
				return "topStyle"
			case "bottom":
				return "bottomStyle"
			case "left":
				return "leftStyle"
			case "right":
				return "rightStyle"
			default:
				return ""
		}
	}

	const tooltipContent = () => {
		return !isInExtension ? (
			ReactDOM.createPortal(
				<div
					ref={contentRef}
					className={`tooltipHelper-content ${getClassNames()}`}
				>
					<span className="tooltipHelper-content-text">
						{content}
					</span>
				</div>,
				document.getElementById(isInLogin ? "login" : "mLayout"),
			)
		) : (
			<div
				ref={contentRef}
				className={`tooltipHelper-content inExtension  ${getClassNames()}`}
			>
				<span className="tooltipHelper-content-text">{content}</span>
			</div>
		)
	}

	return (
		<div
			className={`tooltipHelper ${className ? className : ""}`}
			ref={ref}
		>
			<div
				className={
					isChildrenHidden
						? "childrenHidden tooltipHelper-children"
						: `tooltipHelper-children ${
								location === "pass" && "pass"
						  }`
				}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				ref={triggerRef}
			>
				{Icon && (
					<Icon className={iconCN ? `${iconCN} icon` : "icon"} />
				)}
				<span
					className={`${
						logs ? "content-text-logs" : "content-text"
					} ${labelClass ? labelClass : ""}`}
				>
					{isInCardRepo ? splitedContent() : children}
				</span>
				{!hasIcon ? (
					<div
						className={
							isInCompass
								? "tooltipHelper-childrenCopy-inCompass"
								: "tooltipHelper-childrenCopy"
						}
						ref={childrenCopyRef}
					>
						<span className="tooltipHelper-content-text">
							{content}
						</span>
					</div>
				) : null}

				{(isHovering || active) && tooltipContent()}
			</div>
		</div>
	)
}

TooltipHelper.propTypes = {
	className: PropTypes.string,
	content: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	active: PropTypes.bool,
	topPos: PropTypes.bool,
	bottomPos: PropTypes.bool,
	rightPos: PropTypes.bool,
	leftPos: PropTypes.bool,
	hasIcon: PropTypes.bool,
	Icon: PropTypes.any,
	isChildrenHidden: PropTypes.bool,
	isInCompass: PropTypes.bool,
	hasEllipsis: PropTypes.bool,
	iconCN: PropTypes.string,
	isInExtension: PropTypes.bool,
	isInCardRepo: PropTypes.bool,
	logs: PropTypes.bool,
	location: PropTypes.string,
	isInLogin: PropTypes.bool,
	labelClass: PropTypes.string,
}
