import { call, put, takeLeading, takeLatest, select } from "redux-saga/effects"
import moment from "moment"
import * as Api from "./services"
import { addLCS, removeLCS } from "../../../../actions/generalUtils"

export function* getLogs(action) {
	try {
		addLCS(action)

		let ogP = { ...action.payload }
		let s = yield select((state) => state.dashboardLogs)

		let retrievePagin = ogP.retrievePagin || (ogP.initCall && !ogP.limit)
		let searchInitCase = ogP.search?.length > 0 && !ogP.offset

		if (retrievePagin) ogP.limit = s.pageSize * 2 //s.data[0].files.length ? s.data[0].files.length : s.pageSize * 2

		if (!ogP?.justRefresh && !ogP?.offset)
			yield put({ type: "dashboardLogs/toggleLoading", payload: true })

		yield put({ type: "dashboardLogs/setCR", payload: true })
		yield put({ type: "dashboardLogs/paginLoading", payload: true })

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, ...ogP }

		if (ogP.saveLimitAs2Size)
			yield put({
				type: "dashboardLogs/savePageSize",
				payload: ogP.limit / 2,
			})

		const auth = yield select((state) => state.auth)

		// When finalizing the payload, check the hourIn and hourOut values in the store. If they contain data, use the YYYY-MM-DD HH:MM:SS syntax in the service. Else, use the YYYY-MM-DD syntax.
		payload = {
			...payload,
			...(s.hourIn
				? { dateIn: `${s.dateIn}%20${s.hourIn}:00` }
				: { dateIn: s.dateIn }),
			...(s.hourOut
				? { dateOut: `${s.dateOut}%20${s.hourOut}:00` }
				: { dateOut: s.dateOut }),
			...(s.organizationId
				? { organizationId: s.organizationId }
				: {
						organizationId:
							auth.token.userSession.organizationIds[
								auth.selectedOrg
							],
				  }),
		}

		let ogData = yield call(Api.getLogs, payload)

		yield put({ type: "dashboardLogs/setCR" })
		yield put({ type: "dashboardLogs/paginLoading" })

		switch (ogData) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				removeLCS(action)
				return yield put({
					type: "dashboardLogs/setErrorNotFound",
					payload: true,
				})
			default: {
				removeLCS(action)
				let data = { ...ogData }

				if (retrievePagin || searchInitCase) {
					let sps = s.pageSize
					// data.pages = Math.ceil(ogData.total / sps)
					data.page = Math.ceil(ogP.limit / sps)
					data.limit = sps
				}

				if (!ogP?.justRefresh && !ogP?.offset)
					yield put({ type: "dashboardLogs/toggleLoading" })

				if (ogP.offset)
					return yield put({
						type: "dashboardLogs/addElements",
						payload: { ...ogP, ...data },
					})
				else if (ogP.search?.length >= 1)
					return yield put({
						type: "dashboardLogs/initSearch",
						payload: { ...ogP, ...data },
					})
				else
					return yield put({
						type: "dashboardLogs/setElements",
						payload: { ...ogP, ...data },
					})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getLogsFilters(action) {
	try {
		addLCS(action)

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, ...action.payload }

		const data = yield call(Api.getLogsFilters, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				yield put({ type: "dashboardLogs/setFilters", payload: data })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getListOrganization(action) {
	try {
		addLCS(action)

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, ...action.payload }

		const data = yield call(Api.getListOrganization, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				yield put({ type: "dashboardLogs/setListOrga", payload: data })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* generateCSV(action) {
	try {
		let data

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, ...action.payload }

		yield put({ type: "dashboardLogs/setActionLoading", payload: true })

		if (action.payload) {
			data = yield call(Api.getLogsForDownload, payload)
		} else {
			data = yield select((state) => state.dashboardLogs.data[0].files)
		}

		if (data) {
			let rows = []

			rows.push([
				'"Email"',
				'"Type"',
				'"Action"',
				'"Description"',
				'"IP"',
				'"User Agent"',
				'"Date"',
			])

			data.map((item) => {
				rows.push([
					`"${item.userEmail}"`,
					`"${item.type}"`,
					`"${item.object}"`,
					`"${item.message}"`,
					`"${item.userIp}"`,
					`"${item.userAgent}"`,
					`"${moment(item.creationDate).format("LL")} at ${moment(
						item.creationDate,
					).format("LTS")}"`,
				])
			})

			let csvContent = rows.map((e) => e.join(",")).join("\n")
			let blob = new Blob([csvContent], {
				type: "text/csv;charset=utf-8;",
			})
			let url = URL.createObjectURL(blob)
			let link = document.createElement("a")

			link.setAttribute("href", url)
			link.setAttribute(
				"download",
				`lockselfLogsExport-${moment().format("HH-mm-ss")}.csv`,
			)
			document.body.appendChild(link)
			link.click()
		}

		yield put({ type: "dashboardLogs/setActionLoading" })

		return yield put({ type: "action/closeMiniAction" })
	} catch (error) {
		//console.log(error)
	}
}

export default function* transferSagas() {
	yield takeLatest("LOGS_GET_SAGA", getLogs)
	yield takeLeading("LOGS_GET_FILTERS_SAGA", getLogsFilters)
	yield takeLeading("LOGS_GENERATE_CSV_SAGA", generateCSV)
	yield takeLeading("LOGS_GET_LIST_ORGANIZATION", getListOrganization)
}
