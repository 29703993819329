import { createSlice } from "@reduxjs/toolkit"

const licenseSlice = createSlice({
	name: "license",
	initialState: {
		info: {},
		email: "",
		id: "",
		slots: {},
	},
	reducers: {
		setInfo: (state, action) => {
			state.info = action.payload
		},
		setId: (state, action) => {
			state.id = action.payload
		},
		setEmail: (state, action) => {
			state.email = action.payload
		},
		setSlots: (state, action) => {
			localStorage.setItem("licenseSlots", JSON.stringify(action.payload))
			state.slots = action.payload
		},
		__devSetLicExpirationDate: (state, action) => {
			state.info.expirationDate = action.payload
		},
		__devSetSlotsUsed: (state, action) => {
			state.slots = {
				slotUsed: action.payload,
				totalSlot: state.slots.totalSlot,
			}
		},
		__devSetLicCreationDate: (state, action) => {
			state.info.createdAt = action.payload
		},
		__devTogglePocStatus: (state, action) => {
			state.info.poc = action.payload
		},
	},
})

//export const { openAction, closeAction } = actionSlice.actions

export default licenseSlice.reducer
