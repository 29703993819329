import React from "react"
import PropTypes from "prop-types"

import TooltipHelper from "../../tooltip/TooltipHelper"

export function CheckBox(props) {
	const {
		isChecked,
		type,
		onChange,
		disabled,
		Icon,
		label,
		smallLabel,
		style,
		isExcludedCatID,
		size,
		isInExtension,
	} = props

	return (
		<div
			className={`repHead ${isExcludedCatID ? "actual" : ""}`}
			onClick={!disabled && style !== "hidden" ? onChange : undefined}
		>
			<div
				className={`checkBox ${type ?? "default"} ${
					disabled
						? "disabled"
						: isChecked
						? type === "radio"
							? "radioSelected"
							: "selected"
						: ""
				} ${size ? size : "default"}`}
				style={{
					visibility: style,
				}}
			/>
			{type === "radio" ? (
				<>
					{Icon}
					{label ? (
						<h3>{label}</h3>
					) : (
						<TooltipHelper
							leftPos
							content={smallLabel}
							isInExtension={isInExtension}
						>
							{smallLabel}
						</TooltipHelper>
					)}
				</>
			) : null}
		</div>
	)
}

CheckBox.propTypes = {
	isChecked: PropTypes.bool,
	type: PropTypes.oneOf(["lockpass", "default", "radio"]),
	size: PropTypes.oneOf(["small", "default", "big"]),
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	Icon: PropTypes.element,
	label: PropTypes.string,
	smallLabel: PropTypes.string,
	style: PropTypes.string,
	isExcludedCatID: PropTypes.bool,
	isInExtension: PropTypes.bool,
}
