import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
//import { reactI18nextModule } from "react-i18next";
import { initReactI18next } from "react-i18next"

import translationFR from "./assets/translations/fr-FR/translations.json"
import translationEN from "./assets/translations/en-EN/translations.json"

// the translations
const resources = {
	fr: { translation: translationFR },
	en: { translation: translationEN },
}

i18n.use(LanguageDetector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		fallbackLng: "en",
		debug: false,
		supportedLngs: ["en", "fr"],
		react: {
			useSuspense: true,
		},
	})

export default i18n
