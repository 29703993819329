import React, { useState } from "react"
import PropTypes from "prop-types"

import { createPortal } from "react-dom"

const CustomFrame = ({ children, ...props }) => {
	const [iframeBody, setIframeBody] = useState(null)

	const handleLoad = (e) => {
		try {
			if (!e.defaultPrevented)
				setIframeBody(e.target.contentDocument.body)
		} catch (e) {}
	}

	return (
		<iframe srcDoc={`<!DOCTYPE html>`} {...props} onLoad={handleLoad}>
			{iframeBody && createPortal(children, iframeBody)}
		</iframe>
	)
}

export default CustomFrame

CustomFrame.propTypes = {
	children: PropTypes.any,
}
