import { createSlice } from "@reduxjs/toolkit"

const actionBtn = [
	{ name: "pass.addPass", action: "addPass" },
	{ name: "pass.addCategory", action: "addCategory" },
]

export const passRelatedConfig = {
	showHelp: "lP",
	boardHelp: "lP",
	repName: "mLayout.categories",
	fileName: "pass.passList",
	comp: "Pass",
	actionBtn,
	loadData: "LP_GET_DATA_SAGA",
	searchable: true,
}
const base = [
	{
		name: "pass.customIndex.title",
		id: 0,
		homepageLPView: true,
		...passRelatedConfig,
		boardHelp: undefined,
	},
	{
		name: "mLayout.personnalSpace",
		id: 0,
		subFolderId: 1,
		mainId: 0,
		static: true,
		dispatchable: true,
		...passRelatedConfig,
		loadData: "LP_GET_DATA_PERMALINKS_SAGA",
		rootLoadData: "LP_GET_DATA_SAGA",
		icon: "personal",
	},
	{
		name: "mLayout.shared",
		id: -1,
		subFolderId: 2,
		static: true,
		dispatchable: true,
		...passRelatedConfig,
		loadData: "LP_GET_DATA_PERMALINKS_SAGA",
		rootLoadData: "LP_GET_DATA_SAGA",
		icon: "share",
	},
]

const passSlice = createSlice({
	name: "pass",
	initialState: {
		loading: true,
		slideLoading: false,
		data: null,
		categories: {},
		history: [],
		active: {},
		favCats: [],
		searchMode: false,
		searchVal: "",
		searchType: "0",
		searchResults: [],
		searchCatResults: [],
		lastActionResult: null,
		connectionResult: null,
		categories: {},
		paginLoading: false,
		errorNotFound: false,
		passwordPolicies: [],
		trashLoading: false,
		arboChildren: [],
		arbo: base,
		compassLoading: true,
		runningGetIsWaitingSaga: false,
		trash: {
			buttonLoading: false,
			total: null,
			searchedFiles: [],
			files: [],
			listType:
				JSON.parse(localStorage.getItem("user"))?.userType === "isUser"
					? "personal"
					: "all",
		},
		sshFileTemporary: {},
		isArboLoaded: false,
	},
	reducers: {
		init: (state, action) => {
			state.data = base
			state.history = []
			state.active = {}
			state.searchMode = false
			state.loading = true
			state.searchVal = ""
			state.searchType = "0"
			state.lastActionResult = null
			state.connectionResult = null
			state.trash.buttonLoading = false
			state.trashLoading = false
			state.trash.total = null
			state.trash.searchedFiles = []
			state.trash.files = []
			state.sshFileTemporary.file = {}
			state.sshFileTemporary.decryptedFile = {}

			if (action.payload?.resetArbo) {
				state.isArboLoaded = false
			}

			if (action.payload?.extensiveReset) {
				state.arbo = base
				state.categories = null
				state.isArboLoaded = false
				state.arboChildren = []
				state.sharedCategories = null
				state.persoCategories = null
			}
		},
		initArbo: (state) => {
			state.arbo = base
			state.categories = null
			state.isArboLoaded = false
			state.arboChildren = []
			state.sharedCategories = null
			state.persoCategories = null
		},
		setErrorNotFound: (state, action) => {
			state.errorNotFound = action?.payload ? action?.payload : false
			state.callRunning = false
		},
		savePageSize: (state, action) => {
			state.pageSize = action.payload
		},
		toggleLoading: (state, action) => {
			if (!action.payload) {
				state.loading = false
			} else {
				state.loading = action.payload
			}
		},
		toggleSlideLoading: (state, action) => {
			if (!action.payload) {
				state.slideLoading = false
			} else {
				state.slideLoading = action.payload
			}
		},
		toggleCompassLoading: (state, action) => {
			if (!action.payload) {
				state.compassLoading = false
			} else {
				state.compassLoading = action.payload
			}
		},
		toggleTrashLoading: (state, action) => {
			if (!action.payload) {
				state.trashLoading = false
			} else {
				state.trashLoading = action.payload
			}
		},
		toggleChildrenLoading: (state, action) => {
			if (!action.payload) {
				state.childrenLoading = false
			} else {
				state.childrenLoading = action.payload
			}
		},
		setUsersLoading: (state, action) => {
			if (!action.payload) {
				state.usersLoading = null
			} else {
				state.usersLoading = action.payload
			}
		},
		paginLoading: (state, action) => {
			if (!action.payload) {
				state.paginLoading = null
			} else {
				state.paginLoading = action.payload
			}
		},
		setLAR: (state, action) => {
			if (!action.payload) {
				state.lastActionResult = null
			} else {
				state.lastActionResult = action.payload
			}
		},
		setConnection: (state, action) => {
			if (!action.payload) {
				state.connectionResult = null
			} else {
				state.connectionResult = action.payload
			}
		},
		setCR: (state, action) => {
			if (!action.payload) {
				state.callRunning = false
			} else {
				state.callRunning = action.payload
			}
		},
		setIsWaiting: (state, action) => {
			if (!action.payload) {
				state.isWaiting = false
			} else {
				state.isWaiting = true
			}
		},
		updatePagin: (state, action) => {
			if (action.payload) state.pagin = action.payload
		},
		updateHistory: (state, action) => {
			if (action.payload) state.history = action.payload
		},
		saveBranch: (state, action) => {
			if (action.payload) state.branchInfo = action.payload
		},
		saveCategories: (state, action) => {
			if (action.payload) state.categories = action.payload
		},
		setFavCats: (state, action) => {
			if (action.payload?.length) {
				// Sort data to retieve personnal categories first
				let favs = [...action.payload]

				state.favCats = favs.sort((fav) => (fav.mainId === 0 ? -1 : 1))
			} else {
				state.favCats = []
			}
		},
		addFavCat: (state, action) => {
			state.favCats = [
				...state.favCats,
				{ ...action.payload, _links: undefined },
			].sort((a) => (a.mainId === 0 ? -1 : 1))
		},
		removeFavCat: (state, action) => {
			state.favCats = [
				...state.favCats.filter((item) => action.payload !== item.id),
			]
		},
		removeFirstStateElement: (state) => {
			let newState = [...state.data]
			state.data = newState.slice(1)
		},
		removeLastStateElement: (state) => {
			let newState = [...state.data]
			state.data = newState.slice(0, -1)
		},
		removeLastHistoryElement: (state) => {
			let newHistory = [...state.history]
			state.history = newHistory.slice(0, -1)
		},
		addElements: (state, action) => {
			// Ici on combine la data de fichiers/dossiers dans le state redux, et on y ajoute les fichiers récupérés depuis l'appel api
			let og = state.data[0]
			let newFiles = action.payload._embedded?.items
				? action.payload._embedded?.items
				: action.payload.files
				? action.payload.files
				: []

			let newState = [...state.data]
			newState[0] = {
				...og,
				files:
					og.files?.length > 0 && !action.payload.doNotKeep
						? [...og.files, ...newFiles]
						: newFiles,
				reps: og.reps ? og.reps : [],
				boardHelp: "lP",
			}

			state.data = newState
			state.pagin = {
				limit: action.payload.limit,
				page: action.payload.page,
				pages: action.payload.pages,
				total: action.payload.total,
			}
			//console.log(state.pagin)
		},
		addTrashListElement: (state, action) => {
			let trashFilesList = state.trash
			let newFiles = action.payload.entity ?? []
			let newList = state.trash.files.entity
			newList = {
				limit: action.payload.limit,
				page: action.payload.page,
				pages: action.payload.pages,
				total: action.payload.total,
				entity:
					trashFilesList?.files.entity?.length > 0
						? [...trashFilesList.files.entity, ...newFiles]
						: newFiles,
			}
			state.trash.files = newList
		},
		addChildren: (state, action) => {
			const children = action.payload.data

			state.arboChildren = {
				...state.arboChildren,
				[action.payload.parentId]: children,
			}
		},
		addReps: (state, action) => {
			let i
			if (action.payload.stateTarget) {
				i = action.payload.stateTarget
			} else {
				i = 0
			}

			let og = state.data[i]
			let newState = [...state.data]

			let ogArbo = state.arbo[i]
			let arboState = [...state.arbo]

			// On prépare les nouveaux dossiers
			let d = action.payload.data
			let newD = []

			if (d?.length > 0) {
				d.map((item) => {
					return newD.push({
						...item,
						showHelp: "lP",
						boardHelp: "lP",
						repName: "mLayout.categories",
						fileName: "pass.passList",
						comp: "Pass",
						loadData: "LP_GET_DATA_PERMALINKS_SAGA",
						rootLoadData: "LP_GET_DATA_SAGA",
					})
				})
			}

			// On les ajoute au nouveau state
			let reps = action.payload?.doNotKeep
				? newD
				: og.reps?.length > 0
				? [...og.reps, ...newD]
				: newD

			let arboReps = action.payload?.doNotKeep
				? newD
				: ogArbo.reps?.length > 0
				? [...ogArbo.reps, ...newD]
				: newD

			newState[i] = { ...og, reps }
			arboState[i] = { ...ogArbo, reps: arboReps }

			state.arbo = arboState

			if (!action.payload?.initArbo) {
				state.data = newState
			}
			//state.loading = false
		},
		historyEnter: (state, action) => {
			let pt = action.payload.productTarget
				? action.payload.productTarget
				: 0

			state.history.push({
				name: state.data[pt].name,
				loadData: state.data[pt].loadData,
				// historyState: JSON.stringify(state.history),
				repName: state.data[pt].repName,
				fileName: state.data[pt].fileName,
				comp: state.data[pt].comp,
				noSelect: state.data[pt].noSelect ? true : false,
				showHelp: state.data[pt].showHelp
					? state.data[pt].showHelp
					: false,
				actionBtn: state.data[pt].actionBtn,
				searchable: state.data[pt].searchable ? true : false,
				categoryId: state.data[0].id,
				id: state.data[0].id,
				parentId: state.data[0].parentId,
				mainId: state.data[0].mainId,
				isInCategory: state.data[0].isInCategory,
			})
		},
		enterInFolder: (state, action) => {
			let pt = action.payload.productTarget
				? action.payload.productTarget
				: 0 // Quel sous produit on vise ? N'existe que si l'on est à la base du produit et depuis le compass affichant tous les sous produits

			if (action.payload.justRefresh) {
				// Lorsqu'on reste dans le même repertoire mais qu'on update seulement les données (par ex après post ou delete)

				// Ici on combine la data de fichiers/dossiers dans le state redux, et on y ajoute les fichiers récupérés depuis l'appel api
				let a = []
				let og = state.data[0]
				a.push({
					...og,
					files: action.payload._embedded.items,
					reps: og.reps ? og.reps : [],
				})

				if (action.payload.retriveActive) {
					let a = action.payload._embedded.items.find(
						(item) =>
							item.id ===
							state.active[Object.keys(state.active)[0]].id,
					)
					let elemType =
						state.active[Object.keys(state.active)[0]].elemType
					let ai = Object.keys(state.active)[0]

					state.active = { [ai]: { ...a, elemType } }
				} else {
					state.active = {}
				}

				if (state.data[1]) a.push(state.data[1])

				state.data = a
				state.pagin = {
					limit: action.payload.limit,
					page: action.payload.page,
					pages: action.payload.pages,
					total: action.payload.total,
				}
				return
			}

			if (
				!action.payload.isBackToHistorisedTarget &&
				!action.payload.isTeleporting
			) {
				// Ici on combine la data de fichiers/dossiers dans le state redux, et on y ajoute les fichiers récupérés depuis l'appel api
				let a = []
				let og = state.data?.[pt]?.reps?.[action.payload.domTarget]
					? state.data[pt].reps[action.payload.domTarget]
					: {}

				a.push({
					...og,
					repUsers: action.payload.repUsers,
					searchable: true,
					files: action.payload._embedded?.items,
					reps: og.reps ? og.reps : [],
					...(!og.static
						? state.data?.[pt]?.actionBtn
							? { actionBtn: state.data?.[pt]?.actionBtn }
							: state.searchMode
							? { actionBtn }
							: {}
						: {}),
				})

				state.data = a
			} else {
				// ici on revient donc sur un dossier précedent OU on se TP

				if (action.payload.isBackToHistorisedTarget) {
					let _history = [...state.history]
					state.history = _history.slice(0, action.payload.domTarget)
				}
				// let _historyState = action.payload.historyState
				// if (_historyState) {
				// 	if (typeof _historyState === "string") {
				// 		state.history = JSON.parse(action.payload.historyState)
				// 	} else {
				// 		state.history = action.payload.historyState
				// 	}
				// }

				let ns = []
				let p = action.payload
				let og = state.data[0]

				ns.push({
					id:
						typeof p.categoryId !== "undefined"
							? p.categoryId
							: undefined,
					mainId:
						typeof p.mainId !== "undefined" ? p.mainId : undefined,
					isInCategory: p.isInCategory,
					name: p.name,
					static: p.static,
					loadData: p.loadData,
					files: p._embedded?.items,
					repName: p.repName,
					fileName: p.fileName,
					comp: p.comp,
					reps: og.reps && !action.payload.resetReps ? og.reps : [],
					noSelect: p.noSelect ? true : false,
					showHelp: p.showHelp ? p.showHelp : false,
					actionBtn: p.actionBtn
						? p.actionBtn
						: p.isInCategory === false
						? []
						: actionBtn,
					searchable: true,
					repUsers: action.payload.repUsers
						? action.payload.repUsers
						: [],
				})

				state.data = ns
			}
			state.searchMode = false
			state.searchVal = ""
			state.active = {}
			state.lastActionResult = null
			state.pagin = {
				limit: action.payload.limit,
				page: action.payload.page,
				pages: action.payload.pages,
				total: action.payload.total,
			}
			return
		},
		manageSelected: (state, action) => {
			if (state.active[action.payload.elemKey]) {
				// Si l'élément est déjà dans les actifs on le retire
				delete state.active[action.payload.elemKey]
			} else {
				if (action.payload.uniSelect) {
					state.active = {
						[action.payload.elemKey]: action.payload,
					}
				} // Si on est en séléction unique (clic sur la card) on reset la sélec par l'élément
				else {
					state.active = {
						...state.active,
						[action.payload.elemKey]: action.payload,
					}
				} // sinon on le rajoute dans la sélec
			}
		},
		updateActive: (state, action) => {
			if (action.payload?.isSetter) {
				state.active = action.payload?.newActiveObject
			} else if (
				!action.payload?.removePass &&
				!action.payload?.addPass
			) {
				let targetPos = Object.keys(state.active)[0]
				let a = {
					...state.active[targetPos],
					...action.payload.data,
					elemKey: state.active[targetPos]?.elemKey,
					elemType: state.active[targetPos]?.elemType,
				}

				state.active[targetPos] = a // On update l'élément actif (qui est une copie de son original dans la data du store du produit)

				if (state.data[0].files?.length) {
					// Condition ajoutée lors du travail sur la HomepageLP car on peut avoir
					// des éléments dans le state active sans les avoir dans l'objet files

					// On l'update aussi dans son store original (si des elements sont présents)
					let idInOutdatedState = action.payload.ogId
						? action.payload.ogId
						: action.payload.id
					state.data[0].files[
						state.data[0].files.findIndex(
							(f) => f.id === idInOutdatedState,
						)
					] = a
				}
			} else if (action.payload?.addPass) {
				delete action.payload?.addPass

				state.active[action.payload?.id] = action.payload
			} else {
				delete state.active[action.payload?.id]
			}

			state.slideLoading = false
		},
		updateData: (state, action) => {
			let a = []

			if (action?.payload?.reset) {
				a.push({
					...state.data[action.payload.mainId === 0 ? 1 : 2],
					...action.payload.data,
				})
			} else {
				a.push({
					...state.data[0],
					...action.payload,
					reps: action.payload.children
						? action.payload.children
						: state.data[0].reps,
				})
			}

			state.data = a
		},
		updateTree: (state, action) => {
			// create by Theo, ask him if you have some questions : t.makowiak@lockself.com

			let tree = [...state.branchInfo.tree]
			let originCatIndex = null
			let originCatIsFound = false
			let updatedBranch = {}
			let branchIsUpdated = false

			const getModifyCatOrigin = (cat, index) => {
				if (originCatIsFound) return

				originCatIndex = index

				let findIdCategory = (subCat) => {
					if (subCat.id === action.payload.id) {
						originCatIsFound = true
					} else {
						subCat.children &&
							subCat.children.forEach(findIdCategory)
					}
				}

				if (cat.id === action.payload.id) {
					originCatIsFound = true
				} else {
					cat.children && cat.children.forEach(findIdCategory)
				}
			}

			const updateBranchWithNewCat = (branch) => {
				const nestedBranch = branch

				if (nestedBranch.id === action.payload.id) {
					nestedBranch.name = action.payload.name
				} else if (branch.children !== null) {
					const nestedBranchChildren = []

					nestedBranch?.children?.forEach((branch) => {
						nestedBranchChildren.push(
							updateBranchWithNewCat(branch),
						)
					})

					nestedBranch.children = nestedBranchChildren
				}

				return nestedBranch
			}

			;(state.data[0].mainId === 0 ||
			state.data[0].mainId === state.data[0].id
				? tree
				: tree[0].children
			).forEach(getModifyCatOrigin)

			updatedBranch = (
				state.data[0].mainId === 0 ||
				state.data[0].mainId === state.data[0].id
					? tree
					: tree[0].children
			)[originCatIndex]

			if (updatedBranch?.id === action.payload?.id) {
				updatedBranch.name = action.payload?.name
				branchIsUpdated = true
			}

			if (!branchIsUpdated) {
				const branchChildren = []

				updatedBranch?.children?.forEach((branch) => {
					branchChildren.push(updateBranchWithNewCat(branch))
				})

				updatedBranch.children = branchChildren
			}

			;(state.data[0].mainId === 0 ||
			state.data[0].mainId === state.data[0].id
				? tree
				: tree[0].children)[originCatIndex] = updatedBranch
			state.branchInfo.tree = tree
		},
		unselectAll: (state) => {
			state.active = {}
		},
		selectAll: (state, action) => {
			let a

			if (
				state.data[0].files?.length > 0 &&
				!action.payload?.isTrashedPasswords
			) {
				state.data[0].files?.map((file) => {
					return (a = {
						...a,
						[file?.id]: {
							...file,
							elemType: state.data?.[0]?.comp,
						},
					})
				})
			} else if (
				(state.searchMode &&
					state.trash?.["searchedFiles"]?.length > 0) ||
				(state.trash?.["files"]?.entity.length > 0 &&
					action.payload?.isTrashedPasswords)
			) {
				state.searchMode
					? state.trash?.["searchedFiles"]?.map((file) => {
							return (a = {
								...a,
								[file?.id]: {
									...file,
									passType: "Trash",
									elemType: state.data?.[0]?.comp,
								},
							})
					  })
					: state.trash?.["files"]?.entity.map((file) => {
							return (a = {
								...a,
								[file?.masterId.id]: {
									...file,
									passType: "Trash",
									elemType: state.data?.[0]?.comp,
								},
							})
					  })
			}
			state.active = a
		},
		setSearchVal: (state, action) => {
			state.searchVal = action.payload
		},
		setSearchType: (state, action) => {
			state.searchType = action.payload
		},
		initSearch: (state, action) => {
			let a = []
			let og = state.data[0]
			a.push({
				...og,
				name: action?.payload?.isTrash
					? "mLayout.compass.trashSearch"
					: "mLayout.compass.results",
				actionBtn: null,
				isInCategory: true,
				homepageLPView: undefined,
			})
			state.data = a

			state.searchMode = true
			state.searchVal = action.payload.search
			state.searchResults = []
			state.searchCatResults = []
			state.active = {}
			state.history = []
		},
		manualFilter: (state, action) => {
			let val = action.payload.toUpperCase()

			if (state.searchResults.length === 0) {
				state.searchResults = state.data[0].files
			}
			if (state.searchCatResults.length === 0) {
				state.searchCatResults = state.data[0].reps
			}

			let newFiles = []
			let newCats = []

			let tempoFiles = JSON.parse(JSON.stringify(state.searchResults))
			let tempoCats = JSON.parse(JSON.stringify(state.searchCatResults))

			tempoFiles.map((item) => {
				if (
					item.name?.toUpperCase().includes(val) ||
					item.token?.toUpperCase().includes(val) ||
					item.email?.toUpperCase().includes(val) ||
					item.phoneNumber?.toUpperCase().includes(val) ||
					item.firstname?.toUpperCase().includes(val) ||
					item.lastname?.toUpperCase().includes(val)
				) {
					return newFiles.push(item)
				} else {
					return undefined
				}
			})

			tempoCats.map((item) => {
				if (item.name?.toUpperCase().includes(val)) {
					return newCats.push(item)
				} else {
					return undefined
				}
			})

			state.data[0].files = newFiles
			state.data[0].reps = newCats
			state.pagin = {}
		},
		toggleTrashButtonLoading: (state, action) => {
			if (!action.payload) {
				state.trash.buttonLoading = false
			} else {
				state.trash.buttonLoading = action.payload
			}
		},
		setTrashTotal: (state, action) => {
			state.trash.total = action?.payload ?? null
		},
		setTrashSearchedFiles: (state, action) => {
			state.trash.searchedFiles = action?.payload ?? []
		},
		setTrashFiles: (state, action) => {
			state.trash.files = action?.payload ?? []
		},
		setTrashListType: (state, action) => {
			state.trash.listType = action?.payload ?? null
		},
		setTrashListOrder: (state, action) => {
			state.trash.listOrder = action?.payload ?? null
		},
		setSshFileTemporary: (state, action) => {
			if (action.payload?.decrypted === "decrypted") {
				state.sshFileTemporary.decryptedFile = action?.payload ?? {}
			} else {
				state.sshFileTemporary.file = action?.payload ?? {}
			}
		},
		setArboStatus: (state, action) => {
			if (action.payload?.space === "share") {
				state.isArboLoaded = {
					...state.isArboLoaded,
					share: true,
				}
			} else {
				state.isArboLoaded = {
					...state.isArboLoaded,
					perso: true,
				}
			}
		},
		setShareCateg: (state, action) => {
			if (action.payload) {
				state.sharedCategories = action.payload
			} else {
				state.sharedCategories = null
			}
		},
		setPersoCateg: (state, action) => {
			if (action.payload) {
				state.persoCategories = action.payload
			}
		},
		setPasswordPolicies: (state, action) => {
			if (!action.payload) state.passwordPolicies = null
			else state.passwordPolicies = action.payload
		},
		setRunningGetIsWaitingSaga: (state, action) => {
			// runningGetIsWaitingSaga
			state.runningGetIsWaitingSaga = action?.payload ?? false
		},
		setMappingData: (state, action) => {
			state.mappingData = action?.payload || null
		},
		setMappingLoader: (state, action) => {
			state.mappingLoader = action?.payload || false
		},
		emptyData: (state) => {
			state.data = null
		},
		setReloadArbo: (state, action) => {
			state.reloadArbo = action.payload.reloadArbo
		},
	},
})

export const { init } = passSlice.actions

export default passSlice.reducer
