import { eventChannel } from "redux-saga"
import { subscribeToTimeUpdates } from "../../actions/generalUtils"

export function createTimeUpdatesChannel() {
	const timeUpdateChannel = eventChannel((emitter) => {
		// La fonction subscribeToTimeUpdates doit retourner une fonction de désinscription
		const unsubscribe = subscribeToTimeUpdates((time) => {
			// À chaque mise à jour du temps, nous émettons un événement dans le canal
			emitter(time)
		})

		// Cette fonction sera exécutée lorsque le canal sera fermé
		return () => {
			unsubscribe()
		}
	})

	return timeUpdateChannel
}
