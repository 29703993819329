import { createSlice } from "@reduxjs/toolkit"
import I18n from "../i18n"

const actionSlice = createSlice({
	name: "action",
	initialState: {
		active: false,
		miniActive: false,
		guacTermActive: false,
		lastActionResult: null,
		closeActionButtonList: false,
		trash: undefined,
		uploadEstimator: null,
		loader: null,
	},
	reducers: {
		openAction: (state, action) => {
			state.active = true
			state.type = action.payload?.data
				? action.payload?.data
				: action.payload

			if (action?.info) {
				state.actionInfo = { ...action.info }
			}

			if (action.payload?.redirectionPath) {
				state.redirectionOrigin = action.payload?.data
					? action.payload?.data
					: action.payload
				state.redirectionPath = action.payload?.redirectionPath
			} else {
				state.redirectionOrigin = null
				state.redirectionPath = null
			}
		},
		setActionProps: (state, action) => {
			state.actionProps = action.payload
		},
		closeActionButtonList: (state) => {
			state.closeActionButtonList = true
		},
		resetCloseActionListFlag: (state) => {
			state.closeActionButtonList = false
		},
		openMiniAction: (state, action) => {
			state.miniActive = true
			state.miniType = action.payload.type
			state.miniOrigin = action.payload.origin
			state.trash = {
				categoryName: action.payload?.categoryName,
			}
			state.data = action.payload?.data
				? action.payload?.data
				: action.payload

			if (action.payload?.redirectionPath) {
				state.redirectionOrigin = action.payload.type
				state.redirectionPath = action.payload?.redirectionPath
			} else {
				state.redirectionOrigin = null
				state.redirectionPath = null
			}
		},
		closeAction: (state) => {
			state.active = false
			state.type = null
			state.data = undefined
			state.actionProps = null
		},
		closeMiniAction: (state) => {
			state.miniActive = false
			state.miniType = null
			state.miniOrigin = undefined
			state.data = undefined
			state.trash = undefined
		},
		showTokens: (state, action) => {
			state.active = true
			state.type = "apiUserTokens"
			state.data = action.payload
		},
		setLAR: (state, action) => {
			if (!action.payload) state.lastActionResult = null
			else state.lastActionResult = action.payload
		},
		setRedirectionPath: (state, action) => {
			if (!action.payload) state.redirectionPath = null
			else state.redirectionPath = action.payload
		},
		setTrash: (state, action) => {
			state.trash.createCatForRemainingPasswords =
				action?.payload?.createCatForRemainingPasswords
			state.trash.remainingPasswords = action?.payload?.ids
		},
		setupTimeEstimator: (state, action) => {
			const newState = {}

			const itemsList = action.payload.itemsList
			const initialUploadContent = {}
			let totalChunksCount = 0
			let totalSize = 0
			let totalLightCount = 0
			let totalHeavyCount = 0

			let chunkMaxSize = 20000000
			if (action.payload.chunkMaxSize) {
				chunkMaxSize = action.payload.chunkMaxSize
			}

			// Add the files/folders list to the estimator. Each item will be represented with
			// a list of chunks. A folder will be estimated to a chunk with a small size.
			for (let i = 0; i < itemsList.length; i++) {
				let remainingSize = 50000

				// We need to differenciate heavy payloads (files, and requests that have substential payload size) from
				// light payloads (any request that don't have any XForm or file associated, such as folders).
				// For now, we only estimate on files and folders upload, files will be classified as heavy and folders
				// as light, for light payloads, there will be only one chunk added, so we can increment the value here.
				let itemType = "light"

				if (itemsList[i].file?.size > 500000) {
					totalSize += itemsList[i].file.size
					remainingSize = itemsList[i].file.size
					itemType = "heavy"
				} else {
					totalLightCount++
				}

				// Create the chunks list for a file
				while (remainingSize > 0) {
					if (itemType === "heavy") {
						totalHeavyCount++
					}

					const chunkSize =
						remainingSize < chunkMaxSize
							? remainingSize
							: chunkMaxSize

					// If the current item has a name key, it means we're adding a folder.
					// For files, we don't have any name directly inside the payload, it is contained inside the file key.
					let chunkName = itemsList[i].name
					let fileSize = 100000

					if (chunkName === undefined) {
						chunkName = itemsList[i].file.name
						fileSize = itemsList[i].file.size
					}

					const newChunk = {
						size: chunkSize,
						uploadStart: null,
						fileName: chunkName,
						fileSize,
						weight: itemType,
					}

					if (!initialUploadContent[itemsList[i].id]?.length) {
						// Adding the first chunk of the file
						initialUploadContent[itemsList[i].id] = [newChunk]
					} else {
						initialUploadContent[itemsList[i].id].push(newChunk)
					}

					remainingSize -= chunkMaxSize
					totalChunksCount += 1
				}
			}

			newState.uploadContent = initialUploadContent
			newState.chunksToSend = totalChunksCount
			newState.chunksSent = 0
			newState.totalSize = totalSize
			newState.sizeSent = 0
			newState.meanSpeed = 0
			newState.timeLeft = null
			newState.meanHeavySpeed = 0
			newState.heavyChunksSent = 0
			newState.heavyRequestsQuantity = totalHeavyCount
			newState.meanLightSpeed = 0
			newState.lightChunksSent = 0
			newState.lightRequestsQuantity = totalLightCount
			newState.uploadStarted = false

			state.uploadEstimator = newState
		},
		startUpload: (state, action) => {
			// Some saga may or may not estimate the remaining upload time. To avoid adding more keys to complex mecanisms, this condition allow the startUpload slice to always run without throwing an error
			if (!state.uploadEstimator) {
				return
			}

			const uploadingFile =
				state.uploadEstimator.uploadContent[action.payload.fileId]
			state.uploadEstimator.uploadStarted = true

			if (!uploadingFile) {
				console.warn(I18n.t("genericErrors.startUploadError"))
				return
			}

			const uploadingChunk = uploadingFile[0]

			if (uploadingChunk?.uploadStart !== null) {
				console.warn(I18n.t("genericErrors.endUploadError"))
				return
			}

			const timerStart = new Date()
			uploadingChunk.uploadStart = timerStart
		},
		addFileToEstimator: (state, action) => {
			const { file, fileId, lightFile, name } = action.payload
			const uploadContent = state.uploadEstimator.uploadContent

			const chunkMaxSize = 20000000
			let remainingSize = 50000

			// We need to differenciate heavy payloads (files, and requests that have substential payload size) from
			// light payloads (any request that don't have any XForm or file associated, such as folders).
			// For now, we only estimate on files and folders upload, files will be classified as heavy and folders
			// as light, for light payloads, there will be only one chunk added, so we can increment the value here.
			let itemType = "light"

			if (file?.size > 500000 && !lightFile) {
				state.totalSize += file.size
				remainingSize = file.size
				itemType = "heavy"
			} else {
				state.uploadEstimator.lightRequestsQuantity++
			}

			// Create the chunks list for a file
			while (remainingSize > 0) {
				if (itemType === "heavy") {
					state.uploadEstimator.heavyRequestsQuantity++
				} else {
					state.uploadEstimator.lightRequestsQuantity++
				}

				const chunkSize =
					remainingSize < chunkMaxSize ? remainingSize : chunkMaxSize

				// If the current item has a name key, it means we're adding a folder.
				// For files, we don't have any name directly inside the payload, it is contained inside the file key.
				let chunkName = name
				let fileSize = 100000

				if (chunkName === undefined) {
					chunkName = file.name
					fileSize = file.size
				}

				const newChunk = {
					size: chunkSize,
					uploadStart: null,
					fileName: chunkName,
					fileSize,
					weight: itemType,
				}

				if (!uploadContent[fileId]?.length) {
					// Adding the first chunk of the file
					uploadContent[fileId] = [newChunk]
				} else {
					uploadContent[fileId].push(newChunk)
				}

				remainingSize -= chunkMaxSize
				state.chunksToSend++
			}
		},
		endUpload: (state, action) => {
			// Some saga may or may not estimate the remaining upload time. To avoid adding more keys to complex mecanisms, this condition allow the endUpload slice to always run without throwing an error
			if (!state.uploadEstimator) {
				return
			}

			const uploadEstimator = state.uploadEstimator
			const finishedChunk =
				uploadEstimator.uploadContent[action.payload.fileId][0]

			// Obtain the request's time
			const timerEnd = new Date()
			const timeEllapsed = timerEnd - finishedChunk.uploadStart

			// From now, we must treat separatly the heavy requests from light ones.
			// Update the heavy/light meanspeed
			if (finishedChunk.weight === "heavy") {
				uploadEstimator.sizeSent += finishedChunk.size
				const requestSpeed = finishedChunk.size / timeEllapsed
				const newHeavyChunksSent = uploadEstimator.heavyChunksSent + 1

				if (uploadEstimator.meanHeavySpeed === 0) {
					uploadEstimator.meanHeavySpeed = requestSpeed
				} else {
					uploadEstimator.meanHeavySpeed =
						(uploadEstimator.meanHeavySpeed *
							uploadEstimator.heavyChunksSent +
							requestSpeed) /
						newHeavyChunksSent
				}

				uploadEstimator.heavyChunksSent++
			} else {
				const newLightChunksSent = uploadEstimator.lightChunksSent + 1
				uploadEstimator.lightChunksSent++

				// Since light requests don't have any size, the speed is simply the time
				// needed for the request to end.
				if (uploadEstimator.meanLightSpeed === 0) {
					uploadEstimator.meanLightSpeed = timeEllapsed
				} else {
					uploadEstimator.meanLightSpeed =
						(uploadEstimator.meanLightSpeed *
							uploadEstimator.lightChunksSent +
							timeEllapsed) /
						newLightChunksSent
				}
			}

			const remainingSize =
				uploadEstimator.totalSize - uploadEstimator.sizeSent

			const remainingLightChunks =
				uploadEstimator.lightRequestsQuantity -
				uploadEstimator.lightChunksSent

			// Update the completion percentage
			// In order to have folder creation impacting a bit more the percentage, the light requests contributions
			// will be multiplied by an empiric value.
			const empiric = 200000
			const fullRatio =
				uploadEstimator.totalSize +
				uploadEstimator.lightRequestsQuantity * empiric

			const currentAdvancement =
				uploadEstimator.sizeSent +
				uploadEstimator.lightChunksSent * empiric
			const exactRatio = currentAdvancement / fullRatio

			state.loader.completion = Math.round(exactRatio * 100)
			uploadEstimator.chunksSent += 1

			// Update the remaining time, we must estimate separatly this value for heavy and light requests, then add them.
			let heavyTimeLeft = remainingSize / uploadEstimator.meanHeavySpeed
			if (uploadEstimator.meanHeavySpeed === 0) heavyTimeLeft = undefined

			let lightTimeLeft =
				remainingLightChunks * uploadEstimator.meanLightSpeed

			// If we're lacking a data that is needed for the remaining time.
			// estimation, simply set the timeLeft value to null, this will
			// indicate the loader to display a placeholder.
			if (
				(heavyTimeLeft === undefined &&
					state.uploadEstimator.heavyRequestsQuantity > 0) ||
				(lightTimeLeft === undefined &&
					state.uploadEstimator.lightRequestsQuantity > 0)
			) {
				uploadEstimator.timeLeft = null
			} else {
				// The next 2 lines are necessary for upload with only heavy or only light requests
				if (!heavyTimeLeft) heavyTimeLeft = 0
				if (!lightTimeLeft) lightTimeLeft = 0

				uploadEstimator.timeLeft = heavyTimeLeft + lightTimeLeft
			}

			// Remove the finished chunk
			state.uploadEstimator.uploadContent[action.payload.fileId].shift()

			// Cleanup the items list. If an array is empty, remove the key. If items list is empty,
			// it means the upload has ended, so set the upload estimator to null, that way the loader
			// knows the upload has ended.)
			if (
				state.uploadEstimator.uploadContent[action.payload.fileId]
					.length === 0
			) {
				delete state.uploadEstimator.uploadContent[
					action.payload.fileId
				]
			}

			if (Object.keys(state.uploadEstimator.uploadContent).length === 0) {
				state.uploadEstimator = null
			}
		},
		cancelUploadEstimation: (state) => {
			state.uploadEstimator = null
		},
		removeFileFromEstimator: (state, action) => {
			const fileToRemove =
				state.uploadEstimator?.uploadContent[action.payload.fileId]

			if (!fileToRemove) return

			const fileType = fileToRemove[0].weight
			const chunksQuantity = fileToRemove.length
			const fileSize = fileToRemove.reduce(
				(accumulator, currentValue) => {
					return accumulator + currentValue.size
				},
				0,
			)

			state.uploadEstimator.chunksToSent -= chunksQuantity
			state.uploadEstimator.totalSize -= fileSize

			if (fileType === "light") {
				state.uploadEstimator.lightRequestsQuantity -= chunksQuantity
			} else {
				state.uploadEstimator.heavyRequestsQuantity -= chunksQuantity
			}

			delete state.uploadEstimator.uploadContent[action.payload.fileId]

			if (
				Object.keys(state.uploadEstimator.uploadContent)?.length === 0
			) {
				state.uploadEstimator = null
			}
		},
		setActionLoader: (state, action) => {
			if (action.payload) {
				state.loader = {
					...state.loader,
					...action.payload,
				}
			} else {
				state.loader = null
			}
		},
		setOpenGuacTerm: (state, action) => {
			state.guacTermActive = action.payload
		},
	},

	// W
	// T
	// F
	// ?
	// !
	// !
	// !
	// WHY IS THIS SLICE SO LONG ?! It originally only took 20 lines T_T
})

export const { openAction, closeAction, setActionProps } = actionSlice.actions

export default actionSlice.reducer
