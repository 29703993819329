import { toastr } from "react-redux-toastr"
import I18n from "../../../../i18n"
import { handleError } from "../../../../actions/generalUtils"

function headers(payload) {
	let header = {
		"Accept-Language": localStorage.getItem("i18nextLng"),
		Authorization: "Bearer " + payload.token,
		"X-Ls-Token": payload.xLsT,
	}
	if (!payload.formData)
		header = { ...header, "Content-Type": "application/json" }

	return header
}

function formQuery(d, mode) {
	let query = ""
	let first = mode

	if (d.organizationId) {
		if (first) {
			query += "/organization/" + d.organizationId
		} else {
			query += "?organization=" + d.organizationId
		}
	}

	return query
}

/* -------------------------------------------------------------------------- */
/*                                THREATS LIST                                */
/* -------------------------------------------------------------------------- */
export const getThreatsList = (payload) => {
	let responseStatus
	const url =
		localStorage.getItem("apiUrl") +
		"dashboard_risk" +
		formQuery(payload, true)

	return fetch(url, {
		method: "GET",
		headers: headers(payload),
	})
		.catch((error) => {
			toastr.error(
				I18n.t("forServices.error"),
				I18n.t("forServices.netIssue"),
			)
			throw error
		})
		.then((res) => {
			responseStatus = res.ok
			return res
		})
		.then((res) => res.json())
		.then((res) => (!responseStatus ? handleError(res) : res)) // Soit une erreur : dans ce cas là on affiche l'erreur avec un toaster soit on return res vers les sagas
}
