import { createSlice } from "@reduxjs/toolkit"

const base = [
	{
		name: "manage.orgs",
		static: true,
		searchable: true,
		showHelp: "Manage",
		boardHelp: "Manage",
		repName: "manage.subOrgs",
		fileName: "manage.usersBoard",
		comp: "Org",
		actionBtn: [
			{ name: "manage.org", action: "addOrg" },
			{ name: "manage.group", action: "addGroup" },
			{ name: "manage.user", action: "addUserToOrga" },
			{ name: "manage.apiUser", action: "addApiUser" },
		],
		rootLoadData: "M_GET_DATA_SAGA",
		loadData: "M_GET_DATA_PERMALINKS_SAGA",
		id: 0,
		icon: "orga",
	},
	{
		name: "manage.groups",
		static: true,
		rootLoadData: "M_GET_DATA_SAGA",
		loadData: "M_GET_DATA_PERMALINKS_SAGA",
		actionBtn: [{ name: "manage.user", action: "addUserToGroup" }],
		id: -1,
		icon: "groups",
	},
	{
		name: "manage.ADgroups",
		static: true,
		rootLoadData: "M_GET_DATA_SAGA",
		loadData: "M_GET_DATA_PERMALINKS_SAGA",
		actionBtn: [],
		id: -2,
		icon: "adGroups",
	},
]

const manageSlice = createSlice({
	name: "manage",
	initialState: {
		loading: true,
		slideLoading: false,
		data: null,
		history: [],
		active: {},
		lastActionResult: null,
		paginLoading: false,
		searchVal: "",
		arbo: base,
		compassLoading: false,
	},
	reducers: {
		init: (state) => {
			state.data = base
			state.history = []
			state.active = {}
			state.searchMode = false
			state.searchVal = ""
			state.loading = true
		},
		setErrorNotFound: (state, action) => {
			state.errorNotFound = action?.payload ? action?.payload : false
			state.callRunning = false
		},
		savePageSize: (state, action) => {
			state.pageSize = action.payload
		},
		toggleLoading: (state, action) => {
			if (!action.payload) state.loading = false
			else state.loading = action.payload
		},
		toggleSlideLoading: (state, action) => {
			if (!action.payload) state.slideLoading = false
			else state.slideLoading = action.payload
		},
		toggleCompassLoading: (state, action) => {
			if (!action.payload) state.compassLoading = false
			else state.compassLoading = action.payload
		},
		paginLoading: (state, action) => {
			if (!action.payload) state.paginLoading = null
			else state.paginLoading = action.payload
		},
		setLAR: (state, action) => {
			if (!action.payload) state.lastActionResult = null
			else state.lastActionResult = action.payload
		},
		setCR: (state, action) => {
			if (!action.payload) state.callRunning = false
			else state.callRunning = action.payload
		},
		addElements: (state, action) => {
			// Ici on combine la data de fichiers/dossiers dans le state redux, et on y ajoute les fichiers récupérés depuis l'appel api
			let og = state.data[0]

			let newFiles = action.payload._embedded?.items
				? action.payload._embedded?.items
				: action.payload.files
				? action.payload.files
				: []

			let newState = [...state.data]
			newState[0] = {
				...og,
				files:
					og.files?.length > 0 && !action.payload.doNotKeep
						? [...og.files, ...newFiles]
						: newFiles,
				reps: og.reps ? og.reps : [],
			}

			state.data = newState
			state.pagin = {
				limit: action.payload.limit,
				page: action.payload.page,
				pages: action.payload.pages,
				total: action.payload.total,
			}
		},
		addReps: (state, action) => {
			//console.log(action)

			let i
			if (action.payload.stateTarget) i = action.payload.stateTarget
			else i = 0

			let og = action.payload.search ? state.data[0] : state.data[i]
			let newState = [...state.data]

			let ogArbo = action.payload.search ? state.arbo[0] : state.arbo[i]
			let arboState = [...state.arbo]

			// On prépare les nouveaux dossiers
			let d = action.payload.data
			let newD = []

			if (d?.length > 0) {
				d?.map((item) => {
					if (!item.loadMoreItem) {
						return newD.push({
							...item,
							showHelp: i >= 1 ? "groups" : "orgs",
							boardHelp: i >= 1 ? "groups" : "orgs",
							repName:
								i >= 1 ? "manage.groups" : "manage.subOrgs",
							fileName: "manage.usersBoard",
							comp: i >= 1 ? "Group" : "Org",
							rootLoadData: "M_GET_DATA_SAGA",
							loadData: "M_GET_DATA_PERMALINKS_SAGA",
							//noSelect: i === 1 ? true : undefined
						})
					} else {
						return newD.push(item)
					}
				})
			}

			// On les ajoute au nouveau state
			if (action.payload.search) {
				newState[0] = {
					...og,
					reps: og.reps?.length > 0 ? [...og.reps, ...newD] : newD,
					repName: action.payload.search
						? "manage.group"
						: og.repName,
				}

				arboState[0] = {
					...ogArbo,
					reps:
						ogArbo.reps?.length > 0
							? [...ogArbo.reps, ...newD]
							: newD,
					repName: action.payload.search
						? "manage.group"
						: ogArbo.repName,
				}
			} else {
				newState[i] = {
					...og,
					reps:
						og.reps?.length > 0 && !action?.payload?.doNotKeep
							? [...og.reps, ...newD]
							: newD,
				}

				arboState[i] = {
					...ogArbo,
					reps: action?.payload?.addRep
						? [...og.reps, ...newD]
						: newD,
				}
			}

			if (!action.payload.isArbo) {
				state.data = newState
			}

			state.arbo = arboState
		},
		historyEnter: (state, action) => {
			let pt = action.payload?.productTarget
				? action.payload?.productTarget
				: 0

			state.history.push({
				name: state.data[pt].name,
				loadData: state.data[pt].loadData,
				historyState: JSON.stringify(state.history),
				repName: state.data[pt].repName,
				fileName: state.data[pt].fileName,
				comp: state.data[pt].comp,
				noSelect: state.data[pt].noSelect ? true : false,
				showHelp: state.data[pt].showHelp
					? state.data[pt].showHelp
					: false,
				actionBtn: state.data[pt].actionBtn,
				searchable: state.data[pt].searchable ? true : false,
				categoryId: state.data[0].id,
				parentId: state.data[0].parentId,
				mainId: state.data[0].mainId,
				isInCategory: state.data[0].isInCategory,
				isAdmin: state.data[0].isAdmin,
			})
		},
		enterInFolder: (state, action) => {
			//console.log(action)
			let pt = action.payload.productTarget
				? action.payload.productTarget
				: 0 // Quel sous produit on vise ? N'existe que si l'on est à la base du produit et depuis le compass affichant tous les sous produits

			if (action.payload.justRefresh) {
				// Lorsqu'on reste dans le même repertoire mais qu'on update seulement les données (par ex après post ou delete)

				// Ici on combine la data de fichiers/dossiers dans le state redux, et on y ajoute les fichiers récupérés depuis l'appel api
				let og = state.data[pt]
				let a = {
					...og,
					files: action.payload._embedded.items,
					reps: og.reps ? og.reps : [],
				}

				if (action.payload.retriveActive) {
					let a = action.payload._embedded.items.find(
						(item) =>
							item.id ===
							state.active[Object.keys(state.active)[0]].id,
					)
					let elemType =
						state.active[Object.keys(state.active)[0]].elemType
					let ai = Object.keys(state.active)[0]

					state.active = { [ai]: { ...a, elemType } }
				} else state.active = {}

				if (state.data[1]) a.push(state.data[1])

				state.data[pt] = a
				state.pagin = {
					limit: action.payload.limit,
					page: action.payload.page,
					pages: action.payload.pages,
					total: action.payload.total,
				}
				return
			}

			if (!action.payload.historyState) {
				// Ici on combine la data de fichiers/dossiers dans le state redux, et on y ajoute les fichiers récupérés depuis l'appel api
				let a = []
				let og = state.data?.[pt]?.reps?.[action.payload.domTarget]
					? state.data[pt].reps[action.payload.domTarget]
					: []

				a.push({
					...og,
					repUsers: action.payload.repUsers,
					searchable: true,
					superiorAdmin: action.payload.superiorAdmin,
					files: action.payload._embedded?.items,
					reps: og.reps ? og.reps : [],
					...(!og.static
						? { actionBtn: state.data?.[pt]?.actionBtn }
						: {}),
				})

				state.data = a
			} else {
				// ici on revient donc sur un dossier précedent
				state.history = JSON.parse(action.payload.historyState)

				let ns = []
				let p = action.payload
				let og = state.data[0]

				ns.push({
					id:
						typeof p.categoryId !== "undefined"
							? p.categoryId
							: undefined,
					mainId:
						typeof p.mainId !== "undefined" ? p.mainId : undefined,
					isInCategory: p.isInCategory,
					name: p.name,
					static: p.static,
					loadData: p.loadData,
					files: p._embedded?.items,
					repName: p.repName,
					fileName: p.fileName,
					comp: p.comp,
					reps: og.reps ? og.reps : [],
					noSelect: p.noSelect ? true : false,
					showHelp: p.showHelp ? p.showHelp : false,
					actionBtn: p.actionBtn,
					searchable: true,
					repUsers: action.payload.repUsers
						? action.payload.repUsers
						: [],
				})

				state.data = ns
			}

			state.active = {}
			state.pagin = {
				limit: action.payload.limit,
				page: action.payload.page,
				pages: action.payload.pages,
				total: action.payload.total,
			}
			return
		},
		manageSelected: (state, action) => {
			if (state.active[action.payload.elemKey]) {
				// Si l'élément est déjà dans les actifs on le retire
				delete state.active[action.payload.elemKey]
			} else {
				if (action.payload.uniSelect)
					state.active = {
						[action.payload.elemKey]: action.payload,
					}
				// Si on est en séléction unique (clic sur la card) on reset la sélec par l'élément
				else
					state.active = {
						...state.active,
						[action.payload.elemKey]: action.payload,
					} // sinon on le rajoute dans la sélec
			}
		},
		updateActive: (state, action) => {
			if (!action.payload.organizationId && action.payload.addUser) {
				delete state.active[action.payload?.id]
			} else if (
				!action.payload?.removeUser &&
				!action.payload?.addUser
			) {
				let targetPos = Object.keys(state.active)[0]

				const a = {
					...state.active[targetPos],
					...action.payload.data,
					elemKey: state.active[targetPos].elemKey,
					elemType: state.active[targetPos].elemType,
				}

				if (action.payload.data.pwdExpirationDate === false)
					delete a.pwdExpirationDate

				state.active[targetPos] = a // On update l'élément actif (qui est une copie de son original dans la data du store du produit)

				state.data[0].files[
					state.data[0].files.findIndex(
						(f) => f.id === action.payload.id,
					)
				] = a // On l'update aussi dans son store original
			} else if (action.payload?.addUser) {
				delete action.payload?.addUser

				state.active[action.payload?.id] = action.payload
			} else {
				delete state.active[action.payload?.id]
			}

			state.slideLoading = false
		},
		updateData: (state, action) => {
			const baseData = (stateTarget) => ({
				showHelp: stateTarget >= 1 ? "groups" : "orgs",
				boardHelp: stateTarget >= 1 ? "groups" : "orgs",
				repName: "manage.subOrgs",
				fileName: "manage.usersBoard",
				comp: stateTarget >= 1 ? "Group" : "Org",
				rootLoadData: "M_GET_DATA_SAGA",
				loadData: "M_GET_DATA_PERMALINKS_SAGA",
			})

			let a = [...state.data]
			let i = action.payload.stateTarget ? action.payload.stateTarget : 0

			//let reps
			if (action.payload?.reset) {
				delete action.payload?.reset

				if (action.payload?.historyData?.length > 0) {
					state.history = action.payload?.historyData?.map(
						(data, index) => ({
							...state.data[0],
							...state.data[i],
							...baseData(i),
							...data,
							categoryId: state.data[0].id,
							parentId: state.data[0].parentId,
							mainId: state.data[0].mainId,
							isInCategory: state.data[0].isInCategory,
							isAdmin: state.data[0].isAdmin,
							static: index === 0 ? true : false,
						}),
					)
				} else {
					state.history = [
						{
							...state.data[0],
							...state.data[i],
							...baseData(i),
							categoryId: state.data[0].id,
							parentId: state.data[0].parentId,
							mainId: state.data[0].mainId,
							isInCategory: state.data[0].isInCategory,
							isAdmin: state.data[0].isAdmin,
							static: true,
						},
					]
				}

				const reps = action?.payload?.children
				delete action?.payload?.children

				state.data = [
					{
						...state.data[0],
						...state.data[i],
						...baseData(i),
						...action.payload,
						reps: reps
							? reps?.map((data) => ({
									...state.data[i],
									...data,
									boardHelp: "orgs",
									static: false,
							  }))
							: state.data[i]?.reps
							? state.data[i]?.reps
							: [],
						static: false,
						stateTarget: undefined,
					},
				]
			} else {
				a[i] = {
					...state.data[i],
					...action.payload,
					reps: action.payload.children
						? action.payload.children
						: state.data[i].reps,
					stateTarget: undefined,
				}
				//delete a.children
				state.data = a
			}
		},
		updatePagin: (state, action) => {
			state.pagin = {
				page: action.payload.page,
				limit: action.payload.limit,
				pages: action.payload.pages,
				total: action.payload.total,
			}
		},
		unselectAll: (state) => {
			state.active = {}
		},
		selectAll: (state) => {
			let a
			state.data[0].files?.map((file) => {
				if (file.status !== 6 && file.status !== 5)
					return (a = {
						...a,
						[file?.id]: {
							...file,
							elemType:
								state.data?.[0]?.comp === "Org"
									? "User"
									: "GroupUser",
						},
					})
				else return undefined
			})
			if (a) state.active = a
		},
		setSearchVal: (state, action) => {
			state.searchVal = action.payload
		},
		initSearch: (state, action) => {
			let a = []
			let og = state.data[0]
			a.push({
				...og,
				name: "mLayout.compass.results",
				reps: null,
				actionBtn: null,
			})
			state.data = a

			state.searchMode = true
			state.searchVal = action.payload.search
			state.searchResults = []
			state.active = {}
		},
		manualFilter: (state, action) => {
			let val = action.payload.toUpperCase()

			if (state.searchResults.length === 0)
				state.searchResults = state.data[0].files

			let newFiles = []
			let tempoFiles = JSON.parse(JSON.stringify(state.searchResults))

			tempoFiles?.map((item) => {
				if (
					item.email?.toUpperCase().includes(val) ||
					item.firstName?.toUpperCase().includes(val) ||
					item.lastName?.toUpperCase().includes(val)
				)
					return newFiles.push(item)
				else return undefined
			})

			state.data[0].files = newFiles
			state.pagin = {}
		},
		saveBranch: (state, action) => {
			if (action.payload) state.branchInfo = action.payload
		},
		hideADGroups: (state) => {
			let sCopy = [...state.data]

			if (sCopy[2]) sCopy.pop()

			state.data = sCopy
		},
		modalSearch: (state, action) => {
			state.data[0].modalSearch = action.payload.modalSearchResult
		},
		removeMfaFromUser: (state, action) => {
			// remove the mfa for the user in the selection
			state.active[Object.keys(state.active)[0]].isUserMfa = false

			// remove the mfa for the user in the files list
			for (let i = 0; i < state.data[0].files.length; i++) {
				if (state.data[0].files[i].id === action.payload.userId) {
					state.data[0].files[i].isUserMfa = false
				}
			}
		},
	},
})

export const { init } = manageSlice.actions

export default manageSlice.reducer
