import React from "react"
import PropTypes from "prop-types"

export default function LoaderBars(props) {
	const { mini, bullet, stop } = props
	if (bullet) {
		return (
			<span id="loader-1">
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
			</span>
		)
	}
	return (
		<div className={`spinner ${mini ? "mini" : ""} ${stop ? "stop" : ""}`}>
			<div className="rect rect1" />
			<div className="rect rect2" />
			<div className="rect rect3" />
			<div className="rect rect4" />
			{!mini && <div className="rect rect5" />}
		</div>
	)
}

LoaderBars.propTypes = {
	mini: PropTypes.bool,
	stop: PropTypes.bool,
	bullet: PropTypes.bool,
}
